import type { RemovableRef } from '@vueuse/core';
import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

import { LOCAL_STORAGE_KEYS, PINIA_STORE } from '~/constants';

interface INotifications {
  total: RemovableRef<number>;
}

export const useUnreadNotificationsStore = defineStore(
  PINIA_STORE.UNREAD_NOTIFICATIONS_STORE,
  {
    state: (): INotifications => ({
      total: useLocalStorage(LOCAL_STORAGE_KEYS.UNREAD_NOTIFICATIONS, 0),
    }),
    actions: {
      reset() {
        this.total = 0;
      },
      updateCount(count: number) {
        this.total = count;
      },
      increment() {
        this.updateCount(this.total + 1);
      },
    },
  },
);
