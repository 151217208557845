<script setup lang="ts" name="FieldSingleSelect">
import { useFieldSelect } from '~/features/useFieldSelect';

import FieldSelectDropdown from '~/components/FieldSelectDropdown.vue';
import FilterRadioInput from '~/components/FilterRadioInput.vue';

import type { IFieldSelectOption, IFormField } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue?: IFieldSelectOption;
    field: IFormField<IFieldSelectOption>;
    autofocus?: boolean;
    errors?: string[];
  }>(),
  {
    autofocus: false,
    errors: (): string[] => [],
  },
);

const emit = defineEmits<{
  (e: 'blur'): void;
  (e: 'change'): void;
  (e: 'update:modelValue'): void;
}>();

const { inputValue, inputLabel, options, isActive, isSelected } =
  useFieldSelect(props, emit);
</script>

<template>
  <FieldSelectDropdown
    v-model="inputLabel"
    :field="props.field"
    :errors="props.errors"
    :autofocus="props.autofocus"
    :options="options"
    @blur="emit('blur')"
    @change="emit('change')"
  >
    <template #default="{ close }">
      <FilterRadioInput
        v-for="option in options"
        v-model="inputValue"
        :capitalize="field.capitalize"
        :key="option.id"
        :id="option.id"
        :title="option.name"
        :native-value="option"
        :tooltip="option.tooltip"
        :active="isActive(option)"
        :selected="isSelected(option)"
        :disabled="option.disabled || isSelected(option)"
        @select="close"
      >
        <slot name="item" :item="option">
          {{ option.name }}
        </slot>
      </FilterRadioInput>
    </template>
  </FieldSelectDropdown>
</template>
