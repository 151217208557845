import type {
  ICargoBookingRow,
  ICargoBookingType,
  ICargoesByType,
} from '~/types';

interface IBookingMap {
  booking: ICargoBookingRow;
  status: ICargoesByType<ICargoBookingRow>;
}

interface IBookingToMove {
  oldBooking: IBookingMap;
  newBooking: ICargoBookingRow;
  newCargoType: ICargoBookingType;
}

export const cargoBookingsByTypeUpdate = (
  oldCargoData: ICargoesByType<ICargoBookingRow>[],
) => {
  const createBookingMap = () => {
    const map = new Map<string, IBookingMap>();

    oldCargoData.forEach((status) => {
      status.bookings.forEach((booking) => {
        map.set(booking.id, { booking, status });
      });
    });

    return map;
  };

  const moveBooking = ({
    oldBooking,
    newBooking,
    newCargoType,
  }: IBookingToMove) => {
    oldBooking.status.bookings = oldBooking.status.bookings.filter(
      (b) => b.id !== oldBooking.booking.id,
    );

    const oldCargoStatus = oldCargoData.find(
      (status) => status.type === newCargoType,
    );

    if (oldCargoStatus) {
      newBooking.highlight = true;
      oldCargoStatus.bookings.push(newBooking);

      scheduleHighlightRemoval(newBooking, oldCargoStatus);
    }
  };

  const scheduleHighlightRemoval = (
    highlightedBooking: ICargoBookingRow,
    targetStatus: ICargoesByType<ICargoBookingRow>,
  ) => {
    const bookingInTargetStatus = targetStatus.bookings.find(
      (b) => b.id === highlightedBooking.id,
    );

    setTimeout(() => {
      if (bookingInTargetStatus) {
        bookingInTargetStatus.highlight = false;
      }
    }, 1500);
  };

  const updateBooking = (
    oldBooking: ICargoBookingRow,
    newBooking: ICargoBookingRow,
  ) => {
    Object.assign(oldBooking, newBooking);
  };

  const moveOrUpdateBooking = ({
    oldBooking,
    newBooking,
    newCargoType,
  }: IBookingToMove) => {
    const bookingTypeChanged = oldBooking.status.type !== newCargoType;

    if (bookingTypeChanged) {
      moveBooking({ oldBooking, newBooking, newCargoType });
      return;
    }

    updateBooking(oldBooking.booking, newBooking);
  };

  const updateBookings = (newCargoData: ICargoesByType<ICargoBookingRow>[]) => {
    const bookingMap = createBookingMap();

    newCargoData.forEach((newCargo) => {
      const oldStatus = oldCargoData.find(
        (status) => status.type === newCargo.type,
      );

      newCargo.bookings.forEach((newBooking) => {
        const oldBooking = bookingMap.get(newBooking.id);

        if (oldBooking) {
          moveOrUpdateBooking({
            oldBooking,
            newBooking,
            newCargoType: newCargo.type,
          });
          return;
        }

        oldStatus?.bookings.push(newBooking);
      });
    });
  };

  return {
    updateBookings,
  };
};
