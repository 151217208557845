<script setup lang="ts" name="AdminOrganisationsFilters">
import { useVModel } from '@vueuse/core';

import { useActiveFilters } from '~/features/useActiveFilters';

import FilterMenuSelectMultiple from '~/components/FilterMenuSelectMultiple.vue';
import FiltersLayout from '~/components/FiltersLayout.vue';
import FilterTag from '~/components/FilterTag.vue';

import type { IFilter, IOption } from '~/types';

const props = defineProps<{
  modelValue: {
    type: IOption[];
  };
  orgTypes: IOption[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue'): () => void;
}>();

const query = useVModel(props, 'modelValue', emit);

const { activeFilters, clearFilter, clearQuery } = useActiveFilters(query);

const clearActiveFilter = (filter: IFilter) => {
  const newQuery = clearFilter(filter, query);
  query.value.type = newQuery.type;
};

const clearAllFilters = () => {
  const newQuery = clearQuery(query);
  query.value.type = newQuery.type;
};
</script>

<template>
  <div class="divide-y divide-charcoal-2 rounded-md bg-white shadow">
    <slot />

    <FiltersLayout @clear="clearAllFilters">
      <FilterMenuSelectMultiple
        v-model="query.type"
        label="Organisation Type"
        :options="orgTypes"
      />

      <template v-if="activeFilters.length" #tags>
        <FilterTag
          v-for="filter in activeFilters"
          :key="filter.id"
          :name="filter.label"
          @click="clearActiveFilter(filter)"
        />
      </template>
    </FiltersLayout>
  </div>
</template>
