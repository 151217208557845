import type { RemovableRef } from '@vueuse/core';
import { useLocalStorage } from '@vueuse/core';
import axios from 'axios';
import { defineStore } from 'pinia';

import { LOCAL_STORAGE_KEYS, PINIA_STORE } from '~/constants';
import {
  useAnalyticsStore,
  useAuthStore,
  useMaintenanceStore,
  useOrganisationsStore,
  useUnreadNotificationsStore,
} from '~/store';

import { emailIsG2Ocean } from '~/features/useAzureLogin';
import { fetchProfileData } from '~/features/useProfileHelpers';
import {
  getFullName,
  isEmpty,
  useNullableObjectSerializer,
} from '~/features/useUtilities';

import type { IOrganisation, IProfile, IProfileType } from '~/types';

type StoredItem<T> = RemovableRef<T | null>;

interface IProfileStore {
  profile: StoredItem<IProfile>;
  internalPermissions: StoredItem<string[]>;
  tourIsCompleted: StoredItem<boolean>;
}

export const useProfileStore = defineStore(PINIA_STORE.PROFILE_STORE, {
  state: (): IProfileStore => ({
    profile: useLocalStorage<IProfile | null>(
      LOCAL_STORAGE_KEYS.PROFILE,
      null,
      {
        serializer: useNullableObjectSerializer<IProfile>(),
      },
    ),
    internalPermissions: useLocalStorage<string[]>(
      LOCAL_STORAGE_KEYS.PERMISSIONS,
      [],
    ),
    tourIsCompleted: useLocalStorage<boolean>(
      LOCAL_STORAGE_KEYS.TOUR_COMPLETED,
      false,
    ),
  }),
  getters: {
    fullName: (state): string =>
      getFullName(state.profile?.firstName, state.profile?.lastName),
    isG2ocean: (state): boolean => {
      if (state.profile == null) {
        return false;
      }

      return (
        emailIsG2Ocean(state.profile?.email) || state.profile?.isG2OEmployee
      );
    },
    isG2OEmployee: (state): boolean => state.profile?.userType === 'g2o',
    userType: (state): IProfileType | null => state.profile?.userType || null,
    isSpectator: (state): boolean => state.profile?.role === 'spectator',
    isPublic: (state): boolean => state.profile?.userType === 'public',
    isCustomer: (state): boolean => !(state.profile?.isG2OEmployee ?? false),
    hasPermission:
      (state) =>
      (permission: string, organisation?: IOrganisation | null): boolean => {
        const allPermissions = [
          ...new Set([
            ...(state.internalPermissions || []),
            ...(organisation?.permissions || []),
          ]),
        ];

        return allPermissions.includes(permission) ?? false;
      },
    isAuthenticated(state): boolean {
      return !!useAuthStore().accessToken && !isEmpty(state.profile);
    },
    tourIsVisible(state): boolean {
      const hideTourForSmallScreen = window.innerWidth < 1024;

      if (hideTourForSmallScreen || !this.isAuthenticated || this.isPublic) {
        return false;
      }

      return !state.tourIsCompleted;
    },
  },
  actions: {
    _setProfile(profile: IProfile): IProfile {
      this.profile = profile;
      return profile;
    },
    async fetchProfile(update = false) {
      const {
        profile,
        organisations,
        internalPermissions,
        unseenNotifications,
        maintenance,
      } = await fetchProfileData();

      this.internalPermissions = internalPermissions;
      this.tourIsCompleted = profile.tourCompleted;

      // Order is important here because of websocket initialization
      this._setProfile(profile);
      useAnalyticsStore().update(profile.id);
      useUnreadNotificationsStore().updateCount(unseenNotifications);
      useMaintenanceStore().setMessages(maintenance);
      useOrganisationsStore().updateOrganisations(organisations, update);
    },
    completeTour() {
      this.tourIsCompleted = true;
      axios.put('/api/customer/profile/tour-completed');
    },
    resetTour() {
      this.tourIsCompleted = false;
    },
    updateName({
      firstName,
      lastName,
    }: {
      firstName: string;
      lastName: string;
    }) {
      this.profile = {
        ...(this.profile as IProfile),
        firstName,
        lastName,
      };
    },
  },
});
