<script setup lang="ts" name="UserListPendingRequestsHeader">
import Icon from '~/components/Icon.vue';

const { open, requests } = defineProps<{
  requests: number;
  open: boolean;
}>();

const emit = defineEmits<{ (e: 'toggle'): void }>();
</script>

<template>
  <button class="flex w-full justify-between" @click="emit('toggle')">
    <div class="flex gap-2">
      <p class="text-subtitle-1 text-charcoal-9">Pending Requests</p>

      <div class="flex-center h-6 w-6 rounded-full bg-primary-4">
        <span class="-ml-px text-body-2 leading-none text-white">
          {{ requests }}
        </span>
      </div>
    </div>

    <Icon
      icon="mdi:chevron-right"
      class="text-primary-6"
      :class="{
        '-rotate-90': open,
        'rotate-90': !open,
      }"
    />
  </button>
</template>
