<script setup lang="ts" name="PendingUserInfo">
import { BASE_TAG_CONTRAST } from '~/constants';

import { formatDataEntries } from '~/features/useUtilities';

import BaseTag from '~/components/BaseTag.vue';
import Icon from '~/components/Icon.vue';

import type {
  BaseTagContrastType,
  IAdminRequestedUser,
  IconType,
  ITradeLane,
} from '~/types';

const { user } = defineProps<{
  user: IAdminRequestedUser;
}>();

const formattedTrades = formatDataEntries<ITradeLane>({
  entries: user.trades,
  max: user.trades.length,
  key: 'name',
});

const [firstOrganisation] = user.organisations;

type PendingUser = {
  icon: IconType;
  text: string;
  tagVariant?: BaseTagContrastType;
};

const userList: PendingUser[] = [
  {
    icon: 'mdi:account',
    text: `${user.first_name} ${user.last_name}`,
  },
  {
    icon: 'mdi:briefcase',
    text: firstOrganisation,
  },
  {
    icon: 'ic:baseline-email',
    text: user.email,
  },
  {
    icon: 'mdi:account',
    text: user.business_type,
    tagVariant: BASE_TAG_CONTRAST.ORANGE_3,
  },
  {
    icon: 'mdi:calendar-blank',
    text: user.createdAt,
  },
  {
    icon: 'mdi:map',
    text: formattedTrades,
  },
];
</script>

<template>
  <div>
    <ul class="list-none space-y-4">
      <li
        v-for="item in userList"
        :key="item.text"
        class="flex items-center gap-x-2"
      >
        <Icon class="text-charcoal-6" :icon="item.icon" />
        <BaseTag
          v-if="item.tagVariant"
          :name="item.text"
          :variant="item.tagVariant"
        />

        <span v-else class="text-body-1 text-charcoal-9">{{ item.text }}</span>
      </li>
    </ul>
  </div>
</template>
