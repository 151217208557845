import { ref } from 'vue';
import { defineStore } from 'pinia';

import { PINIA_STORE } from '~/constants';

import { getSearchableLane } from '~/features/useSailingSchedule';

import type {
  IOption,
  IStatisticsFiltersResponse,
  IStatisticsPort,
  IStatisticsTradeLane,
} from '~/types';

export const useStatisticsStore = defineStore(
  PINIA_STORE.STATISTICS_STORE,
  () => {
    const voyages = ref<IOption[]>([]);
    const tradeLanes = ref<IStatisticsTradeLane[]>([]);
    const commodities = ref<IOption[]>([]);
    const loadingPorts = ref<IStatisticsPort[]>([]);
    const dischargePorts = ref<IStatisticsPort[]>([]);

    const setFilters = ({ data }: IStatisticsFiltersResponse) => {
      voyages.value = data.voyages;
      tradeLanes.value = data.tradelanes.map(getSearchableLane);
      commodities.value = data.commodities.map((commoditie) => ({
        name: commoditie,
        id: commoditie,
      }));
      loadingPorts.value = data.loading_ports;
      dischargePorts.value = data.discharge_ports;
    };

    const reset = () => {
      voyages.value = [];
      tradeLanes.value = [];
      commodities.value = [];
      loadingPorts.value = [];
      dischargePorts.value = [];
    };

    return {
      setFilters,
      voyages,
      tradeLanes,
      commodities,
      loadingPorts,
      dischargePorts,
      $reset: reset,
    };
  },
);
