<script lang="ts" setup name="FilterCheckboxInput">
import { useVModel } from '@vueuse/core';

import BaseCheckbox from '~/components/BaseCheckbox.vue';

const props = defineProps<{
  modelValue?: unknown[] | boolean;
  id?: number | string;
  active?: boolean;
  nativeValue?: unknown;
  disabled?: boolean;
  tooltip?: string;
  title?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue'): void;
}>();
const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <BaseCheckbox
    class="group w-full px-4 py-2 hover:bg-charcoal-1"
    v-tooltip="tooltip"
    v-model="inputValue"
    :title="title"
    :name="id"
    :disabled="disabled"
    :nativeValue="nativeValue"
  >
    <p
      v-title
      class="truncate group-hover:text-primary-8"
      :class="{
        'text-primary-8': active,
      }"
    >
      <slot />
    </p>
  </BaseCheckbox>
</template>
