<script setup lang="ts">
import { computed } from 'vue';
import { useLink } from 'vue-router';

import BaseLink from '~/components/BaseLink.vue';

import type { IDataTableLink } from '~/types';

const { link } = defineProps<{
  link: IDataTableLink;
  value: string;
}>();

const isExternalLink = computed(
  () => typeof link.to === 'string' && link.to.startsWith('http'),
);

const href = computed(() =>
  isExternalLink.value
    ? (link.to as string)
    : useLink({ to: link.to }).href.value,
);

const isATag = computed(() => isExternalLink.value || link.openInNewTab);

const tag = computed(() => (isATag.value ? 'a' : 'RouterLink'));

const target = computed(() => (link.openInNewTab ? '_blank' : undefined));

const to = computed(() => (isATag.value ? undefined : link.to));
</script>

<template>
  <component :is="tag" :href="href" :target="target" :to="to" @click.stop>
    <BaseLink size="base" :icon="null">
      <p v-title class="truncate">{{ value }}</p>
    </BaseLink>
  </component>
</template>
