<script setup lang="ts" name="FieldComboBox">
import { computed, ref, watch } from 'vue';
import { useVModel } from '@vueuse/core';
import { useField } from 'vee-validate';

import FieldBaseErrors from '~/components/FieldBaseErrors.vue';
import FieldBaseInput from '~/components/FieldBaseInput.vue';
import FilterMenuOptions from '~/components/FilterMenuOptions.vue';
import FilterRadioInput from '~/components/FilterRadioInput.vue';
import XDropdown from '~/components/XDropdown.vue';

import type { IFormComboBox, IOption } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue?: IOption;
    errors?: string[];
    field: IFormComboBox<IOption>;
  }>(),
  {
    errors: () => [],
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: IOption): void;
  (e: 'change'): void;
  (e: 'blur'): void;
}>();

const UNIQUE_SEARCH_FIELD_NAME = `${props.field.name}-${props.field.label}`;

const inputValue = useVModel(props, 'modelValue', emit);
const options = ref<IOption[]>([]);

const {
  errors,
  value: search,
  setErrors,
} = useField<string>(
  UNIQUE_SEARCH_FIELD_NAME,
  (value: any) => {
    return value && !inputValue.value
      ? props.field.requiredSearchMessage
      : true;
  },
  {
    initialValue: '',
    syncVModel: false,
    validateOnValueUpdate: false,
  },
);

const listOfErrors = computed(() => {
  if (errors.value.length) {
    return errors.value;
  }

  return props.errors;
});

const onInput = async (
  searchValue = '',
  isOpen: boolean,
  open: () => void,
  close: () => void,
) => {
  try {
    if (searchValue === inputValue.value?.name) {
      return;
    }

    inputValue.value = undefined;
    options.value = props.field.search(searchValue);

    if (!isOpen) {
      open();
    }
  } catch (err) {
    close();
  }
};

watch(
  () => inputValue.value,
  (value) => {
    if (value) {
      search.value = value.name;
      setErrors([]);
    }
  },
  { immediate: true },
);
</script>

<template>
  <XDropdown :data-test="`${field.name}-wrapper`">
    <template #trigger="{ open, close, isOpen }">
      <FieldBaseInput
        v-model="search"
        class="w-full"
        :label="field.label || ''"
        :disabled="field.disabled"
        :name="field.name"
        @blur="emit('blur')"
        @click="onInput(search, isOpen, open, close)"
        @change="emit('change')"
        @update:modelValue="
          (value: string) => onInput(value, isOpen, open, close)
        "
      />
      <FieldBaseErrors
        v-if="listOfErrors.length > 0"
        class="mt-1"
        :errors="listOfErrors"
        :data-test="`${field.name}-error`"
      />
    </template>

    <template #default="{ close }">
      <FilterMenuOptions
        data-testid="combo-box-options"
        :hasOptions="options.length > 0"
      >
        <li v-for="option in options" :key="option.id" class="w-full">
          <FilterRadioInput
            v-model="inputValue"
            capitalize
            :title="option.name"
            :id="option.id"
            :name="field.name"
            :native-value="option"
            @select="close"
          >
            <slot name="item" :item="option">
              {{ option.name }}
            </slot>
          </FilterRadioInput>
        </li>
      </FilterMenuOptions>
    </template>
  </XDropdown>
</template>
