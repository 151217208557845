<script>
import intersect from '~/directives/intersect.js';

export default {
  name: 'LoadingIntersect',
  directives: {
    intersect,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['intersect'],
  setup(_, { emit }) {
    const onIntersect = (__, ___, isIntersecting) => {
      if (!isIntersecting) return;

      emit('intersect');
    };

    return {
      onIntersect,
    };
  },
};
</script>

<template>
  <div v-intersect="onIntersect">
    <div
      v-if="loading"
      class="flex animate-pulse items-center justify-center p-4"
    >
      <p class="text-charcoal-5">Loading more...</p>
    </div>
  </div>
</template>
