<script setup lang="ts" name="FieldPassword">
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';

import FieldBaseErrors from '~/components/FieldBaseErrors.vue';
import FieldBaseInput from '~/components/FieldBaseInput.vue';
import Icon from '~/components/Icon.vue';

import type { IFormField } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue: string | null | number;
    field: IFormField;
    errors?: string[];
  }>(),
  { errors: () => [] },
);

const emit = defineEmits<{
  'update:modelValue': [value: string | null | number];
  change: [];
  blur: [];
}>();

defineOptions({
  inheritAttrs: false,
});

const inputValue = useVModel(props, 'modelValue', emit);
const inputType = ref('password');

const onToggle = () => {
  inputType.value = inputType.value === 'password' ? 'text' : 'password';
};
</script>

<template>
  <div :data-test="`${field.name}-wrapper`">
    <FieldBaseInput
      v-model="inputValue"
      class="w-full"
      :name="field.name"
      :label="field.label"
      :type="inputType"
      :has-errors="errors.length > 0"
      :optional="!field.rules"
      :disabled="field.disabled"
      @change="emit('change')"
      @blur="emit('blur')"
    >
      <template #append>
        <button
          type="button"
          class="flex items-center"
          :class="{ 'pointer-events-none': field.disabled }"
          :data-test="`${field.name}-toggle`"
          @click="onToggle"
        >
          <Icon
            :icon="inputType === 'password' ? 'mdi:eye' : 'mdi:eye-off'"
            :class="{
              'text-primary-8': !field.disabled,
              'text-charcoal-4': field.disabled,
            }"
          />
        </button>
      </template>
    </FieldBaseInput>
    <FieldBaseErrors
      v-if="errors.length > 0"
      class="mt-1"
      :errors="errors"
      :data-test="`${field.name}-error`"
    />
  </div>
</template>
