<script lang="ts" setup name="CustomCard">
import { computed, useSlots } from 'vue';

import InfoTooltip from '~/components/InfoTooltip.vue';

const {
  title = '',
  subtitle = '',
  loading,
  tooltipTitle = 'How is this chart calculated?',
  tooltip = '',
} = defineProps<{
  title?: string;
  subtitle?: string;
  loading?: boolean;
  tooltip?: string;
  tooltipTitle?: string;
}>();

const slots = useSlots();
const hasTitle = computed(() => title || slots.title);
</script>

<template>
  <div class="flex min-w-0 flex-col rounded-md bg-white shadow">
    <div
      v-if="hasTitle"
      class="mb-4 flex shrink-0 items-center justify-between px-6 pt-6"
    >
      <div class="w-full">
        <div class="flex w-full items-center justify-between">
          <slot name="title">
            <p
              class="text-subtitle-1 text-charcoal-9"
              :class="{ 'text-loading': loading }"
            >
              {{ title }}
            </p>
          </slot>

          <InfoTooltip
            v-if="tooltip"
            :title="tooltipTitle"
            :description="tooltip"
          />
        </div>
        <p
          v-if="subtitle"
          class="mt-2 max-w-max text-body-2"
          :class="{ 'text-loading': loading }"
        >
          {{ subtitle }}
        </p>
      </div>

      <slot v-if="slots.actions" name="actions" />
    </div>
    <div class="flex-1" :class="title ? 'rounded-b-md' : 'rounded-md'">
      <slot />
    </div>
  </div>
</template>
