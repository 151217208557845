<script setup name="FieldNumber" lang="ts">
import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';

import { formatNumber } from '~/features/useNumbers';

import FieldBaseErrors from '~/components/FieldBaseErrors.vue';
import FieldBaseInput from '~/components/FieldBaseInput.vue';

import type { IFormField } from '~/types';

const props = withDefaults(
  defineProps<{
    field: IFormField;
    modelValue: string | null | number;
    errors?: string[];
    hasErrors?: boolean;
  }>(),
  {
    errors: (): string[] => [],
  },
);

const emit = defineEmits<{
  'update:modelValue': [value: string | null | number];
  change: [];
  blur: [];
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const visible = ref(false);
const previewValue = computed(() =>
  inputValue.value ? formatNumber(inputValue.value) : null,
);

const onBlurNumber = () => {
  visible.value = false;
  emit('blur');
};
const onFocusPreview = () => {
  visible.value = true;
};

const fieldHasErrors = computed(
  () => props.hasErrors || props.errors.length > 0,
);
</script>

<template>
  <div :data-test="`${field.name}-wrapper`" lang="en-US">
    <FieldBaseInput
      v-if="visible"
      v-model.number="inputValue"
      type="number"
      :label="field.label"
      :name="field.name"
      :has-errors="fieldHasErrors"
      class="w-full"
      @blur="onBlurNumber"
      @change="emit('change')"
    />
    <FieldBaseInput
      v-else
      :model-value="previewValue"
      type="text"
      :label="field.label"
      :name="field.name"
      :has-errors="fieldHasErrors"
      class="w-full"
      @focus="onFocusPreview"
      @blur="emit('blur')"
      @change="emit('change')"
    />
    <FieldBaseErrors
      v-if="errors.length > 0"
      :errors="errors"
      class="mt-1"
      :data-test="`${field.name}-error`"
    />
  </div>
</template>
