<script setup name="Ports">
import { computed } from 'vue';

import PortsRow from '~/components/PortsRow.vue';

const props = defineProps({
  ports: {
    type: Array,
    required: true,
  },
});

const startPort = computed(() => props.ports[0]);
const endPort = computed(() => props.ports[props.ports.length - 1]);
const intermediatePortNames = computed(() =>
  props.ports
    .slice(1, -1) // this is to exclude first and last port
    .map((port) => port.name)
    .join(' - '),
);
</script>

<template>
  <div>
    <PortsRow
      :port="startPort"
      port-number="1"
      is-first
      :is-last="false"
      class="pb-4"
    />
    <div
      class="relative grid items-start gap-4"
      style="grid-template-columns: 1.5rem 1fr"
    >
      <div
        class="absolute h-full border-l-2 border-secondary-3"
        style="left: 11px; border-left-style: dashed"
      />
      <div />
      <p class="text-charcoal-6">
        {{ intermediatePortNames }}
      </p>
    </div>
    <PortsRow
      :port="endPort"
      :port-number="ports.length"
      :is-first="false"
      is-last
    />
  </div>
</template>
