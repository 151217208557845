<script setup lang="ts" name="OrganisationUsersForm">
import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';

import { ROLES } from '~/constants';

import BaseCheckboxToggle from '~/components/BaseCheckboxToggle.vue';
import FormWizardSearchableList from '~/components/FormWizardSearchableList.vue';
import ListCheckbox from '~/components/ListCheckbox.vue';

import type { IOrganisationUser } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue: IOrganisationUser[];
    users: IOrganisationUser[];
    title: string;
    subtitle: string;
  }>(),
  {
    title: 'Add G2O Users',
    subtitle:
      'Select G2O users in order to grant access to the Organisation. If the user does not exist, please create a user in the User List.',
  },
);

const emit = defineEmits(['update:modelValue']);
const selectedUsers = useVModel(props, 'modelValue', emit);

const tagsForSelectedCustomerUsers = computed(() =>
  selectedUsers.value.filter((user) => !user.role.is_admin),
);

const search = ref('');

const clearSearch = () => {
  search.value = '';
};

const queryUserBySearch = (
  search: string,
): ((user: IOrganisationUser) => boolean) => {
  const normalizedSearch = search.toLowerCase();

  return (user) =>
    user.first_name.toLowerCase().includes(normalizedSearch) ||
    user.last_name.toLowerCase().includes(normalizedSearch);
};

const userList = computed(() => {
  if (!search.value) return props.users;

  return props.users.filter(queryUserBySearch(search.value));
});

const removeUser = (user) => {
  if (user.role.name === ROLES.G2O_ADMIN) return;

  selectedUsers.value = selectedUsers.value.filter(
    (item) => item.id !== user.id,
  );
};

const tooltip = computed(
  () => (isAdmin: boolean) =>
    isAdmin
      ? 'Admin users are by default assigned to all organisations.'
      : undefined,
);

const toggleSelectAll = () => {
  if (selectedUsers.value.length === props.users.length) {
    selectedUsers.value =
      props.users?.filter(({ role }) => role.is_admin) ?? [];
  } else {
    selectedUsers.value = [...props.users];
  }
};
</script>

<template>
  <FormWizardSearchableList
    v-model="search"
    placeholder="Search by Name, Surname"
    selected-item-tag-key="email"
    :title="title"
    :subtitle="subtitle"
    :items="userList"
    :selected-items="tagsForSelectedCustomerUsers"
    @on-remove-selected-item="removeUser"
    @clear-search="clearSearch"
  >
    <BaseCheckboxToggle
      class="px-6 py-4"
      :items="users"
      :selectedItems="selectedUsers"
      @toggle="toggleSelectAll"
    />

    <ListCheckbox
      v-for="user in userList"
      :key="user.id"
      v-model="selectedUsers"
      :disabled="user.role.is_admin"
      :tooltip="tooltip(user.role.is_admin)"
      :item="user"
    >
      <div class="flex px-4">
        <div class="w-1/3">
          <p>{{ user.first_name }} {{ user.last_name }}</p>
          <p class="truncate text-charcoal-6">
            {{ user.email }}
          </p>
        </div>
        <div class="ml-4">
          <p>User Role</p>
          <p class="text-charcoal-6">
            {{ user.role.name }}
          </p>
        </div>
      </div>
    </ListCheckbox>
  </FormWizardSearchableList>
</template>
