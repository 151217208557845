<script lang="ts" setup name="AdminUsersIndex">
import { computed, reactive, ref } from 'vue';

import { ORIGIN_EXTERNAL, USER_TYPES } from '~/constants';
import { useProfileStore, useSnackbarStore } from '~/store';

import { useShowModal } from '~/features/useModalsProvider';
import { useService } from '~/features/useService.js';
import { storage } from '~/features/useStorage';
import {
  getIdsFromArray,
  getItemsFromSearch,
  getKeyFromSearch,
} from '~/features/useUrlSearch';
import { useUserDataTable } from '~/features/useUserDataTable';

import Service from '~/services/Service.js';

import BasePage from '~/components/BasePage.vue';
import CustomCard from '~/components/CustomCard.vue';
import DataTable from '~/components/DataTable.vue';
import DataTableActions from '~/components/DataTableActions.vue';
import DataTableLoading from '~/components/DataTableLoading.vue';
import ModalConfirmUserDelete from '~/components/ModalConfirmUserDelete.vue';
import SearchField from '~/components/SearchField.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import UserListFilters from '~/components/UserListFilters.vue';
import UsersListPendingUsers from '~/components/UsersListPendingUsers.vue';

import type {
  IAdminUserListPendingUser,
  IAdminUserListQuery,
  IAdminUserListUsersTableRow,
  IBasePage,
  IDataTableSettings,
  IPageResponse,
} from '~/types';

const page = computed<IBasePage>(() => ({
  title: 'User List',
  actions: [
    {
      label: 'New G2O User',
      icon: 'mdi:plus',
      to: { name: 'AdminUserNew' },
    },
  ],
}));

const snackbar = useSnackbarStore();
const showModal = useShowModal();

const { userColumns, getUserTypeState, geActionsTooltipText } =
  useUserDataTable();

const profile = computed(() => useProfileStore().profile);
const filters = computed(() => storage.getUserListQuery());

const users = ref<IAdminUserListUsersTableRow[]>([]);
const pendingUserRequests = ref<IAdminUserListPendingUser[]>(
  storage.getPendingUserRequests(),
);

const fromEmail = computed(
  () => getKeyFromSearch('origin') === ORIGIN_EXTERNAL,
);

const query = reactive<IAdminUserListQuery>({
  organisations: getItemsFromSearch(
    'organisation',
    filters.value.organisations,
  ),
  roles: getItemsFromSearch('role', filters.value.roles),
  userTypes: getItemsFromSearch('user_type', filters.value.user_types),
  search: getKeyFromSearch('search'),
});

const serviceState = useService(
  Service.users().index(),
  ({ data }: IPageResponse<IAdminUserListUsersTableRow[]>) => {
    if (!data.length) return false;
    users.value = data;

    return true;
  },
  {
    loading: 'skeleton',
    cancelOnNewRequest: true,
    paginated: true,
    query,
    getQuery: () => ({
      organisation: getIdsFromArray(query.organisations),
      role: getIdsFromArray(query.roles),
      user_type: getIdsFromArray(query.userTypes),
      search: query.search || '',
    }),
  },
);

const tableSettings = computed<IDataTableSettings<IAdminUserListUsersTableRow>>(
  () => ({
    editable: false,
    pagination: true,
    columns: [
      userColumns.details,
      userColumns.organisations,
      userColumns.email,
      userColumns.user_type,
      userColumns.role,
      userColumns.business_type,
    ],
    actions: (item?: IAdminUserListUsersTableRow) => {
      const isCurrentUser = profile.value?.id === item?.id;
      const { isInternal, isPublicUser } = getUserTypeState(item?.user_type);

      const { removeTooltip, editTooltip } = geActionsTooltipText(
        isInternal ? null : item?.user_type,
        isCurrentUser,
      );

      return [
        {
          key: 'remove',
          icon: 'ic:baseline-delete',
          dataTestId: 'remove',
          tooltipDescription: removeTooltip,
          variant: 'btn-tertiary',
          disabled: isCurrentUser,
          handler: async () => {
            const result = await showModal(ModalConfirmUserDelete, {
              email: item?.email ?? '',
              isExternal: false,
              isPublic: isPublicUser,
              hasOneOrganisation: true,
            });

            if (result.action === 'CONFIRM' && item) {
              onDeleteUser(item.id, item.email);
            }
          },
        },
        {
          key: 'edit',
          icon: 'ic:baseline-edit',
          dataTestId: 'row-edit',
          tooltipDescription: editTooltip,
          variant: 'btn-tertiary',
          disabled: !isInternal || isCurrentUser,
          to: {
            name: 'AdminUserEdit',
            params: {
              userId: item?.id,
            },
          },
        },
        {
          key: 'view',
          icon: 'mdi:eye',
          dataTestId: 'row-view',
          tooltipDescription: 'View',
          variant: 'btn-tertiary',
          to: isCurrentUser
            ? { name: 'AuthProfile', query: { from: 'user-list' } }
            : {
                name: 'AdminUserView',
                params: {
                  userId: item?.id,
                  type: item?.user_type,
                },
              },
        },
      ];
    },
  }),
);

const onDeleteUser = (id: string, email: string) => {
  Service.user(id)
    .destroy()
    .onSuccess(() => {
      snackbar.add({
        type: 'success',
        html: `The user <span class="font-bold">${email}</span> removed successfully.`,
      });

      pendingUserRequests.value = pendingUserRequests.value.filter(
        (user) => user.email !== email,
      );

      serviceState.fetchData();
    })
    .execute();
};

const onFiltersClear = () => {
  query.organisations = [];
  query.roles = [];
  query.userTypes = [];
  query.search = '';
};
</script>

<template>
  <BasePage v-bind="page">
    <UsersListPendingUsers
      v-if="pendingUserRequests.length"
      class="col-span-full"
      :open="fromEmail"
      :users="pendingUserRequests"
    />

    <div class="col-span-full space-y-2">
      <div class="divide-y divide-charcoal-2 rounded-t-md bg-white shadow">
        <SearchField
          v-if="!serviceState.stateHasErrors"
          v-model="query.search"
          placeholder="Search by Name, Surname or Email"
        />
        <UserListFilters
          v-if="!serviceState.stateHasErrors"
          v-model="query"
          :organisations="filters.organisations"
          :roles="filters.roles"
          :user-types="filters.user_types"
        />
      </div>

      <CustomCard>
        <ServiceCard
          v-bind="serviceState"
          no-results-message="Please amend the search criteria or filter field to see all users"
          @clear="onFiltersClear"
        >
          <DataTableLoading
            v-if="serviceState.stateIsLoading"
            :columns="tableSettings.columns.length"
          />
          <DataTable v-else :data="users" :settings="tableSettings">
            <template #actions="{ item }">
              <DataTableActions :actions="tableSettings.actions!(item)" />
            </template>
          </DataTable>
        </ServiceCard>
      </CustomCard>
    </div>
  </BasePage>
</template>
