<script setup lang="ts" name="DataTableNumericField">
import { computed, onMounted, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

const { value } = defineProps<{
  value: number | null;
}>();

const emit = defineEmits<{
  update: [val: number | null];
  keyDown: [event: KeyboardEvent];
  moveRightOrTab: [];
  resetEditMode: [];
  onEnter: [];
}>();

const inputValue = ref(value);

const inputRef = ref<HTMLInputElement>();

const valueChanged = computed(() => inputValue.value !== value);

const onKeyDown = (event: KeyboardEvent) => {
  event.stopPropagation();

  if (event.key === 'Escape') {
    emit('resetEditMode');
    return;
  }

  if (event.key === 'Tab' || event.key === 'Enter') {
    event.preventDefault();

    if (valueChanged.value) {
      emit('update', inputValue.value);
    }

    event.key === 'Tab' ? emit('moveRightOrTab') : emit('onEnter');
    return;
  }

  if (shouldPreventDefault(event)) {
    event.preventDefault();
  }
};

const shouldPreventDefault = (event: KeyboardEvent): boolean => {
  return (
    event.key !== 'Backspace' &&
    event.key !== 'ArrowLeft' &&
    event.key !== 'ArrowDown' &&
    event.key !== 'ArrowUp' &&
    event.key !== 'ArrowRight' &&
    Number.isNaN(Number(event.key))
  );
};

onClickOutside(inputRef, () => {
  if (valueChanged.value) {
    emit('update', inputValue.value);
  }

  emit('resetEditMode');
});

onMounted(() => {
  inputRef.value?.focus();
  inputRef.value?.select();
});
</script>

<template>
  <input
    type="numeric"
    ref="inputRef"
    class="w-full max-w-full focus:outline-none"
    data-testid="input-field"
    v-model="inputValue"
    @paste.prevent
    @keydown="onKeyDown"
    @dblclick.stop
  />
</template>
