export const KPI_TYPE = {
  VOLUMES_PER_PERIOD: 'volumePerPeriod',
  VOLUMES_PER_SHIPMENT: 'volumePerShipment',
  TRANSIT_TIME: 'transitTime',
  INVOICES: 'invoices',
} as const;

export const KPI_CONTRACT_TYPE = {
  SHIPMENT_VOLUMES: 'shipmentVolumes',
  TRANSIT_TIME: 'transitTime',
  INVOICES: 'invoices',
} as const;

export const KPI_STATUS = {
  ACTIVE: 'active',
  UPCOMING: 'upcoming',
  EXPIRED: 'expired',
} as const;

export const KPI_PER_SHIPMENT_VIEW_QUERY = {
  ALL: 'all',
  OUTSIDE: 'outside',
} as const;

export const KPI_OVERVIEW_WIDGET_COLORS = {
  BG_PRIMARY_8: 'bg-primary-8',
  BG_SECONDARY_3: 'bg-secondary-3',
  BG_CHARCOAL_4: 'bg-charcoal-4',
} as const;

//Order important
export const KPI_TRANSIT_TIME_RULE = {
  LAST_TO_FIRST: 'lastToFirst',
  LAST_TO_LAST: 'lastToLast',
  PORT_PAIRS: 'portPairs',
  FIRST_TO_FIRST: 'firstToFirst',
  FIRST_TO_LAST: 'firstToLast',
} as const;

// USED IN NEW KPIS
export const KPI_SETTING = {
  INVOICES: 'INVOICES',
  SHIPPED_VOLUMES: 'SHIPPED_VOLUMES',
  TRANSIT_TIMES: 'TRANSIT_TIMES',
} as const;

export const KPI_SETTING_META = {
  [KPI_SETTING.INVOICES]: {
    name: 'Invoices',
    create: 'OrganisationSettingsKpisInvoicesNew',
  },
  [KPI_SETTING.SHIPPED_VOLUMES]: {
    name: 'Shipped Volumes',
    create: 'OrganisationSettingsKpisShippedVolumesNew',
  },
  [KPI_SETTING.TRANSIT_TIMES]: {
    name: 'Transit Times',
    create: 'OrganisationSettingsKpisTransitTimesNew',
  },
} as const;

export const TRANSIT_TIME_RULE_TO_TEXT = {
  [KPI_TRANSIT_TIME_RULE.PORT_PAIRS]: 'Port Pairs',
  [KPI_TRANSIT_TIME_RULE.LAST_TO_FIRST]:
    'Last Load Port to First Discharge Port',
  [KPI_TRANSIT_TIME_RULE.LAST_TO_LAST]: 'Last Load Port to Last Discharge Port',
  [KPI_TRANSIT_TIME_RULE.FIRST_TO_FIRST]:
    'First Load Port to First Discharge Port',
  [KPI_TRANSIT_TIME_RULE.FIRST_TO_LAST]:
    'First Load Port to Last Discharge Port',
};

export const KPI_TRANSIT_TIME_FILTERS_STATES = {
  FETCHING_FILTERS: 'FETCHING_FILTERS',
  CONTRACTS: 'CONTRACTS',
  TRADE_LANES: 'TRADE_LANES',
  STATUSES: 'STATUSES',
  PORTS: 'PORTS',
} as const;
