<script setup lang="ts" name="AdminUserAcceptRequest">
import { computed, reactive, ref } from 'vue';

import { ORIGIN_EXTERNAL } from '~/constants';

import { sendCreateOrganisationEvent } from '~/features/useAnalytics';
import { useLeavePageWarning } from '~/features/useLeavePageWarning';
import {
  getOrganisationFormData,
  getOrganisationNewForm,
} from '~/features/useOrganisationForm';
import { useService } from '~/features/useService';
import { useState } from '~/features/useState';

import Service from '~/services/Service';

import AdminAcceptUserOrganisationsStep from '~/components/AdminAcceptUserOrganisationsStep.vue';
import AdminAcceptUserReviewStep from '~/components/AdminAcceptUserReviewStep.vue';
import AdminAcceptUserSuccess from '~/components/AdminAcceptUserSuccess.vue';
import BaseAlert from '~/components/BaseAlert.vue';
import BasePage from '~/components/BasePage.vue';
import Stepper from '~/components/Stepper.vue';

import type { IAdminRequestedUser, IAdminUserAcceptForm } from '~/types';

import AdminUserRequest from '~/pages/AdminUserRequest.vue';

const { userId } = defineProps<{
  userId: string;
}>();

const userListPath = {
  name: 'AdminUsersIndex',
  query: {
    origin: ORIGIN_EXTERNAL,
  },
};

const page = computed(() => ({
  title: 'Accept User',
  backButton: {
    label: 'User List',
    to: userListPath,
  },
}));

const titles = [
  { title: 'Review the user' },
  { title: 'Link or create organisation' },
  { title: 'Done' },
];

const user = ref<IAdminRequestedUser>();
const initialForm = reactive<IAdminUserAcceptForm>({
  newOrganisation: getOrganisationNewForm(),
  organisation: null,
});

const form = reactive<IAdminUserAcceptForm>({
  newOrganisation: getOrganisationNewForm(),
  organisation: null,
});

const { stateIs, setState } = useState(
  'IDLE',
  'SUCCESSFUL',
  'LOADING',
  'ERROR',
  'RESOLVED',
);

const serviceState = useService(
  Service.users()
    .publicRequests()
    .byId(userId)
    .onErrorConflict(() => {
      setState('RESOLVED');
    }),
  ({ data }) => {
    user.value = data;

    return true;
  },
);

const onAssignOrganisation = (next: () => void) => {
  Service.users()
    .publicRequests()
    .accept(userId, form.organisation?.id)
    .onStart(() => {
      setState('LOADING');
    })
    .onSuccess(() => {
      next();
      setState('SUCCESSFUL');
    })
    .onErrorConflict(() => {
      setState('RESOLVED');
    })
    .onError(() => {
      setState('ERROR');
    })
    .execute();
};

const onCreateNewOrganisation = (next: () => void) => {
  Service.users()
    .publicRequests()
    .store(userId, getOrganisationFormData(form.newOrganisation))
    .onStart(() => {
      setState('LOADING');
    })
    .onErrorConflict(() => {
      setState('RESOLVED');
    })
    .onError(() => {
      setState('ERROR');
    })
    .onSuccess(() => {
      sendCreateOrganisationEvent(form.newOrganisation);
      next();

      setState('SUCCESSFUL');
    })
    .execute();
};

useLeavePageWarning(
  computed(
    () =>
      JSON.stringify({
        organisation: initialForm.organisation,
        newOrganisation: getOrganisationFormData(initialForm.newOrganisation),
      }) ===
      JSON.stringify({
        organisation: form.organisation,
        newOrganisation: getOrganisationFormData(form.newOrganisation),
      }),
  ),
  computed(() => stateIs.value('SUCCESSFUL')),
);
</script>

<template>
  <AdminUserRequest v-if="stateIs('RESOLVED')" />
  <BasePage v-else v-bind="page">
    <BaseAlert type="error" class="col-span-full" v-if="stateIs('ERROR')">
      Sorry, we could not assign Organisation to this user. Please try again.
    </BaseAlert>

    <Stepper :titles="titles" form class="col-span-full">
      <AdminAcceptUserReviewStep
        :user="user"
        :user-list-path="userListPath"
        :serviceState="serviceState"
      />

      <AdminAcceptUserOrganisationsStep
        validateStep="true"
        v-model:form="form"
        v-model:initialForm="initialForm"
        :user="user"
        :loading="stateIs('LOADING')"
        @assignOrgSubmit="onAssignOrganisation"
        @createOrgSubmit="onCreateNewOrganisation"
      />

      <AdminAcceptUserSuccess
        :user="user"
        :selected-organisation="form.organisation?.name"
        :new-organisation="form.newOrganisation.name"
      />
    </Stepper>
  </BasePage>
</template>
