<script lang="ts" setup name="FiltersLayout">
import FiltersLayoutActive from '~/components/FiltersLayoutActive.vue';

const emit = defineEmits(['clear']);
</script>

<template>
  <div class="space-y-4 p-4" data-test="filters-layout">
    <div class="flex items-center justify-between">
      <div
        class="flex flex-1 flex-wrap items-center gap-2"
        data-test="filters-layout-filters"
      >
        <p class="text-body-2">Filter by</p>
        <slot />
      </div>
      <div
        v-if="$slots.sorting"
        class="flex flex-shrink-0 items-center space-x-2"
      >
        <p class="text-body-2">Sort by</p>
        <slot name="sorting" />
      </div>
      <div
        v-if="$slots.actions"
        class="flex flex-shrink-0 items-center space-x-2"
      >
        <slot name="actions" />
      </div>
    </div>
    <div v-if="$slots.tags">
      <FiltersLayoutActive @clear="emit('clear')">
        <slot name="tags" />
      </FiltersLayoutActive>
    </div>
    <div v-if="$slots.append">
      <slot name="append" />
    </div>
  </div>
</template>
