<script setup lang="ts" name="UserListPendingUser">
import { computed } from 'vue';

import { Item, useBaseItems } from '~/features/useBaseItem';
import { formatDataEntries } from '~/features/useUtilities';

import BaseButton from '~/components/BaseButton.vue';
import BaseItem from '~/components/BaseItem.vue';

import type { IAdminUserListPendingUser, ITradeLane } from '~/types';

const { user } = defineProps<{
  user: IAdminUserListPendingUser;
}>();

const cols = computed(() => {
  const [firstOrganisation] = user.organisations;

  const formattedTrades = formatDataEntries<ITradeLane>({
    entries: user.trades,
    max: 3,
    key: 'name',
  });

  return useBaseItems(
    Item.label('Organisation').value(firstOrganisation).fallback('-'),
    Item.label('Trades of Interest').value(formattedTrades).fallback('-'),
  );
});

const acceptPath = computed(() => ({
  name: 'AdminUserAcceptRequest',
  params: {
    userId: user.id,
  },
}));

const rejectPath = computed(() => ({
  name: 'AdminUserRejectRequest',
  params: {
    userId: user.id,
  },
}));
</script>

<template>
  <div class="grid grid-cols-4 px-6 py-3">
    <div class="flex flex-col">
      <p class="truncate text-body-1">
        {{ user.first_name }} {{ user.last_name }}
      </p>

      <p class="truncate text-body-2 text-charcoal-6">{{ user.email }}</p>
    </div>

    <BaseItem v-bind="item" v-for="item in cols" :key="item.key" />

    <div class="flex justify-end gap-x-2">
      <BaseButton variant="btn-tertiary" danger :to="rejectPath">
        Reject
      </BaseButton>
      <BaseButton :to="acceptPath">Accept</BaseButton>
    </div>
  </div>
</template>
