import {
  computed,
  type ComputedRef,
  type Ref,
  ref,
  watch,
  type WritableComputedRef,
} from 'vue';
import { useVModel } from '@vueuse/core';

import type { IFieldSelectOption, IFormField, IOption } from '~/types';

interface IFieldProps<T> {
  modelValue?: T;
  field: IFormField<T>;
  autofocus?: boolean;
  errors?: string[];
}

const getOptionsBySearch = <T extends IOption | IFieldSelectOption>(
  options: T[],
  label: string,
) =>
  options?.filter((option: T) =>
    option.name?.toLowerCase().includes(label.toLowerCase()),
  );

export const useFieldSelect = <
  T extends IFieldSelectOption = IFieldSelectOption,
>(
  props: IFieldProps<T>,
  emit: any,
) => {
  const inputValue = useVModel(props, 'modelValue', emit);
  const inputLabel = ref(inputValue.value?.name || '');

  const isSelected = computed(() => (option: T) => {
    if (!props.field.selectedOptions?.length) return false;

    return props.field.selectedOptions.some(
      (selectedOption: IFieldSelectOption) => selectedOption.id === option.id,
    );
  });

  const isActive = computed(
    () => (option: T) => inputValue.value?.id === option.id,
  );

  const { filteredOptions: options } = useFieldFilter(
    inputValue,
    inputLabel,
    () => props.field.options ?? [],
  );

  return {
    inputValue,
    inputLabel,
    isSelected,
    isActive,
    options,
  };
};

export const useFieldFilter = <T extends IFieldSelectOption | IOption>(
  inputValue: Ref<T | undefined> | WritableComputedRef<T | undefined>,
  inputLabel: Ref<string>,
  allOptions: T[] | (() => T[]),
) => {
  const filteredOptions = computed(() => {
    const options =
      typeof allOptions === 'function' ? allOptions() : allOptions;

    if (inputValue.value) {
      return options;
    }

    return inputLabel.value === ''
      ? options
      : getOptionsBySearch(options || [], inputLabel.value);
  });

  watch(
    () => inputValue.value,
    () => {
      inputLabel.value = inputValue.value?.name || '';
    },
  );

  watch(
    () => inputLabel.value,
    (value) => {
      if (!value) {
        inputValue.value = undefined;
      }
    },
  );

  return {
    filteredOptions,
  };
};

export const useCargoFilter = <T extends IFieldSelectOption | IOption>(
  inputValue: Ref<T | undefined> | WritableComputedRef<T | undefined>,
  inputLabel: Ref<string>,
  allOptions: ComputedRef<T[]>,
) => {
  const filteredOptions = computed(() => {
    if (inputValue.value) {
      return allOptions.value;
    }

    return inputLabel.value === ''
      ? allOptions.value
      : getOptionsBySearch(allOptions.value || [], inputLabel.value);
  });

  watch(
    () => inputValue.value,
    (value) => {
      if (value) {
        inputLabel.value = value.name;
      }
    },
  );

  watch(
    () => inputLabel.value,
    (value) => {
      if (value !== inputValue.value?.name) {
        inputValue.value = undefined;
      }
    },
  );

  return {
    filteredOptions,
  };
};
