import { ref } from 'vue';
import { defineStore } from 'pinia';

import { PINIA_STORE } from '~/constants';

export const useSidePaneStore = defineStore(PINIA_STORE.SIDE_PANE_STORE, () => {
  const pane = ref<string | null>(null);

  const openPane = (type: string) => {
    pane.value = type;
  };

  const closePane = () => {
    pane.value = null;
  };

  const tabs = ref<string[]>([]);
  const createTabs = (...paneTabs: string[]) => {
    tabs.value = paneTabs;
  };

  const selectedTab = ref<string | null>(null);

  const selectTab = (tab: string) => {
    if (!tabs.value.includes(tab)) {
      throw new Error('Invalid pane tab provided');
    }

    selectedTab.value = tab;
  };

  const makeSelectTab = (pane: string) => (tab: string) => {
    openPane(pane);
    selectTab(tab);
  };

  const resetStore = () => {
    pane.value = null;
    selectedTab.value = null;
    tabs.value = [];
  };

  return {
    tabs,
    createTabs,
    selectedTab,
    selectTab,
    makeSelectTab,
    pane,
    openPane,
    closePane,
    resetStore,
  };
});
