<script setup lang="ts" name="AdminUserOrganisationAssign">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import { useOrganisationsStore } from '~/store';

import { useShowModal } from '~/features/useModalsProvider';

import AdminAcceptUserOrganisationLink from '~/components/AdminAcceptUserOrganisationLink.vue';
import AdminAcceptUserOrganisationsNavigation from '~/components/AdminAcceptUserOrganisationsNavigation.vue';
import BaseCard from '~/components/BaseCard.vue';
import BaseCardLoading from '~/components/BaseCardLoading.vue';
import ModalConfirmNeutral from '~/components/ModalConfirmNeutral.vue';

import type {
  IAdminRequestedUser,
  IFormField,
  IOption,
  IStepperConfig,
} from '~/types';

const props = defineProps<{
  loading: boolean;
  modelValue: IOption | null;
  user?: IAdminRequestedUser;
  stepperConfig?: IStepperConfig;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: IOption): void;
  (e: 'assignOrgSubmit', next: () => void): void;
  (e: 'showCreateOrg'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);
const organisationStore = useOrganisationsStore();

const showModal = useShowModal();

const field = computed<IFormField>(() => ({
  name: 'organisations',
  label: 'Organisation list',
  rules: 'required:Please select an organisation in order to assign',
  component: 'FieldSingleSelect',
  searchable: true,
  capitalize: false,
  options: organisationStore.organisations.map((org) => ({
    id: org.id,
    name: org.name,
  })),
}));

const assignOrganisation = async () => {
  if (!inputValue.value) {
    props.stepperConfig?.onNextStep();
    return;
  }

  const fullName = `${props.user?.first_name} ${props.user?.last_name}`;

  const result = await showModal(ModalConfirmNeutral, {
    title: `Assign <span class="italic">${fullName}</span> to <span class="italic">${inputValue.value?.name}</span>?`,
    message: `Are you sure you want to assign <span class="font-bold">${fullName}</span> to <span class="font-bold">${inputValue.value?.name}</span> Organisation? Keep in mind that user will get full access to the Organisation and will be notified via email.`,
    confirm: 'Assign',
  });

  if (result.action === 'CONFIRM') {
    emit('assignOrgSubmit', props.stepperConfig?.onNextStep ?? (() => {}));
  }
};
</script>

<template>
  <div>
    <BaseCard v-if="loading">
      <BaseCardLoading small />
    </BaseCard>

    <AdminAcceptUserOrganisationLink
      v-else
      v-model="inputValue"
      :user="user"
      :field="field"
    />

    <AdminAcceptUserOrganisationsNavigation
      :organisationExists="user?.organisationExists"
      @assignOrganisation="assignOrganisation"
      @prev="stepperConfig?.onPrevStep"
      @showCreateOrg="emit('showCreateOrg')"
    />
  </div>
</template>
