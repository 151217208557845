<script setup lang="ts" name="FormWizardVerticalHeaderCollapsible">
import { computed } from 'vue';

import BaseCollapsible from '~/components/BaseCollapsible.vue';
import FormWizardVerticalHeaderTab from '~/components/FormWizardVerticalHeaderTab.vue';
import Icon from '~/components/Icon.vue';

const { activeStepIdx, step, disabled } = defineProps<{
  step: number;
  activeStepIdx: number;
  title?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  toggle: [];
}>();

const isCompleted = computed(() => step - 1 < activeStepIdx);
const isActive = computed(() => step - 1 === activeStepIdx);

const onToggle = (toggle: () => void) => {
  if (isCompleted.value && !disabled) {
    toggle();
    emit('toggle');
  }
};
</script>

<template>
  <BaseCollapsible :openByDefault="isActive">
    <template #trigger="{ toggle, isOpen }">
      <button
        type="button"
        class="w-full bg-charcoal-0 p-6"
        :class="{ 'pointer-events-none': disabled || !isCompleted }"
        @click="onToggle(toggle)"
      >
        <div class="flex items-center justify-between">
          <FormWizardVerticalHeaderTab
            :isActive="isActive"
            :step="step"
            :isCompleted="isCompleted"
            :title="title"
            :disabled="disabled"
          />

          <Icon
            :icon="isOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'"
            class="text-primary-8"
          />
        </div>
      </button>
    </template>

    <slot />
  </BaseCollapsible>
</template>
