<script lang="ts" setup name="FieldTag">
import Icon from '~/components/Icon.vue';

const { name = '' } = defineProps<{
  name?: string;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();
</script>

<template>
  <button
    type="button"
    class="flex cursor-pointer items-center space-x-2 rounded-full bg-ocean-2 p-2 text-primary-8 transition-colors hover:bg-ocean-5"
    @click="emit('click')"
  >
    <p class="flex-1 text-sm leading-tight">
      {{ name }}
    </p>
    <Icon icon="mdi:close-circle" size="custom" class="text-[18px]" />
  </button>
</template>
