<script setup name="FieldDate" lang="ts">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import DatePickerField from '~/components/DatePickerField.vue';
import FieldBaseErrors from '~/components/FieldBaseErrors.vue';

import type { IFormFieldDate } from '~/types';

const props = withDefaults(
  defineProps<{
    field: IFormFieldDate;
    modelValue: Date | null;
    errors?: string[];
    type?: string | null;
  }>(),
  {
    modelValue: () => new Date(),
    errors: (): string[] => [],
    type: null,
  },
);

const emit = defineEmits<{
  'update:modelValue': [value: Date | null];
}>();

const inputValue = useVModel(props, 'modelValue', emit);
const masks = {
  input: 'DD/MM/YYYY',
};

const hasErrors = computed(() => props.errors.length > 0);
</script>

<template>
  <div :data-test="`${field.name}-wrapper`">
    <v-date-picker
      v-model="inputValue"
      color="orange"
      :masks="masks"
      :min-date="field.minDate"
      :max-date="field.maxDate"
      :popover="{ visibility: 'focus' }"
    >
      <template #default="{ inputValue: dateValue, inputEvents }">
        <DatePickerField
          :name="field.name"
          :value="dateValue"
          :label="field.label"
          :has-errors="hasErrors"
          v-on="inputEvents"
        />
      </template>
    </v-date-picker>
    <FieldBaseErrors
      v-if="hasErrors"
      class="mt-1"
      :errors="errors"
      :data-test="`${field.name}-error`"
    />
  </div>
</template>
