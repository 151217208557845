<script setup lang="ts" name="AdminOrganisationsIndex">
import { computed, reactive, ref } from 'vue';

import { hasOneOfPermissions } from '~/features/usePermissions';
import { getOrgRoute } from '~/features/useRouteHelpers';
import { useService } from '~/features/useService.js';
import { getIdsFromArray, getKeyFromSearch } from '~/features/useUrlSearch';
import { addIf } from '~/features/useUtilities';

import Service from '~/services/Service.js';

import AdminOrganisationsFilters from '~/components/AdminOrganisationsFilters.vue';
import AdminOrganisationsTypeTag from '~/components/AdminOrganisationsTypeTag.vue';
import BasePage from '~/components/BasePage.vue';
import CustomCard from '~/components/CustomCard.vue';
import DataTable from '~/components/DataTable.vue';
import DataTableActions from '~/components/DataTableActions.vue';
import DataTableLoading from '~/components/DataTableLoading.vue';
import SearchField from '~/components/SearchField.vue';
import ServiceCard from '~/components/ServiceCard.vue';

import type {
  IBasePage,
  IDataTableSettings,
  IOrganisation,
  IOrganisationType,
  SortDirection,
} from '~/types';

type OrganisationRow = Pick<IOrganisation, 'id' | 'name'> & {
  type: IOrganisationType;
  userCount: number;
  canEdit: boolean;
  canView: boolean;
};

interface IQuery {
  search: string | null;
  sortBy: string;
  sortDirection: SortDirection;
}

const page = computed<IBasePage>(() => ({
  title: 'Organisation List',
  actions: addIf(hasOneOfPermissions(['organization.create.any']), {
    label: 'New Organisation',
    icon: 'mdi:plus',
    to: { name: 'AdminOrganisationNew' },
  }),
}));

const orgTypes = [
  { id: 'agency', name: 'Broker' },
  { id: 'charterer', name: 'Charterer' },
];

const tableRows = ref<OrganisationRow[]>([]);

const query = reactive<IQuery>({
  search: getKeyFromSearch('search'),
  sortBy: '',
  sortDirection: null,
});

const filtersQuery = reactive({
  type: [],
});

const onSearchClear = () => {
  query.search = '';
  query.sortBy = '';
  query.sortDirection = null;
  filtersQuery.type = [];
};

const onSort = (key: string, direction: SortDirection) => {
  if (query.sortBy === key) {
    query.sortBy = '';
    query.sortDirection = null;
    return;
  }

  query.sortBy = key;
  query.sortDirection = direction;
};

const serviceState = useService(
  Service.organisationList().index(),
  ({ data }: { data: OrganisationRow[] }) => {
    if (!data.length) return false;
    tableRows.value = data;
    return true;
  },
  {
    loading: 'skeleton',
    cancelOnNewRequest: true,
    paginated: true,
    query: computed(() => ({
      search: query.search,
      sort_by: query.sortBy,
      sort_direction: query.sortBy ? query.sortDirection : null,
      type: filtersQuery.type,
    })),
    getQuery: () => ({
      search: query.search,
      sort_by: query.sortBy,
      sort_direction: query.sortBy ? query.sortDirection : null,
      type: getIdsFromArray(filtersQuery.type),
    }),
  },
);

const tableSettings = computed<IDataTableSettings<OrganisationRow>>(() => ({
  editable: false,
  pagination: true,
  columns: [
    {
      header: 'Organisation Name',
      key: 'name',
      visible: true,
    },
    {
      header: 'Organisation Type',
      key: 'type',
      sortable: true,
      sortDirection: query.sortDirection,
      allowedSortDirection: 'asc',
      visible: true,
      render: () => AdminOrganisationsTypeTag,
    },
    {
      header: 'Number of Users',
      key: 'userCount',
      fallback: '0',
      align: 'left',
    },
  ],
  actions: (item?: OrganisationRow) => {
    const disabledTooltip =
      'You are not assigned to this organisation. Please contact a G2O admin for more information.';

    return [
      {
        key: 'edit',
        icon: 'ic:baseline-edit',
        dataTestId: 'row-edit',
        tooltipDescription:
          item?.canEdit && item?.canView ? 'Edit' : disabledTooltip,
        variant: 'btn-tertiary',
        disabled: !item?.canEdit,
        to: {
          name: 'OrganisationSettingsEdit',
          params: {
            organisationId: item?.id ?? '',
            name: item?.name ?? '',
            view: 'list',
          },
        },
      },
      {
        key: 'view',
        icon: 'mdi:eye',
        dataTestId: 'row-view',
        tooltipDescription: item?.canEdit ? 'View' : disabledTooltip,
        variant: 'btn-tertiary',
        disabled: !item?.canView,
        to: getOrgRoute('OrganisationSettingsIndex', item?.id ?? ''),
      },
    ];
  },
}));
</script>

<template>
  <BasePage v-bind="page">
    <div class="col-span-full space-y-2">
      <AdminOrganisationsFilters v-model="filtersQuery" :orgTypes="orgTypes">
        <SearchField
          v-if="!serviceState.stateIsError"
          v-model="query.search"
          placeholder="Search by the Organisation Name"
        />
      </AdminOrganisationsFilters>

      <CustomCard>
        <ServiceCard
          v-bind="serviceState"
          @clear="onSearchClear"
          noResultsMessage="Please amend the search criteria or filter options to see available data"
        >
          <DataTableLoading
            v-if="serviceState.stateIsLoading"
            :columns="tableSettings.columns.length"
          />
          <DataTable
            v-else
            :data="tableRows"
            :settings="tableSettings"
            @sort="onSort"
          >
            <template #actions="{ item }">
              <DataTableActions :actions="tableSettings.actions!(item)" />
            </template>
          </DataTable>
        </ServiceCard>
      </CustomCard>
    </div>
  </BasePage>
</template>
