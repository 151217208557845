interface ResizeVNodeDirective {
  value: () => void;
  modifiers?: {
    quiet: boolean;
  };
  options?: boolean | AddEventListenerOptions;
}

interface ResizeElement extends HTMLElement {
  _onResize?: {
    callback: () => void;
    options: boolean | AddEventListenerOptions;
  };
}

function mounted(el: ResizeElement, binding: ResizeVNodeDirective) {
  const callback = binding.value;
  const options = binding.options ?? { passive: true };

  window.addEventListener('resize', callback, options);
  el._onResize = {
    callback,
    options,
  };

  if (!binding.modifiers?.quiet) callback();
}

function unmounted(el: ResizeElement) {
  if (!el._onResize) return;

  const { callback, options } = el._onResize;
  window.removeEventListener('resize', callback, options);
  el._onResize = undefined;
}

export const Resize = {
  mounted,
  unmounted,
};

export default Resize;
