<script lang="ts" setup name="RouterTabsDropdown">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import RouterTabsDropdownLink from '~/components/RouterTabsDropdownLink.vue';
import TabsDropdownTrigger from '~/components/TabsDropdownTrigger.vue';
import XDropdown from '~/components/XDropdown.vue';

import type { IRouterTabLink } from '~/types';

const { tabs } = defineProps<{
  tabs: IRouterTabLink[];
  minWidth: number;
}>();

const route = useRoute();

const isActive = computed(() =>
  tabs.some((tab) => route.matched.some(({ name }) => name === tab.to.name)),
);
</script>

<template>
  <div class="flex-1" :style="{ minWidth: `${minWidth}px` }">
    <XDropdown placement="bottom-end">
      <template #trigger="{ toggle }">
        <TabsDropdownTrigger
          label="More"
          icon="ic:baseline-more-horiz"
          data-testid="tabs-more-button"
          :active="isActive"
          @click="toggle"
        />
      </template>

      <template #default="{ close }">
        <div class="w-44 py-3">
          <RouterTabsDropdownLink
            v-for="tab in tabs"
            :key="tab.label"
            :label="tab.label"
            :to="tab.to"
            @click="close"
          />
        </div>
      </template>
    </XDropdown>
  </div>
</template>
