import {
  addDays,
  format,
  fromUnixTime,
  getQuarter,
  getTime,
  parse,
} from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

export const getYear = (date: string | Date) => parseDate(date, 'yyyy');

export const getDateQuarter = (date: string | Date) =>
  getQuarter(new Date(date));

export const getYearMonth = (date: string | Date) =>
  parseDate(date, 'MMM, yyyy');

export const getDateYear = (date: string | Date) =>
  parseDate(date, 'd MMM yyyy');

export const getDateTime = (date: string | Date) =>
  parseDate(date, 'd MMM yyyy - hh:mm');

export const getTimeFromDate = (date: string | Date) => {
  // The regular expression /\d{1,2}:\d{2}/ matches one or two digits,
  // followed by a colon, followed by two digits - a common format for time.
  const matchesOneOrTwoDigits = /\d{1,2}:\d{2}/;

  if (typeof date === 'string' && matchesOneOrTwoDigits.test(date)) {
    const time = parseDate(date, 'HH:mm');
    return time;
  }

  if (date instanceof Date) {
    return parseDate(date, 'HH:mm');
  }

  return null;
};

export const getYesterday = () => addDays(new Date(), -1);

export const getTimestamp = (date: Date) => getTime(date);

export const parseToUtc = (date: string) => {
  const refDate = new Date(Date.UTC(0, 0, 0, 0, 0, 0));
  return fromZonedTime(parse(date, 'd MMM yyyy', refDate), 'UTC');
};

export const getYearlyQuarter = (item: Date) => {
  const quarter = getDateQuarter(item);
  const year = getYear(item);

  return `Q${quarter}, ${year}`;
};

const parseDate = (date?: string | Date, formatString?: string): string => {
  if (!(date && formatString)) {
    return '';
  }

  const parsedDate = new Date(date);

  return parsedDate.toString() === 'Invalid Date'
    ? (date as string)
    : format(parsedDate, formatString);
};

export const formatDateRange = (
  startDate?: string,
  endDate?: string,
  prefix = '',
) => {
  if (!startDate) {
    return null;
  }

  const from = getDateYear(startDate);

  if (!endDate) {
    return `${prefix}${from}`;
  }

  const to = getDateYear(endDate);

  return `${prefix}${from} - ${to}`;
};

export const formatUnixToUTC = (timestamp: number) => {
  const date = fromUnixTime(timestamp);

  const utcDate = toZonedTime(date, 'UTC');

  return getDateYear(utcDate);
};
