export const NOMINATION_WIDGET_TYPE = {
  TOTAL_NOMINATED: 'totalNominated',
  TOTAL_DECLARED: 'totalDeclared',
  TO_BE_NOMINATED: 'toBeNominated',
} as const;

export const NOMINATION_WIDGET_TYPE_TO_TITLE = {
  [NOMINATION_WIDGET_TYPE.TOTAL_NOMINATED]: 'Total Nominated',
  [NOMINATION_WIDGET_TYPE.TOTAL_DECLARED]: 'Total Declared',
  [NOMINATION_WIDGET_TYPE.TO_BE_NOMINATED]: 'To Be Nominated',
} as const;

export const NOMINATION_PANE_TABS = {
  OVERVIEW: 'overview',
  MESSAGES: 'messages',
} as const;

export const generatedMessageAuthor = {
  id: 0,
  name: 'MyG2',
} as const;
