<script setup lang="ts" name="AdminUserRequest">
import BaseButton from '~/components/BaseButton.vue';
import BasePageLayout from '~/components/BasePageLayout.vue';
import CustomCard from '~/components/CustomCard.vue';

const path = {
  name: 'AdminUsersIndex',
};
</script>

<template>
  <BasePageLayout>
    <div class="col-span-full mt-10">
      <CustomCard>
        <div class="my-28 flex flex-col items-center gap-4 text-primary-8">
          <p class="text-headline">Request Resolved!</p>
          <p class="text-body-1">
            This request has already been resolved by the team.
          </p>

          <BaseButton
            variant="btn-secondary"
            data-test="success-card-button"
            :to="path"
          >
            go to user list
          </BaseButton>
        </div>
      </CustomCard>
    </div>
  </BasePageLayout>
</template>
