<script setup name="PortsRow">
import { computed } from 'vue';

import { getDateYear } from '~/features/useDates';

import PortsNumber from '~/components/PortsNumber.vue';

const props = defineProps({
  port: {
    type: Object,
    required: true,
  },
  isFirst: {
    type: Boolean,
    default: false,
  },
  isLast: {
    type: Boolean,
    default: false,
  },
  portNumber: {
    type: [Number, String],
    default: 0,
  },
});

const portName = computed(() => props.port.name);
const portArrivalDate = computed(() => getDateYear(props.port.arrivalDateTime));
</script>

<template>
  <div
    class="relative grid items-start gap-4"
    :class="{ 'pt-4': !isFirst }"
    style="grid-template-columns: 1.5rem 4rem 1fr"
  >
    <div
      class="absolute border-2 border-secondary-3"
      style="left: 11px"
      :style="{
        top: isFirst ? '50%' : 0,
        bottom: isLast ? '50%' : 0,
      }"
    />
    <PortsNumber :number="portNumber" />
    <p class="max-w-max whitespace-nowrap">
      {{ portArrivalDate }}
    </p>
    <p class="ml-09 text-secondary-9">
      {{ portName }}
    </p>
  </div>
</template>
