<script lang="ts" setup name="Field" generic="T, Options">
import { type Component, computed, watch } from 'vue';
import { useField } from 'vee-validate';

import CompaniesList from '~/components/CompaniesList.vue';
import FieldAcceptInvitation from '~/components/FieldAcceptInvitation.vue';
import FieldAdminOrganisations from '~/components/FieldAdminOrganisations.vue';
import FieldComboBox from '~/components/FieldComboBox.vue';
import FieldDate from '~/components/FieldDate.vue';
import FieldDateRange from '~/components/FieldDateRange.vue';
import FieldMultipleEmails from '~/components/FieldMultipleEmails.vue';
import FieldNumber from '~/components/FieldNumber.vue';
import FieldNumeric from '~/components/FieldNumeric.vue';
import FieldPassword from '~/components/FieldPassword.vue';
import FieldRadio from '~/components/FieldRadio.vue';
import FieldSelectTradeContract from '~/components/FieldSelectTradeContract.vue';
import FieldSelectVoyage from '~/components/FieldSelectVoyage.vue';
import FieldSingleSelect from '~/components/FieldSingleSelect.vue';
import FieldSingleSelectDate from '~/components/FieldSingleSelectDate.vue';
import FieldText from '~/components/FieldText.vue';
import FieldTextarea from '~/components/FieldTextarea.vue';
import InfoTooltip from '~/components/InfoTooltip.vue';
import OrganisationKpiMetricsMonthRangeCalendar from '~/components/OrganisationKpiMetricsMonthRangeCalendar.vue';

import type { IFormField, IFormFieldComponent } from '~/types';

const {
  modelValue,
  field,
  autofocus = false,
} = defineProps<{
  modelValue?: T;
  field: IFormField<Options>;
  autofocus?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: unknown): void;
  (e: 'change'): void;
  (e: 'blur'): void;
}>();

const lookup: Record<IFormFieldComponent, Component> = {
  FieldText,
  FieldDate,
  FieldDateRange,
  FieldNumber,
  FieldNumeric,
  FieldPassword,
  FieldTextarea,
  FieldComboBox,
  FieldRadio,
  FieldSelectVoyage,
  FieldSelectTradeContract,
  FieldMultipleEmails,
  FieldAcceptInvitation,
  FieldSingleSelect,
  FieldSingleSelectDate,

  CompaniesList,
  FieldAdminOrganisations,
  OrganisationKpiMetricsMonthRangeCalendar,
};

const dynamicRules = computed(() => (field.disabled ? '' : field.rules));

const { errorMessage, value, handleChange, setErrors } = useField(
  field.name,
  dynamicRules,
  {
    initialValue: modelValue,
    validateOnValueUpdate: false,
    syncVModel: true,
  },
);

const updateValue = () => {
  handleChange(value.value);
};

const onBlur = () => {
  emit('blur');
  updateValue();
};

const onChange = () => {
  emit('change');
  updateValue();
};

// This makes the validation eager when the field has errors
const onVModelUpdate = () => {
  if (errorMessage.value) {
    updateValue();
  }
};

const validationListeners = computed(() => {
  if (field?.mode === 'aggressive') {
    return {
      blur: onBlur,
      change: onChange,
    };
  } else {
    return {
      blur: null,
      change: null,
    };
  }
});

const errors = computed(() => {
  if (errorMessage.value) return [errorMessage.value];

  return [];
});

const fieldComponent = computed(() => lookup[field.component]);

watch(
  () => value.value,
  (newValue) => {
    emit('update:modelValue', newValue);
  },
  { deep: true },
);

watch(
  () => field.errorList,
  (val) => {
    if (val) {
      setErrors(val);
    }
  },
);
</script>

<template>
  <div class="relative w-full">
    <component
      :is="fieldComponent"
      v-model="value"
      v-bind="validationListeners"
      :field="field"
      :errors="errors"
      :autofocus="autofocus"
      @update:model-value="onVModelUpdate"
    >
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData"></slot>
      </template>
    </component>
    <InfoTooltip
      v-if="field.tooltip"
      class="absolute right-[-30px] top-4"
      placement="right-start"
      :description="field.tooltip"
    />
  </div>
</template>
