<script lang="ts" setup name="Avatar">
import { computed } from 'vue';

import {
  AVATAR_PENDING,
  AVATAR_SIZES,
  AVATAR_VARIANT,
  AVATAR_VARIANT_LIST,
} from '~/constants';

import type { AvatarPending, AvatarSizes, AvatarVariant } from '~/types';

const {
  name = '',
  variantByIndex = null,
  variant = AVATAR_VARIANT.OCEAN_0,
  size = AVATAR_SIZES.DEFAULT,
} = defineProps<{
  name?: string;
  variantByIndex?: number;
  variant?: AvatarVariant | AvatarPending;
  size?: AvatarSizes;
}>();

const PENDING_TEXT = '...';

const getInitial = (text: string) => text[0].toUpperCase();

const initials = computed(() => {
  if (name) {
    const values = name.split(' ');

    if (values.length >= 2) {
      const [first, last] = values;
      const firstLetter = getInitial(first);
      const secondLetter = getInitial(last);

      return `${firstLetter}${secondLetter}`;
    }

    return getInitial(name);
  }

  return PENDING_TEXT;
});

const sizeIs = computed(() => (currSize: AvatarSizes) => currSize === size);

const indexedVariant = computed(() => {
  if (variantByIndex === null) {
    return variant;
  }

  return AVATAR_VARIANT_LIST[variantByIndex % AVATAR_VARIANT_LIST.length];
});

const variantIs = computed(
  () => (currVariant: AvatarVariant | AvatarPending) =>
    currVariant === indexedVariant.value,
);
</script>

<template>
  <div
    class="flex flex-shrink-0 items-center justify-center rounded-full uppercase"
    :class="{
      'h-14 w-14 text-title': sizeIs(AVATAR_SIZES.LARGE),
      'h-8 w-8 text-subtitle-3': sizeIs(AVATAR_SIZES.DEFAULT),
      'h-9 w-9 text-subtitle-3': sizeIs(AVATAR_SIZES.MEDIUM),
      'h-5 w-5 text-xs font-bold': sizeIs(AVATAR_SIZES.SMALL),

      'bg-ocean-0 !text-ocean-7': variantIs(AVATAR_VARIANT.OCEAN_0),
      'bg-ocean-1 !text-ocean-6': variantIs(AVATAR_VARIANT.OCEAN_1),
      'bg-orange-0 !text-orange-7': variantIs(AVATAR_VARIANT.ORANGE_0),
      'bg-orange-1 !text-orange-6': variantIs(AVATAR_VARIANT.ORANGE_1),
      'bg-purple-0 !text-purple-7': variantIs(AVATAR_VARIANT.PURPLE_0),
      'bg-navy-blue-0 !text-navy-blue-7': variantIs(AVATAR_VARIANT.BLUE_0),
      'bg-magenta-0 !text-magenta-7': variantIs(AVATAR_VARIANT.MAGENTA_0),
      'bg-indigo-0 !text-indigo-7': variantIs(AVATAR_VARIANT.INDIGO_0),
      'bg-indigo-1 !text-indigo-6': variantIs(AVATAR_VARIANT.INDIGO_1),
      'bg-chestnut-0 !text-chestnut-7': variantIs(AVATAR_VARIANT.CHESTNUT_0),
      'bg-pink-0 !text-pink-7': variantIs(AVATAR_VARIANT.PINK_0),
      'bg-amber-0 !text-amber-7': variantIs(AVATAR_VARIANT.AMBER_0),
      'bg-green-0 !text-green-7': variantIs(AVATAR_VARIANT.GREEN_0),
      'bg-red-0 !text-red-7': variantIs(AVATAR_VARIANT.RED_0),
      'bg-charcoal-2 !text-white': variantIs(AVATAR_PENDING),
    }"
  >
    {{ initials }}
  </div>
</template>
