<script setup name="UserListFilters">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import FilterMenuSelectMultiple from '~/components/FilterMenuSelectMultiple.vue';
import FiltersLayout from '~/components/FiltersLayout.vue';
import FilterTag from '~/components/FilterTag.vue';

const props = defineProps({
  modelValue: {
    type: [Number, String, Object, Array, Date, Boolean],
    default: null,
  },
  organisations: {
    type: Array,
    default: null,
  },
  roles: {
    type: Array,
    required: true,
  },
  userTypes: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const query = useVModel(props, 'modelValue', emit);

const setActiveFilterType = (type) => (filter) => ({
  ...filter,
  type,
});

const activeFilters = computed(() => [
  ...query.value.organisations.map(setActiveFilterType('organisations')),
  ...query.value.roles.map(setActiveFilterType('roles')),
  ...query.value.userTypes.map(setActiveFilterType('userTypes')),
]);

const onClearFilter = (filter) =>
  (query.value[filter.type] = query.value[filter.type].filter(
    (x) => x.id !== filter.id,
  ));

const clearAllFilters = () => {
  query.value.organisations = [];
  query.value.roles = [];
  query.value.userTypes = [];
};
</script>

<template>
  <FiltersLayout @clear="clearAllFilters">
    <FilterMenuSelectMultiple
      v-model="query.organisations"
      label="Organisations"
      searchable
      :options="organisations"
    />
    <FilterMenuSelectMultiple
      v-model="query.roles"
      label="Role"
      :options="roles"
    />
    <FilterMenuSelectMultiple
      v-model="query.userTypes"
      label="User Type"
      :options="userTypes"
    />

    <template v-if="activeFilters.length" #tags>
      <FilterTag
        v-for="filter in activeFilters"
        :key="filter.name"
        :name="filter.name"
        @click="onClearFilter(filter)"
      />
    </template>
  </FiltersLayout>
</template>
