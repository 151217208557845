<script lang="ts" setup name="AdminUserEdit">
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { ROLES } from '~/constants';
import { useSnackbarStore } from '~/store';

import {
  getAdminEditForm,
  getAdminEditFormData,
  getAdminEditSuccessPage,
} from '~/features/useAdminForms';
import { useLeavePageWarning } from '~/features/useLeavePageWarning';
import { useShowModal } from '~/features/useModalsProvider';
import { useRouteBack } from '~/features/useRouteBack';
import { useService } from '~/features/useService.js';
import { checkDirty } from '~/features/useUtilities';

import Service from '~/services/Service.js';

import BasePage from '~/components/BasePage.vue';
import Field from '~/components/Field.vue';
import FieldFormSection from '~/components/FieldFormSection.vue';
import FormWizard from '~/components/FormWizard.vue';
import FormWizardStep from '~/components/FormWizardStep.vue';
import ModalConfirmUserDelete from '~/components/ModalConfirmUserDelete.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import SuccessCard from '~/components/SuccessCard.vue';
import UserForm from '~/components/UserForm.vue';

import type {
  AdminUserFormMeta,
  AdminUserFormMetaResponse,
  AdminUserShowResponse,
  IFormFieldComponent,
  IOptionItem,
  ValidationErrorResponse,
} from '~/types';

const { userId } = defineProps<{
  userId?: number | string;
}>();

const snackbar = useSnackbarStore();
const showModal = useShowModal();
const router = useRouter();

const userListName = 'User List';
const userListPath = useRouteBack({ name: 'AdminUsersIndex' });
const success = getAdminEditSuccessPage(userListName, userListPath);

const meta = ref<AdminUserFormMeta>();
const organisations = ref<IOptionItem[]>([]);
const previousOrganisations = ref<IOptionItem[]>([]);
const emailVerified = ref(false);

const finished = ref(false);
const submitting = ref(false);
const submitError = ref(false);
const errors = ref<Record<string, string>>();

const initialForm = reactive(getAdminEditForm());
const form = reactive(getAdminEditForm());

const titles = [
  {
    title: 'User details',
  },
  {
    title: 'Organisations',
  },
];

const page = computed(() => {
  return {
    title: 'Edit G2O User',
    backButton: {
      label: userListName,
      to: userListPath,
    },
    actions: finished.value
      ? []
      : [
          {
            label: 'Remove user',
            icon: 'ic:baseline-delete',
            variant: 'btn-tertiary',
            danger: true,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            click: onRemoveUser,
          },
        ],
  };
});

const serviceState = useService(
  Service.user(userId).show(),
  ({ data }: AdminUserShowResponse) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setFormValues(data);
    emailVerified.value = data.email_verified;
    return true;
  },
);

const destroyUser = () => {
  return Service.user(userId)
    .destroy()
    .onSuccess(async () => {
      snackbar.add({
        type: 'success',
        html: `The user <span class="font-bold">${form.email}</span> removed successfully.`,
      });

      router.push(userListPath);
    })
    .execute();
};

const onRemoveUser = async () => {
  const result = await showModal(ModalConfirmUserDelete, {
    email: form.email,
    hasOneOrganisation: true,
    isExternal: false,
    isPublic: false,
  });

  if (result.action === 'CONFIRM') {
    destroyUser();
  }
};

const schema = reactive({
  organisations: {
    name: 'organisations',
    label: 'Organisations',
    component: 'FieldAdminOrganisations' as IFormFieldComponent,
    rules: 'required',
    organisations: computed(() => organisations.value || []),
    role: computed(() => form.role),
  },
});

const setFormValues = ({
  first_name,
  last_name,
  email,
  role,
  organisations,
}) => {
  [form, initialForm].forEach((item) => {
    item.firstName = first_name;
    item.lastName = last_name;
    item.email = email;
    item.role = role;
    item.organisations = organisations;
  });
};

const setValidationErrors = (data: ValidationErrorResponse) => {
  errors.value = data.response.data?.errors;
  submitting.value = false;
};

const onSubmit = () => {
  Service.user(userId)
    .update(getAdminEditFormData(form))
    .onStart(() => {
      submitting.value = true;
    })
    .onError(() => {
      submitError.value = true;
      submitting.value = false;
    })
    .onSuccess(() => {
      finished.value = true;

      form.firstName = '';
      form.lastName = '';
      form.email = '';
      form.role = null;
      form.organisations = [];

      submitting.value = false;
    })
    .onErrorValidation(setValidationErrors)
    .execute();
};

const setOrganisations = (newOrganisations: IOptionItem[]) => {
  form.organisations = newOrganisations;
};

const resetOrganisations = (newOrganisations: IOptionItem[]) => {
  setOrganisations(newOrganisations);
};

watch(
  () => form.role,
  (newRole, previousRole) => {
    if (newRole?.name === ROLES.G2O_ADMIN) {
      previousOrganisations.value = [...form.organisations];
      setOrganisations([...organisations.value]);
      return;
    }

    if (previousRole?.name === ROLES.G2O_ADMIN) {
      resetOrganisations([...previousOrganisations.value]);
      previousOrganisations.value = [];
    }
  },
);

onBeforeUnmount(() => {
  submitError.value = false;
});

useLeavePageWarning(
  computed(() => checkDirty(initialForm, form, getAdminEditFormData)),
  finished,
);

Service.users()
  .newUserMeta()
  .onSuccess(({ data }: AdminUserFormMetaResponse) => {
    meta.value = data;
    organisations.value = data.organisations;
  })
  .execute();
</script>

<template>
  <BasePage v-bind="page">
    <div class="col-span-full">
      <SuccessCard v-if="finished" v-bind="success" />

      <ServiceCard v-else v-bind="serviceState" styled>
        <FormWizard
          :loading="submitting"
          :errors="errors"
          :submit-error="submitError"
          :titles="titles"
          @submit="onSubmit"
        >
          <FormWizardStep>
            <FieldFormSection title="Edit User Details">
              <UserForm
                v-model="form"
                :meta="meta"
                :email-verified="emailVerified"
              />
            </FieldFormSection>
          </FormWizardStep>
          <FormWizardStep>
            <Field v-model="form.organisations" :field="schema.organisations" />
          </FormWizardStep>
        </FormWizard>
      </ServiceCard>
    </div>
  </BasePage>
</template>
