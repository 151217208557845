import { type InjectionKey, provide } from 'vue';

import type { IAdminViewUser } from '~/types';

import { useStrictInject } from './useStrictInject';

const AdminUserView: InjectionKey<{
  serviceState: Record<string, unknown>;
  user: IAdminViewUser;
  userId: number;
}> = Symbol('AdminUserView');

export const injectAdminUserView = () => useStrictInject(AdminUserView);

export const provideAdminUserView = (viewState: {
  serviceState: Record<string, unknown>;
  user: IAdminViewUser;
  userId: number;
}) => provide(AdminUserView, viewState);
