import axios from 'axios';

import type {
  IOrganisation,
  IProfile,
  IProfileData,
  IProfileResponse,
  IRawProfile,
} from '~/types';

const byName = (a: IOrganisation, b: IOrganisation) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;

  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

  return 0;
};

export const getProfile = (profile: IRawProfile): IProfile => ({
  id: profile.id,
  firstName: profile.first_name,
  lastName: profile.last_name,
  email: profile.email,
  isG2OEmployee: profile.g2o_employee,
  tourCompleted: profile.tour_completed,
  role: profile.role,
  userType: profile.user_type,
});

export const fetchProfileData = async (): Promise<IProfileData> => {
  const response = await axios.get<IProfileResponse>('/api/customer/profile');

  const {
    profile,
    organizations,
    internal_permissions,
    unseenNotifications,
    maintenance,
  } = response.data.data;

  return {
    profile: getProfile(profile),
    organisations: organizations.sort(byName),
    internalPermissions: internal_permissions,
    unseenNotifications,
    maintenance,
  };
};

export const getDefaultOrg = (
  organisations?: IOrganisation[],
  organisation?: IOrganisation | null,
): IOrganisation | null => {
  if (!organisations) {
    return null;
  }

  return (
    organisations.find((x) => x.id === organisation?.id) || organisations[0]
  );
};

export const setBaseProfileData = (
  profile,
  organisations,
  permissions,
  commit,
) => {
  commit('SET_PROFILE', profile);
  commit('SET_TOUR_COMPLETED', profile.tourCompleted);
  commit('SET_INTERNAL_PERMISSIONS', permissions);
  commit('SET_ORGANISATIONS', organisations);
};

export const getTagRole = (
  isG2OEmployee: boolean,
  role: string | undefined,
) => {
  if (!role) {
    return '-';
  }

  return isG2OEmployee ? `G2O ${role}` : role;
};
