<script lang="ts" setup name="TabsDropdownTrigger">
import Icon from '~/components/Icon.vue';

import type { IconType } from '~/types';

const {
  icon,
  label,
  active = false,
} = defineProps<{
  icon: IconType;
  label: string;
  active?: boolean;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();
</script>

<template>
  <button
    type="button"
    class="relative flex w-full flex-col items-center justify-center space-y-2 border-b-4 bg-white px-4 pb-3 pt-4 text-xs"
    :class="{
      'border-primary-8 text-primary-8 hover:bg-green-0': active,
      'border-white text-charcoal-6 hover:border-charcoal-0 hover:bg-charcoal-0':
        !active,
    }"
    @click="emit('click')"
  >
    <Icon :icon="icon" class="h-5 w-5" />
    <p class="uppercase">
      {{ label }}
    </p>
  </button>
</template>
