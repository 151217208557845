<script setup lang="ts" name="SearchField">
import { nextTick, onMounted, ref } from 'vue';
import { useVModel } from '@vueuse/core';

import { useTabFocusEvent } from '~/features/useTabFocusEvent';

import Icon from '~/components/Icon.vue';

const props = withDefaults(
  defineProps<{
    modelValue?: string | null;
    placeholder?: string;
    disabled?: boolean;
    maxlength?: number;
    error?: boolean;
    size?: 'small' | 'large';
    icon?: 'none' | 'search';
    focus?: boolean;
    isolated?: boolean;
  }>(),
  {
    isolated: true,
    modelValue: null,
    placeholder: 'Search...',
    disabled: false,
    maxlength: 128,
    size: 'large',
    icon: 'search',
  },
);

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
  (event: 'clear'): void;
  (event: 'click'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const searchInputRef = ref<HTMLInputElement>();

const clear = () => {
  inputValue.value = '';
  emit('clear');
};

const { tabKeyup } = useTabFocusEvent(searchInputRef);

onMounted(() => {
  nextTick(() => {
    if (props.focus) {
      searchInputRef.value?.focus({ preventScroll: true });
    }
  });
});
</script>

<template>
  <component
    :is="isolated ? 'div' : 'label'"
    class="group relative flex items-center rounded-t bg-white text-charcoal-6 focus-within:border-b-2"
    :class="{
      'border-b-[0.7px] pb-[1px] focus-within:border-b-2': size === 'large',
      'border-b focus-within:border-b-[1.5px]': size === 'small',
      'ring ring-ocean-3': tabKeyup,
      'border-charcoal-4': disabled,
      'border-red-6 focus-within:border-red-6': error,
      'border-charcoal-6 focus-within:border-primary-6 focus-within:pb-0 focus-within:text-primary-6':
        !disabled && !error,
    }"
  >
    <div
      v-if="icon === 'search'"
      class="pointer-events-none mr-4 flex items-center justify-start"
      :class="{
        'text-charcoal-2': disabled,
        'my-2 ml-5': size === 'small',
        'my-4 ml-7': size === 'large',
      }"
    >
      <Icon icon="mdi:magnify" />
    </div>
    <input
      ref="searchInputRef"
      v-model="inputValue"
      type="text"
      data-test="search-field"
      class="mr-16 w-full min-w-[200px] text-black focus:outline-none"
      :class="{
        'placeholder-charcoal-4': disabled,
        'placeholder-charcoal-6': !disabled,
        'py-4': icon === 'none',
      }"
      :maxlength="maxlength"
      :disabled="disabled"
      :placeholder="placeholder"
      @click="emit('click')"
    />
    <div
      v-if="inputValue"
      class="absolute right-0 flex cursor-pointer items-center justify-start"
      data-test="search-field-clear"
      :class="{
        'mr-4': size === 'small',
        'mr-7': size === 'large',
      }"
      @click="clear"
    >
      <Icon icon="ic:round-close" />
    </div>
  </component>
</template>
