<script setup lang="ts" name="BackButton">
import { useAttrs } from 'vue';
import { RouterLink } from 'vue-router';

import Icon from '~/components/Icon.vue';

const { label = 'Back' } = defineProps<{
  label?: string;
  href?: string;
}>();

defineOptions({
  inheritAttrs: false,
});

const attrs = useAttrs();
</script>

<template>
  <component
    :is="href ? 'a' : RouterLink"
    :href="href"
    class="inline-flex cursor-pointer items-center rounded pr-1 text-xs font-bold uppercase tracking-widest text-primary-8"
    v-bind="attrs"
  >
    <Icon icon="mdi:arrow-left-thin" />
    <span class="ml-2">{{ label }}</span>
  </component>
</template>
