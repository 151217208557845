import type { InjectionKey, Ref } from 'vue';
import { markRaw, provide, ref } from 'vue';
import { v4 } from 'uuid';

import { useStrictInject } from '~/features/useStrictInject';

import type {
  IModalComponent,
  IModalType,
  ModalProps,
  ModalResult,
} from '~/types';

const getIK = <T>(key: string): InjectionKey<T> => Symbol(key);

export type ShowModal = <T extends IModalComponent>(
  component: T,
  props: Omit<ModalProps<T>, 'closeModal'>,
  preventClickOutside?: boolean,
) => Promise<ModalResult<T>>;

type Modals = Ref<IModalType<IModalComponent>[]>;

const ShowModalKey = getIK<ShowModal>('ShowModalKey');
const ModalsKey = getIK<Modals>('ModalsKey');

function provideShowModal(showModal: ShowModal) {
  provide(ShowModalKey, showModal);
}

function provideModals(modals: Modals) {
  provide(ModalsKey, modals);
}

export const useShowModal = () => useStrictInject(ShowModalKey);
export const useModalsProviderModals = () => useStrictInject(ModalsKey);

export const useModalsProvider = () => {
  const modals = ref<IModalType<IModalComponent>[]>([]);

  function showModal<T extends IModalComponent>(
    component: T,
    props: Omit<ModalProps<T>, 'closeModal'>,
    preventClickOutside?: boolean,
  ) {
    const id = (props?.id as string) || v4();

    if (modals.value.some((m) => m.id === id)) {
      return new Promise<ModalResult<T>>((resolve) =>
        resolve({ action: 'DUPLICATE_CALL' } as ModalResult<T>),
      );
    }

    return new Promise<ModalResult<T>>((resolve) =>
      modals.value.push(
        markRaw({
          id,
          component,
          preventClickOutside,
          props: {
            ...props,
            closeModal: (data) => {
              modals.value = modals.value.filter((m) => m.id !== id);
              resolve(data as ModalResult<T>);
            },
          },
        }),
      ),
    );
  }

  provideShowModal(showModal);
  provideModals(modals);
};
