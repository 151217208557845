<script setup lang="ts" name="FieldSelectVoyage">
import { computed, onMounted } from 'vue';
import { useVModel } from '@vueuse/core';

import { useProfileStore } from '~/store';

import BaseRadio from '~/components/BaseRadio.vue';
import Ports from '~/components/Ports.vue';

import type { IFormField } from '~/types';

interface IFilteredVoyageByPort {
  voyageId: string;
  vesselName: string;
  tradeCode: string;
  ports: {
    port: {
      name: string;
      arrivalDateTime: string;
    };
    isFirst: boolean;
    isLast: boolean;
    portNumber: number | string[];
  }[];
}

const props = withDefaults(
  defineProps<{
    field: IFormField<IFilteredVoyageByPort>;
    modelValue: IFilteredVoyageByPort | string;
    errors?: string[];
  }>(),
  {
    errors: () => [],
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: IFilteredVoyageByPort | string): void;
  (e: 'change'): void;
  (e: 'blur'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const quoteText = computed(() =>
  useProfileStore().isG2OEmployee
    ? {
        mainText: 'Complete change without selecting a vessel/voyage.',
      }
    : {
        mainText: 'Send a quote for a customised offer.',
        secondaryText: 'Send a request for a suitable vessel.',
      },
);

onMounted(() => {
  if (!props.field.options?.length) inputValue.value = 'custom';
});
</script>

<template>
  <div class="bg-charcoal-1 p-4">
    <div
      v-if="field.options?.length"
      class="flex border-b border-charcoal-2 p-5"
    >
      <BaseRadio
        v-for="voyage in field.options"
        :key="voyage.voyageId"
        v-model="inputValue"
        name="voyage"
        :native-value="voyage"
        @change="emit('change')"
        @blur="emit('blur')"
      >
        <div class="ml-5">
          <div class="flex space-x-2">
            <p class="text-lg">
              {{ voyage.vesselName }}
            </p>
            <div class="rounded bg-charcoal-1 p-1 text-sm uppercase">
              {{ voyage.tradeCode }}
            </div>
          </div>
          <Ports :ports="voyage.ports" class="mt-4" />
        </div>
      </BaseRadio>
    </div>

    <div class="bg-white">
      <BaseRadio
        v-model="inputValue"
        native-value="custom"
        class="mb-3 flex border-b border-charcoal-2 p-5"
        name="voyage"
        @change="emit('change')"
        @blur="emit('blur')"
      >
        <div class="py-09">
          <p>{{ quoteText.mainText }}</p>
          <p v-if="quoteText.secondaryText" class="mt-2 text-sm">
            {{ quoteText.secondaryText }}
          </p>
        </div>
      </BaseRadio>
    </div>
  </div>
</template>
