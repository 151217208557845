<script setup lang="ts" name="Tooltip">
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import type { Instance, Placement } from '@popperjs/core';
import { createPopper } from '@popperjs/core';

const {
  description,
  title,
  placement = 'bottom-start',
  offset = [0, 8],
  variant = 'dark',
  width,
} = defineProps<{
  description: string;
  title?: string;
  width?: number;
  placement?: Placement;
  offset?: [number, number];
  variant?: 'dark' | 'light';
}>();

const isShown = ref(false);
const isInitialized = ref(false);

const popperInstance = ref<Instance>();
const triggerRef = ref<Element>();
const tooltipRef = ref<HTMLElement>();
const arrowRef = ref<HTMLDivElement>();

const setTriggerRef = (el: unknown) => {
  triggerRef.value = el as Element;
};

const initPopper = () => {
  if (!(triggerRef.value && tooltipRef.value)) {
    return;
  }

  popperInstance.value = createPopper(triggerRef.value, tooltipRef.value, {
    placement: placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset,
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowRef.value,
        },
      },
    ],
  });
  popperInstance.value.update();
};

function show() {
  isInitialized.value = true;
  isShown.value = true;

  nextTick(() => {
    if (!popperInstance.value) {
      initPopper();
    }

    if (tooltipRef.value) {
      tooltipRef.value.setAttribute('data-show', '');
    }

    if (popperInstance.value) {
      popperInstance.value.update();
    }
  });
}

function hide() {
  isShown.value = false;

  if (tooltipRef.value) {
    tooltipRef.value.removeAttribute('data-show');
  }
}

onBeforeUnmount(() => {
  popperInstance.value?.destroy();
  popperInstance.value = undefined;
});
</script>

<template>
  <slot name="trigger" :show="show" :hide="hide" :setRef="setTriggerRef" />

  <Teleport to="#tooltipTarget" v-if="isInitialized || isShown">
    <div
      ref="tooltipRef"
      id="tooltip"
      data-testid="tooltip-content"
      class="z-[1000] rounded-lg p-4 text-sm"
      :class="{
        'w-max max-w-sm': !width,
        'bg-charcoal-2 text-charcoal-9': variant === 'light',
        'bg-charcoal-9 text-white': variant === 'dark',
      }"
      :style="{
        width: `${width}px`,
      }"
    >
      <p v-if="title" class="mb-2 font-bold">
        {{ title }}
      </p>
      <p>{{ description }}</p>
      <div ref="arrowRef" id="arrow" data-popper-arrow></div>
    </div>
  </Teleport>
</template>
