<script lang="ts" setup name="BaseAutoPagination">
import { computed } from 'vue';

import {
  injectNextPage,
  injectPagination,
  injectPrevPage,
} from '~/features/useAutoPagination';

import PaginationButton from '~/components/PaginationButton.vue';
import PaginationDropdown from '~/components/PaginationDropdown.vue';

const { disabled = false, styled = false } = defineProps<{
  disabled?: boolean;
  styled?: boolean;
}>();

const pagination = injectPagination();
const onNextPage = injectNextPage();
const onPreviousPage = injectPrevPage();

const current = computed(() => {
  if (pagination.value.from <= 1) return pagination.value.to;

  if (pagination.value.to >= pagination.value.total)
    return `${pagination.value.from} - ${pagination.value.total}`;

  return `${pagination.value.from} - ${pagination.value.to}`;
});
</script>

<template>
  <div
    class="flex flex-wrap items-center space-x-4 rounded-b"
    :class="{
      'pointer-events-none opacity-50': disabled,
      'justify-end border-t border-charcoal-2 bg-white p-4': styled,
    }"
  >
    <PaginationDropdown
      v-model="pagination.perPage"
      :options="pagination.options"
      :disabled="disabled"
    />
    <p
      data-testid="auto-pages-count"
      class="whitespace-nowrap text-sm text-charcoal-6"
    >
      {{ current }} of {{ pagination.total }}
    </p>
    <div class="flex items-center space-x-1">
      <PaginationButton
        data-test="previous-button"
        :disabled="!pagination.hasPrevious || disabled"
        @click="onPreviousPage"
      />
      <PaginationButton
        is-next
        data-test="next-button"
        :disabled="!pagination.hasNext || disabled"
        @click="onNextPage"
      />
    </div>
  </div>
</template>
