<script setup lang="ts" name="AdminOrganisationNew">
import { computed, onBeforeUnmount, reactive, ref } from 'vue';

import { ROLES } from '~/constants';

import { sendCreateOrganisationEvent } from '~/features/useAnalytics';
import { useLeavePageWarning } from '~/features/useLeavePageWarning';
import {
  getOrganisationDefaultSchema,
  getOrganisationFormData,
  getOrganisationNewForm,
} from '~/features/useOrganisationForm';
import { useRouteBack } from '~/features/useRouteBack';
import { useService } from '~/features/useService';
import { checkDirty } from '~/features/useUtilities';

import Service from '~/services/Service.js';

import AdminOrganisationNewCustomerUsers from '~/components/AdminOrganisationNewCustomerUsers.vue';
import AdminOrganisationNewSettings from '~/components/AdminOrganisationNewSettings.vue';
import BasePage from '~/components/BasePage.vue';
import Field from '~/components/Field.vue';
import FieldFormSection from '~/components/FieldFormSection.vue';
import FormWizard from '~/components/FormWizard.vue';
import FormWizardStep from '~/components/FormWizardStep.vue';
import OrganisationGeneralInfo from '~/components/OrganisationGeneralInfo.vue';
import OrganisationUsersForm from '~/components/OrganisationUsersForm.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import SuccessCard from '~/components/SuccessCard.vue';

import type {
  IFormField,
  IOrganisationCustomerUser,
  IOrganisationNew,
  IOrganisationUser,
  Setting,
} from '~/types';

const organisationListPath = useRouteBack({ name: 'AdminOrganisationsIndex' });
const backButtonName = 'Organisation List';
const finished = ref(false);
const submitting = ref(false);
const submitError = ref(false);
const errors = ref<Record<string, string>>({});

const userList = ref([]);
const customerRoles = ref([]);

const page = computed(() =>
  !finished.value
    ? {
        title: 'Create New Organisation',
        backButton: {
          label: backButtonName,
          to: organisationListPath,
        },
      }
    : {},
);

const initialForm = getOrganisationNewForm();
const form = reactive<IOrganisationNew>(getOrganisationNewForm());

const preselectInternalAdmins = (users: IOrganisationUser[]) => {
  const internalAdmins = users.filter(
    (user) => user.role.name === ROLES.G2O_ADMIN,
  );

  form.users = internalAdmins;
};

const serviceState = useService(
  Service.organisationList().newOrganisationMeta(),
  ({ data }) => {
    preselectInternalAdmins(data.users);

    userList.value = data.users.filter((user) => user.email_verified);
    customerRoles.value = data.roles;
    return true;
  },
);

const defaultSchema = getOrganisationDefaultSchema();

const schema = computed<Record<string, IFormField>>(() => ({
  organisationName: defaultSchema.organisationName,
  organisationType: defaultSchema.organisationType,
  companies: {
    ...defaultSchema.companies,
    type: form.type,
  },
}));

const titles = computed(() => [
  {
    title: 'General Info',
  },
  {
    title: form.type === 'charterer' ? 'Companies' : 'Brokers',
  },
  {
    title: 'G2O Users',
  },
  {
    title: form.type === 'charterer' ? 'Customer Users' : 'Broker Users',
  },
  {
    title: 'Settings',
  },
]);

const updateSettings = (settings: Setting[]) => {
  form.settings = settings
    .filter((feature) => feature.enabled)
    .map((feature) => feature.id);
};

const updateUsers = (users: IOrganisationCustomerUser[]) => {
  form.customerUsers = users;
};

const success = ref({
  title: 'Thank you!',
  subTitle: 'You Have Successfully Created Organisation.',
  buttonText: backButtonName,
  path: organisationListPath,
});

const onSubmit = () => {
  Service.organisationList()
    .store(getOrganisationFormData(form))
    .onStart(() => {
      submitting.value = true;
    })
    .onError(() => {
      submitError.value = true;
      submitting.value = false;
    })
    .onSuccess(() => {
      finished.value = true;

      sendCreateOrganisationEvent(form);

      success.value.subTitle = `You Have Successfully Created ${form.name}.`;
      form.name = '';

      submitting.value = false;
    })
    .onErrorValidation((data) => {
      errors.value = data.response.data?.errors;
      submitting.value = false;
    })
    .execute();
};

useLeavePageWarning(
  computed(() => checkDirty(initialForm, form, getOrganisationFormData)),
  finished,
);

onBeforeUnmount(() => {
  submitError.value = false;
});
</script>

<template>
  <BasePage v-bind="page">
    <div class="col-span-full">
      <SuccessCard v-if="finished" v-bind="success" />

      <ServiceCard v-else v-bind="serviceState" styled>
        <FormWizard
          :loading="submitting"
          :submit-error="submitError"
          :errors="errors"
          :titles="titles"
          @submit="onSubmit"
        >
          <FormWizardStep>
            <FieldFormSection title="Provide General Information" />

            <OrganisationGeneralInfo :schema="schema" :form="form" />
          </FormWizardStep>

          <FormWizardStep>
            <Field v-model="form.companies" :field="schema.companies" />
          </FormWizardStep>

          <FormWizardStep>
            <OrganisationUsersForm v-model="form.users" :users="userList" />
          </FormWizardStep>

          <FormWizardStep>
            <AdminOrganisationNewCustomerUsers
              :orgType="form.type"
              :customer-users="form.customerUsers"
              :customer-roles="customerRoles"
              @update="updateUsers"
            />
          </FormWizardStep>

          <FormWizardStep>
            <AdminOrganisationNewSettings
              :settings="form.settings"
              @update-settings="updateSettings"
            />
          </FormWizardStep>
        </FormWizard>
      </ServiceCard>
    </div>
  </BasePage>
</template>
