<script lang="ts" setup name="BaseCollapsible">
import { ref, watch } from 'vue';

import TransitionExpand from '~/components/TransitionExpand.vue';

const {
  transitionClass,
  openByDefault = false,
  closeOnLeave = false,
} = defineProps<{
  openByDefault?: boolean;
  closeOnLeave?: boolean;
  transitionClass?: string;
}>();

const emit = defineEmits<{
  (e: 'open'): void;
  (e: 'close'): void;
}>();

const isOpen = ref(openByDefault);

const toggle = () => {
  isOpen.value = !isOpen.value;

  if (isOpen.value) {
    emit('open');
  } else {
    emit('close');
  }
};

watch(
  () => closeOnLeave,
  (value) => {
    if (value && isOpen.value) {
      toggle();
    }
  },
);

watch(
  () => openByDefault,
  (newValue) => {
    isOpen.value = newValue;
  },
);
</script>

<template>
  <div>
    <slot name="trigger" :toggle="toggle" :is-open="isOpen" />
    <TransitionExpand :class="transitionClass">
      <div v-if="isOpen">
        <slot :toggle="toggle" />
      </div>
    </TransitionExpand>
  </div>
</template>
