export const REGISTRATION_BUSINESS_TYPE = {
  CARGO_OWNER: 'cargo_owner',
  PORT_AGENT: 'port_agent',
  BOOKING_AGENT: 'booking_agent',
  FREIGHT_FORWARDER: 'freight_forwarder',
  SUPPLIER: 'supplier',
  GOVERNMENT_AUTHORITY: 'government_authority',
  OTHER: 'other',
} as const;

export const REGISTRATION_SEARCH_OPTION_TYPE = {
  TRADES: 'Trades',
  PORTS: 'Ports',
  COUNTRIES: 'Countries',
} as const;

export const REGISTRATION_MAXIMUM_TRADES = 10;

export const REGISTRATION_STATUS = {
  EXPIRED: 'EXPIRED',
  ALREADY_CONFIRMED: 'ALREADY_CONFIRMED',
  SUCCESS: 'SUCCESS',
} as const;
