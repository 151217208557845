<script setup lang="ts" name="FieldText">
import { useVModel } from '@vueuse/core';

import FieldBaseErrors from '~/components/FieldBaseErrors.vue';
import FieldBaseInput from '~/components/FieldBaseInput.vue';

import type { IFormField } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue: string | null | number;
    field: IFormField;
    autofocus?: boolean;
    floatingLabel?: boolean;
    errors: string[];
  }>(),
  { floatingLabel: true, errors: () => [] },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value?: string): void;
  (e: 'change'): void;
  (e: 'blur'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <div :data-test="`${field.name}-wrapper`">
    <FieldBaseInput
      v-model="inputValue"
      class="w-full"
      :name="field.name"
      :label="field.label"
      :has-errors="errors.length > 0"
      :optional="!field.rules"
      :trim="field.trim"
      :floating-label="floatingLabel"
      :autocomplete="field.autocomplete"
      :disabled="field.disabled"
      :autofocus="autofocus"
      @change="emit('change')"
      @blur="emit('blur')"
    />
    <FieldBaseErrors
      v-if="errors.length > 0"
      :errors="errors"
      class="mt-1"
      :data-test="`${field.name}-error`"
    />
  </div>
</template>
