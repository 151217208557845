<script setup lang="ts" name="SortIcon">
import { computed } from 'vue';

import Icon from '~/components/Icon.vue';

import type { IconType, SortDirection } from '~/types';

const { sorted, direction } = defineProps<{
  sorted: boolean;
  direction?: SortDirection;
}>();

const icon = computed((): IconType => {
  if (!sorted) return 'g2o:sort-arrows';
  if (direction === 'desc') return 'g2o:sort-arrows-desc';

  return 'g2o:sort-arrows-asc';
});
</script>

<template>
  <Icon :icon="icon" />
</template>
