import type { IOption } from '~/types';

export interface IItem {
  id: string;
}

export const getIdsFromArray = <T extends IOption>(
  array?: T[],
): (number | string)[] =>
  Array.isArray(array) ? array.map((item) => item.id) : [];

const getSearchParams = (): URLSearchParams =>
  new URLSearchParams(window.location.search);

const toItem =
  <T extends IItem | IOption>(items: T[]) =>
  (id: string | number) =>
    items.find((item) =>
      item.id
        ? item.id.toString() === id.toString()
        : item.toString() === id.toString(),
    );

export const getItemsFromSearch = <T extends IItem | IOption>(
  key: string,
  items: T[],
): T[] => {
  const params = getSearchParams();

  const values = params.get(key)?.split(',').filter(Boolean) || [];

  return values.map(toItem(items)).filter(Boolean) as T[];
};

export const getItemsFromSearchOrDefault = <T extends IOption>(
  key: string,
  items: T[],
  selectedItems: T[] | T['id'][] | null | undefined,
): T[] => {
  const searchItems = getItemsFromSearch<T>(key, items);

  if (!searchItems.length && selectedItems) {
    return selectedItems
      .map((item) => item.id || item)
      .map((id) => toItem<T>(items)(id as string | number))
      .filter(Boolean) as T[];
  }

  return searchItems;
};

export const getKeyFromSearch = (key: string, fallback = ''): string | null =>
  getSearchParams().get(key) || fallback;

export const getBoolKeyFromSearch = (key: string): boolean => {
  const searchParams = getSearchParams();

  if (searchParams.has(key)) {
    const keyValue = searchParams.get(key);
    return keyValue === 'true' || keyValue === '';
  }

  return false;
};

export const getArrayFromSearch = (key: string): string[] =>
  getSearchParams().get(key)?.split(',').filter(Boolean) || [];

export function getItemFromSearch<T extends IItem>(
  key: string,
  items: T[],
  fallback: T,
): T;
export function getItemFromSearch<T extends IItem>(
  key: string,
  items: T[],
): T | undefined;
export function getItemFromSearch<T extends IItem>(
  key: string,
  items: T[],
  fallback?: T,
): T | undefined {
  const [first] = getItemsFromSearch<T>(key, items);
  return first || fallback;
}
