<script setup lang="ts" name="BaseToggle">
import { useVModel } from '@vueuse/core';

const props = defineProps<{
  modelValue: boolean;
  disabled?: boolean;
  tooltip?: string;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <label
    class="relative flex cursor-pointer items-center"
    :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
  >
    <input
      v-model="inputValue"
      type="checkbox"
      class="sr-only"
      :value="inputValue"
      :disabled="disabled"
    />
    <div
      v-tooltip="tooltip"
      data-testid="toggle"
      class="flex h-6 w-09 rounded-full bg-charcoal-0 p-1"
      :class="{
        'bg-primary-6': inputValue,
        'bg-charcoal-4': !inputValue,
        'cursor-not-allowed opacity-60': disabled,
        'cursor-pointer': !disabled,
      }"
    >
      <div
        class="h-4 w-4 transform rounded-full bg-white duration-300"
        :class="{ 'translate-x-6': inputValue }"
      />
    </div>
  </label>
</template>
