<script setup name="CompaniesList">
import { computed, ref, watch } from 'vue';
import { useVModel } from '@vueuse/core';

import { ORGANISATION_TYPE } from '~/constants';

import { useService } from '~/features/useService';
import { debounce } from '~/features/useUtilities';

import Service from '~/services/Service.js';

import FormWizardSearchableList from '~/components/FormWizardSearchableList.vue';
import ListCheckbox from '~/components/ListCheckbox.vue';
import LoadingIntersect from '~/components/LoadingIntersect.vue';
import ServiceCard from '~/components/ServiceCard.vue';

const props = defineProps({
  modelValue: {
    type: Array,
    default: null,
  },
  field: {
    type: Object,
    required: true,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const PER_PAGE = 10;

const selectedCompanies = useVModel(props, 'modelValue', emit);

const search = ref('');
const loadingSearch = ref(false);
const companies = ref([]);
const page = ref(1);
const allCompaniesLoaded = ref(false);
const loadingMoreCompanies = ref(false);

const isAgencyType = computed(
  () => props.field.type === ORGANISATION_TYPE.AGENCY,
);

const title = computed(() => {
  const prefix = props.field.edit ? 'Edit' : 'Add';

  return isAgencyType.value ? `${prefix} Broker` : `${prefix} Companies`;
});

const subtitle = computed(() =>
  isAgencyType.value
    ? 'Select the broker that you would like to use for this Organisation.'
    : 'Select affiliated Companies and link them to the Organisation.',
);

const service = Service.companies().index({
  search: '',
  page: 1,
  limit: PER_PAGE,
});

const addCompanies = (data = []) => {
  allCompaniesLoaded.value = data.length !== PER_PAGE;

  companies.value = [
    ...companies.value,
    ...data.map((company) => ({
      id: company.id,
      code: company.global_address_number,
      name: company.name,
    })),
  ];

  page.value += 1;
};

const serviceState = useService(service, ({ data }) => {
  addCompanies(data);
  return true;
});

const clearSearch = () => {
  search.value = '';
};

const removeCompany = (company) => {
  selectedCompanies.value = selectedCompanies.value.filter(
    (item) => item.id !== company.id,
  );
};

const fetchMoreCompanies = () => {
  Service.companies()
    .index({
      search: search.value,
      page: page.value,
      limit: PER_PAGE,
    })
    .onStart(() => (loadingMoreCompanies.value = true))
    .onSuccess(({ data }) => {
      addCompanies(data);
    })
    .onFinish(() => {
      loadingSearch.value = false;
      loadingMoreCompanies.value = false;
    })
    .execute();
};

const fetchMoreCompaniesDebounced = debounce(fetchMoreCompanies, 500);

watch(search, () => {
  loadingSearch.value = true;
  companies.value = [];
  page.value = 1;
  fetchMoreCompaniesDebounced();
});
</script>

<template>
  <ServiceCard v-bind="serviceState">
    <FormWizardSearchableList
      v-model="search"
      :title="title"
      :subtitle="subtitle"
      placeholder="Search by Name and Global Address Number"
      error-message="Please select at least one Company in order to proceed."
      :items="companies"
      :selected-items="selectedCompanies"
      :loading="loadingSearch"
      :errors="errors"
      @on-remove-selected-item="removeCompany"
      @clear-search="clearSearch"
    >
      <ListCheckbox
        v-for="company in companies"
        :key="company.id"
        v-model="selectedCompanies"
        :item="company"
      >
        <div class="px-4">
          <p>{{ company.name }}</p>
          <p class="truncate text-charcoal-6">
            {{ company.code }}
          </p>
        </div>
      </ListCheckbox>
      <LoadingIntersect
        v-if="!allCompaniesLoaded"
        :loading="loadingMoreCompanies"
        @intersect="fetchMoreCompanies"
      />
    </FormWizardSearchableList>
  </ServiceCard>
</template>
