<script setup name="FiltersLayoutActive" lang="ts">
import FiltersClearButton from '~/components/FiltersClearButton.vue';

const emit = defineEmits<{
  (e: 'clear'): void;
}>();
</script>

<template>
  <div class="space-y-2" data-test="active-filters">
    <p class="text-button-1 uppercase tracking-widest text-charcoal-6">
      Active Filters
    </p>
    <div class="mb-5 flex flex-wrap items-center gap-2">
      <slot />
      <FiltersClearButton @clear="emit('clear')" />
    </div>
  </div>
</template>
