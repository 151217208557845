import { computed, reactive, toRefs } from 'vue';
import { defineStore } from 'pinia';

import { BOOKING_PANES, PINIA_STORE } from '~/constants';

import {
  formatBookingRow,
  useCargoBookingBaseStore,
} from '~/features/cargoBooking';
import {
  addBooking,
  cargoBookingsByTypeUpdate,
  getBookingCreatePayload,
  getBookingUpdatePayload,
  storeBookings,
  updateBooking,
} from '~/features/cargoBooking/create';

import type {
  CargoBookingPort,
  ICargoBooking,
  ICargoBookingRow,
  ICargoCreateBooking,
  ICargoesByType,
  ICargoUpdateResponse,
  ICargoUploadEventPayload,
  IOption,
} from '~/types';

import { useSnackbarStore } from '.';

interface IState {
  cargoData: ICargoesByType<ICargoBookingRow>[] | null;
  isModalVisible: boolean;
  tradelanesFilterOptions: (IOption & { label: string })[] | null;
  reviewTradeQuery: IOption[];
}

export const useCargoBookingCreateStore = defineStore(
  PINIA_STORE.CARGO_BOOKING_CREATE,
  () => {
    const baseStore = useCargoBookingBaseStore();

    const state = reactive<IState>({
      cargoData: null,
      isModalVisible: false,
      tradelanesFilterOptions: null,
      reviewTradeQuery: [],
    });

    const openModal = () => {
      state.isModalVisible = true;
    };

    const closeModal = () => {
      state.isModalVisible = false;
    };

    const actionOrModal = async (
      action?: () => void | Promise<void>,
      pane?: string | null,
    ) => {
      if (pane === BOOKING_PANES.DECLARATION_CREATE) {
        openModal();
        return;
      }

      await action?.();
    };

    const getMaxPorts = (cargoes: ICargoesByType<ICargoBooking>[]) => {
      const maxPorts = Math.max(
        ...cargoes.flatMap((cargo) =>
          cargo.bookings.map((booking) => booking.dischargePorts.length),
        ),
      );

      return maxPorts;
    };

    const setCargoBookingsStates = (
      cargoes: ICargoesByType<ICargoBooking>[],
      totalDischargePorts: number,
    ) => {
      const newCargoData = cargoes?.map((item) => ({
        ...item,
        bookings: formatBookingRow(
          item.bookings,
          totalDischargePorts,
          item.type,
        ),
      })) as ICargoesByType<ICargoBookingRow>[];
      return newCargoData;
    };

    const setCargoData = (cargoes: ICargoesByType<ICargoBooking>[] | null) => {
      if (!cargoes) {
        state.cargoData = null;
        return;
      }

      const maxPorts = getMaxPorts(cargoes);

      baseStore.setTotalDischargePorts(maxPorts);

      state.cargoData = setCargoBookingsStates(cargoes, maxPorts);
    };

    const setTradelanesFilterOptions = (trades: IOption[]) => {
      if (!trades?.length) {
        state.tradelanesFilterOptions = [];
        return;
      }
      state.tradelanesFilterOptions = [
        ...trades.map((trade) => ({ ...trade, label: trade.name })),
        {
          id: 'all',
          name: 'All trades',
          label: 'All trades',
        },
      ];
    };

    const setReviewTradeQuery = (query: IOption[]) => {
      state.reviewTradeQuery = query;
    };

    /* Removal */
    const _removeBookings = (
      bookings: ICargoBookingRow[],
      selectedBookingId: string,
    ): ICargoBookingRow[] => {
      return bookings.filter(
        (booking) =>
          !(
            booking.id === selectedBookingId ||
            booking.continuationOfId === selectedBookingId
          ),
      );
    };
    const removeBooking = () => {
      if (
        !_cargoDataAndSelectedBookingExists() ||
        !baseStore.state.selectedBooking?.id
      ) {
        return false;
      }

      const filteredBookings = _removeBookings(
        cargoBySelectedBooking.value!.bookings,
        baseStore.state.selectedBooking!.id,
      );

      _setCargoGroupTypeBookings(filteredBookings);

      return true;
    };
    /* Removal */

    /* Cancellation */
    const _cancelBookingsById = (
      bookings: ICargoBookingRow[],
      selectedBookingId: string,
    ): ICargoBookingRow[] => {
      return bookings.map((booking) => {
        if (
          booking.id === selectedBookingId ||
          booking.continuationOfId === selectedBookingId
        ) {
          return { ...booking, cancelled: true, errors: [] };
        }
        return booking;
      });
    };

    const cancelBookings = (): boolean => {
      const selected = baseStore.state.selectedBooking;

      if (
        !_cargoDataAndSelectedBookingExists() ||
        !selected?.id ||
        !cargoBySelectedBooking.value
      ) {
        return false;
      }

      const updatedBookings = _cancelBookingsById(
        cargoBySelectedBooking.value.bookings,
        selected.id,
      );

      _setCargoGroupTypeBookings(updatedBookings);

      return true;
    };
    /* Cancellation */

    /* Update */
    const updateBookings = (orgId: OrganisationId, booking: any) => {
      const updatedBooking = getBookingUpdatePayload({
        booking,
        importId: baseStore.state.importId,
      });

      baseStore.setIsUpdating(true);

      updateBooking(orgId, updatedBooking)
        ?.then(({ data }: ICargoUpdateResponse) => {
          if (!state.cargoData) {
            return;
          }

          const { updateBookings } = cargoBookingsByTypeUpdate(state.cargoData);

          updateBookings(
            setCargoBookingsStates(data, baseStore.state.totalDischargePorts),
          );

          const selectedBookingId = baseStore.state.selectedBooking?.id;

          if (selectedBookingId) {
            const updatedSelectedBooking = _bookings.value.find(
              (booking) => selectedBookingId === booking.id,
            );

            baseStore.selectBooking(updatedSelectedBooking);
          }
        })
        .finally(() => {
          baseStore.setIsUpdating(false);
        });
    };
    /* Update */

    const getUpdatedCargoData = (
      oldCargoData: ICargoesByType<ICargoBooking>[],
      newCargoData: ICargoesByType<ICargoBooking>[],
    ) => {
      const newBookings = newCargoData.map((item) => {
        const dataItem = oldCargoData.find((cargo) => cargo.type === item.type);

        const newBookings = [
          ...item.bookings.map((booking) => ({ ...booking, highlight: true })),
          ...dataItem.bookings,
        ];

        newBookings.sort(
          (a, b) => new Date(a.laycanStart) - new Date(b.laycanStart),
        );

        return {
          ...dataItem,
          bookings: newBookings,
        };
      });

      return newBookings;
    };

    /* Creation */
    const addCreatedBooking = ({ data }: ICargoUploadEventPayload) => {
      if (state.cargoData === null) {
        return;
      }

      const newMaxPorts = getMaxPorts(data);
      const maxPorts = Math.max(
        baseStore.state.totalDischargePorts,
        newMaxPorts,
      );

      baseStore.setTotalDischargePorts(maxPorts);

      const newCargoData = setCargoBookingsStates(data, maxPorts);

      state.cargoData = getUpdatedCargoData(state.cargoData, newCargoData);

      setTimeout(() => {
        state.cargoData = state.cargoData.map((cargo) => ({
          ...cargo,
          bookings: cargo.bookings.map((booking) => ({
            ...booking,
            highlight: false,
          })),
        }));
      }, 1500);

      const newBookings = data.flatMap((item) => item.bookings);

      useSnackbarStore().add({
        type: 'success',
        text: `New Booking${
          newBookings.length > 1 ? 'Bookings' : 'Booking'
        } created`,
      });
    };
    const createBooking = (
      orgId: OrganisationId,
      bookingData: ICargoCreateBooking,
    ) => {
      if (state.cargoData === null) {
        return;
      }

      const { bookings, ...restBookingData } = bookingData;

      const formattedBookings = bookings.map((booking) =>
        getBookingCreatePayload({ booking }),
      );

      const formattedBookingData = {
        ...restBookingData,
        bookings: formattedBookings,
      };

      return addBooking(orgId, formattedBookingData);
    };
    /* Creation */

    /* Submit */
    const submitDeclaration = (orgId: OrganisationId) => {
      if (state.cargoData === null) {
        return;
      }

      return storeBookings(orgId, baseStore.state.importId!);
    };
    /* Submit */

    /* Utilities */
    const fillDischargePorts = () => {
      if (!state.cargoData) return;

      baseStore.incrementTotalDischargePorts();

      const newPort: CargoBookingPort = {
        id: null,
        cargoBookingPortId: null,
        name: null,
        qty: null,
        edited: false,
      };

      state.cargoData = state.cargoData?.map((item) => ({
        ...item,
        bookings: item.bookings.map((booking) => ({
          ...booking,
          dischargePorts: [...booking.dischargePorts, newPort],
        })),
      }));
    };

    const _getCargoByBookingId = (id: string) => {
      if (!state.cargoData) {
        return null;
      }

      return (
        state.cargoData.find((cargo) =>
          cargo.bookings.find((cargoBooking) => cargoBooking.id === id),
        ) ?? null
      );
    };

    const updateBookingNo = (id: string, newBookingNo: string) => {
      const booking = _bookings.value.find((booking) => booking.id === id);

      if (booking) {
        booking.bookingNo = newBookingNo;
      }
    };

    const _cargoDataAndSelectedBookingExists = () =>
      !!cargoBySelectedBooking.value?.bookings ||
      !!baseStore.state.selectedBooking;

    const _setCargoGroupTypeBookings = (bookings: ICargoBookingRow[]) => {
      if (!state.cargoData) {
        return;
      }

      const cargoTypeIndex = state.cargoData.findIndex(
        (cargoType) => cargoType.type === cargoBySelectedBooking.value?.type,
      );

      state.cargoData[cargoTypeIndex].bookings = bookings;
    };

    const resetStore = () => {
      state.cargoData = null;
      state.isModalVisible = false;
      state.tradelanesFilterOptions = null;
      state.reviewTradeQuery = [];
      baseStore.reset();
    };
    /* Utilities */

    /* Getters */
    const _bookings = computed(() => {
      if (!state.cargoData) {
        return [];
      }

      if (state.reviewTradeQuery.length) {
        return state.cargoData
          .flatMap((cargo) => cargo.bookings)
          .filter(({ trade }) =>
            state.reviewTradeQuery.find(({ id }) => id === trade?.id),
          );
      }

      return state.cargoData.flatMap((cargo) => cargo.bookings);
    });

    const cargoBySelectedBooking = computed(() =>
      baseStore.state.selectedBooking?.id
        ? _getCargoByBookingId(baseStore.state.selectedBooking.id)
        : null,
    );

    const totalErrors = computed(() => {
      return baseStore.calculateValidationErrors(_bookings.value);
    });
    /* Getters */

    return {
      ...toRefs(state),
      ...toRefs(baseStore.state),

      selectBooking: baseStore.selectBooking,
      isRowSelected: baseStore.isRowSelected,
      setSettings: baseStore.setSettings,
      setImportId: baseStore.setImportId,
      unselectBooking: baseStore.unselectBooking,
      setTotalDischargePorts: baseStore.setTotalDischargePorts,
      setBookingTransferError: baseStore.setBookingTransferError,

      totalErrors,

      closeModal,
      actionOrModal,

      setCargoBookingsStates,
      setCargoData,
      setTradelanesFilterOptions,
      setReviewTradeQuery,
      cargoBySelectedBooking,

      fillDischargePorts,

      addCreatedBooking,
      createBooking,
      removeBooking,
      updateBookings,
      cancelBookings,

      resetStore,

      submitDeclaration,
      updateBookingNo,
    };
  },
);
