import Service from '~/services/Service';

import type {
  ICargoCreateBooking,
  ICargoNominatedVessel,
  ICargoNominateUpdateResponse,
  ICargoUpdateResponse,
  ICargoUploadEventPayload,
} from '~/types';

export const storeBookings = (orgId: OrganisationId, importId: string) => {
  return new Promise((resolve, reject) => {
    Service.organisation(orgId)
      .cargoBookings()
      .store(importId)
      .onSuccess(resolve)
      .onError(reject)
      .execute();
  });
};

export const storeVessels = (orgId: OrganisationId, importId: string) => {
  return new Promise((resolve, reject) => {
    Service.organisation(orgId)
      .cargoBookings()
      .storeVessels(importId)
      .onSuccess(resolve)
      .onError(reject)
      .execute();
  });
};

export const addBooking = (
  orgId: OrganisationId,
  bookingData: ICargoCreateBooking,
): Promise<ICargoUploadEventPayload> | null => {
  return new Promise((resolve, reject) => {
    Service.organisation(orgId)
      .cargoBookings()
      .createBooking(bookingData)
      .onSuccess((data: ICargoUploadEventPayload) => resolve(data))
      .onErrorValidation(({ response }) => {
        reject(response.data.errors);
      })
      .onError(reject)
      .execute();
  });
};

export const updateBooking = (
  orgId: OrganisationId,
  booking: any,
): Promise<ICargoUpdateResponse> | null => {
  return new Promise((resolve, reject) => {
    Service.organisation(orgId)
      .cargoBookings()
      .updateBooking(booking)
      .onSuccess((data: ICargoUpdateResponse) => resolve(data))
      .onErrorValidation(({ response }) => {
        reject(response.data.errors);
      })
      .onError(reject)
      .execute();
  });
};

export const updateVessel = (
  orgId: OrganisationId,
  vessel: ICargoNominatedVessel,
): Promise<ICargoNominateUpdateResponse> | null => {
  return new Promise((resolve, reject) => {
    Service.organisation(orgId)
      .cargoBookings()
      .nomination()
      .update(vessel)
      .onSuccess((data: ICargoNominateUpdateResponse) => resolve(data))
      .onErrorValidation(({ response }) => {
        reject(response.data.errors);
      })
      .onError(reject)
      .execute();
  });
};
