<script
  lang="ts"
  setup
  name="FieldSelectTradeContract"
  generic="T extends IKpiTradeContract"
>
import { useFieldSelect } from '~/features/useFieldSelect';

import FieldSelectDropdown from '~/components/FieldSelectDropdown.vue';
import FilterRadioInput from '~/components/FilterRadioInput.vue';

import type { IFormTradeContractsField, IKpiTradeContract } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue?: T;
    field: IFormTradeContractsField<T>;
    autofocus?: boolean;
    errors?: string[];
  }>(),
  {
    autofocus: false,
    errors: (): string[] => [],
  },
);

const emit = defineEmits<{
  blur: [];
  change: [];
  'update:modelValue': [value: T];
}>();

const { inputValue, inputLabel, options, isActive, isSelected } =
  useFieldSelect<T>(props, emit);
</script>

<template>
  <FieldSelectDropdown
    v-model="inputLabel"
    :field="field"
    :errors="errors"
    :autofocus="autofocus"
    :options="options"
    @blur="emit('blur')"
    @change="emit('change')"
  >
    <template #default="{ close }">
      <div
        v-if="field.noContracts"
        class="w-full p-8 text-center text-sm text-charcoal-6"
      >
        No available trade contracts
      </div>
      <template v-else>
        <FilterRadioInput
          v-for="option in options"
          :key="option.id"
          :id="option.id"
          v-model="inputValue"
          :title="option.name"
          :native-value="option"
          :disabled="isSelected(option)"
          :active="isActive(option)"
          :selected="isSelected(option)"
          capitalize
          @select="close"
        >
          <slot name="item" :item="option">
            <div>
              <p>{{ option.name }}</p>
              <div class="text-gray-500 text-xs">
                {{ option?.periodStart }} - {{ option?.periodEnd }}
              </div>
            </div>
          </slot>
        </FilterRadioInput>
      </template>
    </template>
  </FieldSelectDropdown>
</template>
