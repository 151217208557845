import type { ICargoNominatedVesselRow } from '~/types';

export const cargoBookingsNominateUpdate = (
  oldVessels: ICargoNominatedVesselRow[],
  newVessels: ICargoNominatedVesselRow[],
) => {
  newVessels.forEach((newVessel) => {
    const oldVessel = oldVessels.find(({ id }) => id === newVessel.id);

    if (oldVessel) {
      Object.assign(oldVessel, newVessel);
    }
  });
};
