import type { Component } from 'vue';

import { BASE_ITEM_TYPE } from '~/constants';

import type {
  BaseItemType,
  BaseTagContrastType,
  IBaseIcon,
  IBaseItem,
  IBaseItemCircle,
} from '~/types';

const slugify = (string: string, separator = '-') =>
  string
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the accent
    .replace(/[\u0300-\u036F]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator);

export class Item {
  #fallback = '';

  #id = '';

  #tagVariant?: BaseTagContrastType;

  #label = '';

  #value: string | number | null = '';

  #link: string = '';

  #unit = '';

  #loading = false;

  #bold = false;

  #capitalize = false;

  #type: BaseItemType = 'default';

  #decimal = 0;

  #width: number | 'md' | 'lg' | 'xl' = 'md';

  #truncate = true;

  #useHtml = false;

  #swap = false;

  #disabled = false;

  #icon: IBaseIcon | null = null;

  #circle: IBaseItemCircle | null = null;

  #component: null | Component = null;

  static label(label: string) {
    return new Item(label);
  }

  /**
   * @param {string} label
   * @returns Item
   */
  constructor(label: string) {
    this.#id = slugify(label);
    this.#label = label;
    this.#type = 'default';
  }

  /**
   * @param {string} id
   * @returns Item
   */
  id(id: string) {
    this.#id = id;
    return this;
  }

  value(value: string | number | null = '') {
    this.#value = value;
    return this;
  }

  unit(unit: string) {
    this.#unit = unit;
    return this;
  }

  link(link: string) {
    this.#link = link;
    return this;
  }

  loading(loading = true) {
    this.#loading = loading;
    return this;
  }

  type(type: BaseItemType, decimal = 0) {
    this.#type = type;
    if (type === BASE_ITEM_TYPE.NUMBER || type === BASE_ITEM_TYPE.NUMBER_DANGER)
      this.#decimal = decimal;

    return this;
  }

  fallback(fallback = '-') {
    this.#fallback = fallback;
    return this;
  }

  bold() {
    this.#bold = true;
    return this;
  }

  capitalize() {
    this.#capitalize = true;
    return this;
  }

  preventTruncate() {
    this.#truncate = false;
    return this;
  }

  useHtml(use = false) {
    this.#useHtml = use;
    return this;
  }

  width(width: number | 'md' | 'lg' | 'xl') {
    this.#width = width;
    return this;
  }

  tagVariant(tagVariant: BaseTagContrastType) {
    this.#tagVariant = tagVariant;
    return this;
  }

  swap(swap = true) {
    this.#swap = swap;
    return this;
  }

  disabled(disabled = true) {
    this.#disabled = disabled;
    return this;
  }

  icon(icon: IBaseIcon) {
    this.#icon = icon;
    return this;
  }

  circle(circle: IBaseItemCircle) {
    this.#circle = circle;
    return this;
  }

  component(component: Component | null = null) {
    this.#component = component;
    return this;
  }

  toBaseItem(): IBaseItem {
    return {
      id: this.#id,
      key: this.#id,
      label: this.#label,
      value: this.#value,
      unit: this.#unit,
      link: this.#link,
      loading: this.#loading,
      type: this.#type,
      fallback: this.#fallback,
      bold: this.#bold,
      capitalize: this.#capitalize,
      decimal: this.#decimal,
      width: this.#width,
      truncate: this.#truncate,
      useHtml: this.#useHtml,
      tagVariant: this.#tagVariant,
      swap: this.#swap,
      disabled: this.#disabled,
      icon: this.#icon,
      circle: this.#circle,
      component: this.#component,
    };
  }
}

/**
 *
 * @param {Item[]} baseItems
 */
export const useBaseItems = (...baseItems: Item[]) =>
  baseItems.map((x) => x?.toBaseItem?.() || x);
