<script lang="ts" setup name="OrganisationSetting">
import { useVModel } from '@vueuse/core';

import BaseToggle from '~/components/BaseToggle.vue';

const props = defineProps<{
  modelValue: boolean;
  name: string;
  canEdit: boolean;
  subsetting?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const tooltip = !props.canEdit
  ? 'You are not authorised to enable settings. Please contact your admin for further information.'
  : undefined;

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <div
    class="relative flex w-full justify-between"
    :class="{
      'py-6 pl-8 pr-09': !subsetting,
      'py-4 pl-16 pr-09': subsetting,
      'hover:bg-charcoal-1': canEdit,
    }"
    :data-test="`organisation-${name}-setting`"
  >
    <button
      type="button"
      class="absolute inset-0 h-full w-full"
      :disabled="!canEdit"
      :class="canEdit ? 'cursor-pointer' : 'cursor-default'"
      @click="inputValue = !inputValue"
    />
    <p>
      {{ name }}
    </p>
    <BaseToggle v-model="inputValue" :tooltip="tooltip" :disabled="!canEdit" />
  </div>
</template>
