<script lang="ts" setup name="CountryWithFlag">
import { computed } from 'vue';
const { country = '' } = defineProps<{
  country?: string;
}>();

// For app size we only want to have flags for countries that exist in db
// you get get more flags from here https://github.com/lipis/flag-icons/tree/main/flags/1x1
const availableFlags = {
  'republic of panama': 'pa',
  bahamas: 'bs',
  liberia: 'lr',
  'marshall islands': 'mh',
  norway: 'no',
  'isle of man': 'im',
};

const getCode = (name) => {
  const code = availableFlags[name?.toLowerCase()];
  return code ? `/flags/${code}.svg` : null;
};

const flagSrc = computed(() => getCode(country));
</script>

<template>
  <div class="flex items-center space-x-2">
    <img
      v-if="flagSrc"
      class="h-4 w-4 rounded-full"
      :src="flagSrc"
      :alt="`flag of ${country}`"
    />
    <span>{{ country }}</span>
  </div>
</template>
