<script setup lang="ts" name="BrandPattern">
import { onMounted, ref } from 'vue';

import Icon from '~/components/Icon.vue';

import vResize from '~/directives/resize';

const props = defineProps({
  small: {
    type: Boolean,
    default: false,
  },
});
const isSmall = ref(props.small);

const onResize = () => {
  if (props.small) {
    isSmall.value = props.small;
    return;
  }
  isSmall.value = window.innerWidth < 960;
};

onMounted(() => onResize());
</script>

<template>
  <div v-resize="onResize" class="relative ml-auto flex items-stretch">
    <Icon
      icon="g2o:flag-l"
      size="custom"
      class="absolute top-0 text-[40px]"
      :class="isSmall ? 'right-[180px]' : 'right-[320px]'"
    />
    <Icon
      icon="g2o:flag-h"
      size="custom"
      class="absolute top-08 text-[40px]"
      :class="isSmall ? 'right-[140px]' : 'right-[200px]'"
    />
    <Icon
      icon="g2o:flag-o"
      size="custom"
      class="absolute top-0 text-[40px]"
      :class="isSmall ? 'right-20' : 'right-[120px]'"
    />
    <Icon
      icon="g2o:flag-l"
      size="custom"
      class="absolute top-08 text-[40px]"
      :class="isSmall ? 'right-08' : 'right-20'"
    />
    <Icon
      icon="g2o:flag-e"
      size="custom"
      class="absolute right-0 top-0 text-[40px]"
    />
  </div>
</template>
