<script lang="ts" setup name="DatePickerField">
import { useVModel } from '@vueuse/core';

import Icon from '~/components/Icon.vue';

const props = withDefaults(
  defineProps<{
    dataTest?: string;
    value: string | null;
    modelValue: Date | string | null;
    name: string;
    label?: string;
    type?: string;
    readonly?: boolean;
    hasErrors?: boolean;
    optional?: boolean;
    filled?: boolean;
  }>(),
  {
    dataTest: 'date-picker-field',
    name: '',
    type: 'text',
  },
);

const emit = defineEmits<{
  'update:modelValue': [value: string];
  onBlur: [value: any];
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const handleBlur = (event: FocusEvent) => {
  const input = event.target as HTMLInputElement;

  emit('onBlur', input.value);
};
</script>

<template>
  <label
    :data-test="dataTest"
    class="flex h-input items-center rounded-t border-b py-1 transition-colors"
    :class="{
      'border-red-6 text-red-6 focus-within:text-red-6': hasErrors,
      'border-charcoal-6 text-charcoal-6 focus-within:border-primary-8 focus-within:text-primary-8':
        !hasErrors,
      'cursor-pointer': readonly,
      'cursor-text': !readonly,
      'bg-white': !filled,
      'bg-charcoal-1 px-4': filled,
    }"
  >
    <div class="relative flex h-full w-full flex-1 flex-col-reverse">
      <input
        v-bind="$attrs"
        :value="value"
        :id="name"
        v-model="inputValue"
        :placeholder="label"
        :readonly="readonly"
        class="input-field h-full w-full pb-1 text-black placeholder-transparent outline-none"
        :class="{
          'cursor-pointer': readonly,
          'cursor-text': !readonly,
        }"
        :name="name"
        :type="type"
        :data-test="`${dataTest}-input`"
        @blur="handleBlur"
      />
      <span
        class="input-label block origin-bottom-left cursor-text"
        :for="name"
      >
        {{ label }}
      </span>
      <p
        v-if="optional && !value"
        class="absolute right-0 cursor-text pb-1 text-charcoal-2"
      >
        Optional
      </p>
    </div>
    <div class="mx-2 flex-shrink-0">
      <Icon icon="mdi:calendar-blank-outline" />
    </div>
  </label>
</template>
