<script lang="ts" setup name="RouterTabsDropdownLink">
import type { LocationAsRelativeRaw } from 'vue-router';

import Icon from '~/components/Icon.vue';

const { label, to } = defineProps<{
  label: string;
  to: LocationAsRelativeRaw;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const onNavigate = (navigate: () => void) => {
  emit('click');
  navigate();
};
</script>

<template>
  <RouterLink
    :key="label"
    :to="to"
    custom
    v-slot="{ href, navigate, isActive }"
  >
    <a
      :href="href"
      @click="onNavigate(navigate)"
      class="flex w-full items-center justify-between px-4 py-2 capitalize"
      :class="{
        'hover:bg-green-0': isActive,
        'hover:bg-charcoal-0': !isActive,
      }"
    >
      {{ label }}
      <Icon
        v-if="isActive"
        icon="ic:baseline-check"
        class="h-5 w-5 text-primary-8"
      />
    </a>
  </RouterLink>
</template>
