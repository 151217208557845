import { onMounted, onUnmounted, ref, watch } from 'vue';
import { breakpointsTailwind } from '@vueuse/core';

export const useOnResize = (cb?: () => void) => {
  const isOnMobile = ref(window.innerWidth <= breakpointsTailwind.sm);

  const updateIsOnMobile = () => {
    isOnMobile.value = window.innerWidth <= breakpointsTailwind.sm;
  };

  onMounted(() => {
    window.addEventListener('resize', updateIsOnMobile);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateIsOnMobile);
  });

  watch(isOnMobile, () => {
    if (cb) {
      cb();
    }
  });

  return {
    isOnMobile,
  };
};
