<script lang="ts" setup name="BaseCardServerError">
import BaseButton from '~/components/BaseButton.vue';
import BaseCardState from '~/components/BaseCardState.vue';

import type {
  BaseCardStateIllustrationSizeType,
  BaseCardStateSpacingType,
  BaseCardStateTextSizeType,
  IllustrationType,
} from '~/types';

const {
  title = 'Sorry. It’s Not You, It’s Us...',
  message = 'Try to refresh the page.',
  illustration = 'server_error',
  spacing = 'card-lg',
  illustrationSize,
  styled,
} = defineProps<{
  title?: string;
  message?: string;
  styled?: boolean;
  textSize?: BaseCardStateTextSizeType;
  illustration?: IllustrationType;
  spacing?: BaseCardStateSpacingType;
  illustrationSize?: BaseCardStateIllustrationSizeType;
}>();

const emit = defineEmits<{
  (e: 'retry'): void;
}>();
</script>

<template>
  <BaseCardState
    :illustration="illustration"
    :title="title"
    :message="message"
    :textSize="textSize"
    :styled="styled"
    :spacing="spacing"
    :illustrationSize="illustrationSize"
  >
    <template #actions>
      <slot>
        <BaseButton @click="emit('retry')">Refresh</BaseButton>
      </slot>
    </template>
  </BaseCardState>
</template>
