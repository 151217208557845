import { type RemovableRef, useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

import { LOCAL_STORAGE_KEYS, MAINTENANCE_AREA, PINIA_STORE } from '~/constants';

import type { IMaintenanceNotification } from '~/types';

type StoredItem<T> = RemovableRef<T | null>;

interface IStore {
  messages: StoredItem<IMaintenanceNotification[]>;
}

export const useMaintenanceStore = defineStore(PINIA_STORE.MAINTENANCE_STORE, {
  state: (): IStore => ({
    messages: useLocalStorage<IMaintenanceNotification[]>(
      LOCAL_STORAGE_KEYS.MAINTENANCE,
      [],
    ),
  }),
  getters: {
    inPublicMaintenance: (state) =>
      state.messages?.some(
        (x) => x.area === MAINTENANCE_AREA.LOGIN && x.disableAuth,
      ),
  },
  actions: {
    setMessages(messages: IMaintenanceNotification[]) {
      this.messages = messages;
    },
  },
});
