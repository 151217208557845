import { nextTick, type Ref } from 'vue';

import { ASCII_A } from '~/constants';

export interface IAddressManager {
  selectedCellAddress: Ref<string>;
  createAddress: (colNumber: number, rowNumber: number) => string;
  parseAddress: (address: string) => { colNumber: number; rowNumber: number };
  selectAddress: (address: string) => void;
  incrementLetter: (letter: string) => string;
  getLetterFromAddress: (address: string) => string;
  createCustomAddress: (address: string) => string;
  focusCell: (address: string) => void;
  focusAndScrollToCell: (address: string) => void;
  resetSelectedCell: () => void;
  getRowAndColNumbersFromAddress: () => {
    colNumber: number;
    rowNumber: number;
  };
}

export const useDataTableSelectedCell = ({
  getTableId,
  selectedCellAddress,
}): IAddressManager => {
  const toZeroBased = (number: number) => number - 1;
  const toOneBased = (number: number) => number + 1;

  const resetSelectedCell = () => {
    selectedCellAddress.value = '';
  };

  const createAddress = (colNumber: number, rowNumber: number) => {
    return `${String.fromCharCode(colNumber + ASCII_A)}${toOneBased(
      rowNumber,
    )}-${getTableId()}`;
  };

  const getLetterFromAddress = (address: string) => {
    return String.fromCharCode(address.charCodeAt(0));
  };

  const incrementLetter = (letter: string) => {
    return String.fromCharCode(letter.charCodeAt(0) + 1);
  };

  const createCustomAddress = (address: string) => {
    return `${address}-${getTableId()}`;
  };

  const parseAddress = (address: string) => {
    const parts = address.split('-');
    const tableSpecificAddress = parts[0];

    const rowNumber = toZeroBased(parseInt(tableSpecificAddress.slice(1)));
    const colLetter = tableSpecificAddress.charAt(0);
    const colNumber = colLetter.charCodeAt(0) - ASCII_A;

    return { rowNumber, colNumber };
  };

  const getRowAndColNumbersFromAddress = () => {
    return parseAddress(selectedCellAddress.value);
  };

  const selectAddress = (address: string) => {
    selectedCellAddress.value = address;
  };

  const focusAndScrollToCell = (cellAddress: string) => {
    const cellElement = document.getElementById(cellAddress);
    const containerElement = document.getElementById('overflow-container');

    if (cellElement && containerElement) {
      cellElement.focus();

      const newScrollPosition = cellElement.offsetLeft;

      nextTick(() => {
        containerElement.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth',
        });
      });
    }
  };

  const focusCell = (cellAddress: string) => {
    const cellElement = document.getElementById(cellAddress);

    if (cellElement) {
      cellElement.focus();
      cellElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  };

  return {
    selectedCellAddress,
    focusCell,
    focusAndScrollToCell,
    parseAddress,
    createAddress,
    selectAddress,
    getRowAndColNumbersFromAddress,
    createCustomAddress,
    getLetterFromAddress,
    incrementLetter,
    resetSelectedCell,
  };
};
