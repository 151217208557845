<script setup lang="ts" name="FormWizard">
import { computed, ref, useSlots, watch } from 'vue';
import { useForm } from 'vee-validate';

import FormWizardHorizontal from '~/components/FormWizardHorizontal.vue';
import FormWizardVertical from '~/components/FormWizardVertical.vue';
import FormWizardVerticalMultiStep from '~/components/FormWizardVerticalMultiStep.vue';

import type { IStepperTitle, StepperPositionType } from '~/types';

const {
  errors,
  titles,
  loading = false,
  submitError = false,
  submit = 'Submit',
  position = 'horizontal',
} = defineProps<{
  position?: StepperPositionType;
  loading?: boolean;
  submitError?: boolean;
  errors?: Record<string, string>;
  submit?: string;
  titles?: IStepperTitle[];
}>();

const emit = defineEmits<{
  (e: 'submit'): void;
}>();

const { validate, setErrors } = useForm();

const slots = useSlots();

const activeStepIdx = ref(0);

const content = computed(() => slots.default?.());

const totalSteps = computed(() => content.value?.length ?? 0);

const isLastStep = computed(() => activeStepIdx.value === totalSteps.value - 1);

const isFirstStep = computed(() => activeStepIdx.value === 0);

const isMultiStep = computed(() => totalSteps.value > 2);

const setCurrentTab = (index: number) => {
  activeStepIdx.value = index;
};

const getPreviousStep = () => {
  let previousStepIdx = activeStepIdx.value - 1;

  while (previousStepIdx >= 0) {
    if (!titles?.[previousStepIdx].disabled) {
      break;
    }

    previousStepIdx--;
  }

  return previousStepIdx >= 0 ? previousStepIdx : activeStepIdx.value;
};

const getNextStep = () => {
  let nextStepIdx = activeStepIdx.value + 1;

  while (nextStepIdx < totalSteps.value) {
    if (!titles?.[nextStepIdx].disabled) {
      break;
    }

    nextStepIdx++;
  }

  return nextStepIdx;
};

const decrementStep = () => {
  if (!isFirstStep.value) {
    activeStepIdx.value = getPreviousStep();
  }
};

const incrementStep = async () => {
  if (loading) return;

  const { valid, errors } = await validate();
  if (!valid) {
    setErrors(errors);
    return;
  }

  if (!isLastStep.value && (position === 'horizontal' || isMultiStep.value)) {
    activeStepIdx.value = getNextStep();
    return;
  }

  emit('submit');
};

watch(
  () => errors,
  (allErrors) => {
    if (!allErrors) {
      setErrors({});
      return;
    }

    setErrors(allErrors);
  },
  {
    immediate: true,
    deep: true,
  },
);

const stepperConfig = computed(() => ({
  totalSteps: totalSteps.value,
  activeStepIdx: activeStepIdx.value,
}));
</script>

<template>
  <FormWizardHorizontal
    v-if="position === 'horizontal'"
    :loading="loading"
    :submit="submit"
    :titles="titles"
    :totalSteps="totalSteps"
    :submit-error="submitError"
    :activeStepIdx="activeStepIdx"
    :onNextStep="incrementStep"
    :setCurrentTab="setCurrentTab"
    :onPreviousStep="decrementStep"
  >
    <div v-for="(step, index) in content" :key="index">
      <div v-show="index === activeStepIdx" :data-testid="`step-${index}`">
        <component
          v-if="index === activeStepIdx"
          :is="step"
          v-bind="{ stepperConfig }"
        />
      </div>
    </div>
  </FormWizardHorizontal>

  <div v-else>
    <FormWizardVerticalMultiStep
      v-if="isMultiStep"
      :titles="titles"
      :content="content"
      :totalSteps="totalSteps"
      :activeStepIdx="activeStepIdx"
      :onNextStep="incrementStep"
      :onPreviousStep="decrementStep"
      :setCurrentTab="setCurrentTab"
    />

    <FormWizardVertical
      v-else
      :titles="titles"
      :content="content"
      :onSubmit="incrementStep"
    >
      <template #footer>
        <slot name="footer" />
      </template>
    </FormWizardVertical>
  </div>
</template>
