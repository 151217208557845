<script setup lang="ts" name="StepperHeaderLayout">
import { computed } from 'vue';

import type { StepperPositionType } from '~/types';

const {
  totalSteps,
  hasTitles,
  position = 'horizontal',
} = defineProps<{
  position?: StepperPositionType;
  totalSteps?: number;
  hasTitles?: boolean;
}>();

const positionIs = computed(
  () => (type: StepperPositionType) => type === position,
);

const horizontalWidth = computed(() => {
  if (!totalSteps || positionIs.value('vertical') || hasTitles) {
    return undefined;
  }

  const stepWidth = 70;
  const stepperWidth = totalSteps * stepWidth;

  return `${stepperWidth}px`;
});
</script>

<template>
  <div
    class="rounded bg-white p-4 shadow"
    :class="{
      'flex-center mb-2': positionIs('horizontal'),
      'mr-2 flex items-center': positionIs('vertical'),
    }"
  >
    <div
      :class="{
        'h-[50px] w-[90%] md:w-8/12': positionIs('horizontal'),
        'h-[90%] w-full min-w-16 md:h-4/6': positionIs('vertical'),
      }"
      :style="{ width: horizontalWidth }"
    >
      <slot />
    </div>
  </div>
</template>
