<script setup name="ListCheckbox">
import { useVModel } from '@vueuse/core';

import BaseCheckbox from '~/components/BaseCheckbox.vue';

const props = defineProps({
  modelValue: {
    type: [Number, String, Object, Array, Date, Boolean],
    default: null,
  },
  item: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue', 'click']);
const items = useVModel(props, 'modelValue', emit);
</script>

<template>
  <BaseCheckbox
    v-model="items"
    v-tooltip:checkbox-control.bottom="tooltip"
    class="px-6 py-5 hover:bg-charcoal-1"
    :nativeValue="item"
    :disabled="disabled"
  >
    <slot />
  </BaseCheckbox>
</template>
