import { ORGANISATION_TYPE } from '~/constants';

import type { IFormField, IOrganisationNew } from '~/types';

export const getOrganisationNewForm = (): IOrganisationNew => ({
  name: '',
  type: ORGANISATION_TYPE.CHARTERER,
  companies: [],
  settings: [
    'feature.sailing_schedule.view',
    'feature.bookings.view',
    'feature.statistics.shipment_volumes.view',
    'feature.statistics.laycan.view',
    'feature.statistics.transit_times.view',
    'feature.statistics.invoices.view',
    'feature.statistics.bunkers.view',
    'feature.statistics.emissions.view',
    'feature.statistics.productivity.view',
    'feature.statistics.claims.view',
    'feature.vessels.view',
    'feature.sailing_schedule.sync',
    'feature.carbon_offsetting_widget.view',
    'feature.statistics.waiting_for_berth.view',
    'feature.statistics.laytime.view',
    'feature.kpis.view',
  ],
  users: [],
  customerUsers: [],
});

export const getOrganisationDefaultSchema = (): Record<string, IFormField> => ({
  organisationName: {
    name: 'organisation-name',
    label: 'Organisation Name',
    rules: 'required|min:3|max:128|unique_name',
    component: 'FieldText',
  },
  organisationType: {
    name: 'organisation-type',
    label: '',
    rules: 'required',
    options: [
      {
        id: ORGANISATION_TYPE.CHARTERER,
        label: 'Charterer',
      },
      {
        id: ORGANISATION_TYPE.AGENCY,
        label: 'Broker',
      },
    ],
    component: 'FieldRadio',
  },
  companies: {
    name: 'organisations',
    label: 'Organisations',
    component: 'CompaniesList',
    rules: 'required',
  },
});

export const getOrganisationEditForm = (name: string): IOrganisationNew => ({
  name,
  type: '',
  companies: [],
  settings: [],
  users: [],
  customerUsers: [],
});

export const getOrganisationFormData = ({
  name,
  companies,
  users,
  customerUsers,
  settings,
  type,
}: IOrganisationNew) => ({
  name,
  type,
  settings: settings.sort(),
  companies: companies.map((x) => x.id),
  internal_users: users
    .filter((user) => !user.role.is_admin)
    .map((user) => user.id)
    .sort(),
  customer_users: customerUsers
    .map((x) => ({
      email: x.email,
      role: x.role.id,
    }))
    .sort((a, b) => a.email.localeCompare(b.email)),
});
