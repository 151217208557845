<script setup lang="ts" name="FieldRadio">
import { useVModel } from '@vueuse/core';

import BaseRadio from '~/components/BaseRadio.vue';
import BaseRadioGroup from '~/components/BaseRadioGroup.vue';
import FieldBaseErrors from '~/components/FieldBaseErrors.vue';

import type { IFieldRadio, IFieldSelectOption, IFormField } from '~/types';

const props = withDefaults(
  defineProps<{
    field: IFormField<IFieldRadio>;
    modelValue: string;
    errors?: string[];
  }>(),
  {
    errors: () => [],
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

defineOptions({
  inheritAttrs: false,
});

defineSlots<{
  title?: (props: Record<string, unknown>) => any;
  default?: (props: Record<string, unknown>) => any;
  slot?: (props: { option: IFieldSelectOption }) => any;
}>();

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <div>
    <slot name="title">
      <p
        v-if="field.label"
        class="mb-4 text-body-1"
        :class="{ 'text-charcoal-4': field.disabled }"
      >
        {{ field.label }}
      </p>
    </slot>

    <BaseRadioGroup>
      <BaseRadio
        v-for="option in field.options"
        v-tooltip.start="option.tooltip"
        v-model="inputValue"
        :key="option.id"
        :nativeValue="option.id!"
        :label="option.label"
        :name="field.name"
        :disabled="field.disabled || option.disabled"
      >
        <slot v-bind="option" />
      </BaseRadio>
    </BaseRadioGroup>

    <FieldBaseErrors
      v-if="errors.length > 0"
      class="mt-2"
      :errors="errors"
      :data-test="`${field.name}-error`"
    />

    <p
      v-else-if="field.helpText"
      class="mt-2 text-overline"
      :class="field.disabled ? 'text-charcoal-4' : 'text-charcoal-6'"
    >
      {{ field.helpText }}
    </p>
  </div>
</template>
