<script setup lang="ts" name="FilterMenuTrigger">
import { computed } from 'vue';

import Icon from '~/components/Icon.vue';

import type { IconType } from '~/types';

const {
  icon,
  isOpen = false,
  disabled = false,
  hasSelected = false,
} = defineProps<{
  disabled?: boolean;
  hasSelected?: boolean;
  isOpen?: boolean;
  icon?: IconType;
}>();

const isActive = computed(() => hasSelected || isOpen);
</script>

<template>
  <button
    type="button"
    class="flex h-08 min-w-[98px] items-center rounded-lg px-3 py-2 text-button-1 uppercase tracking-widest shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-ocean-3 active:outline-none"
    :class="{
      'bg-primary-8 text-white': isOpen,
      'bg-ocean-6 text-white': hasSelected && !isOpen,
      'bg-white': !isActive,
      'text-primary-8 hover:bg-ocean-6 hover:text-white hover:shadow-md':
        !isActive && !disabled,
      'cursor-not-allowed text-charcoal-4': disabled,
      'cursor-pointer': !disabled,
    }"
    :disabled="disabled"
  >
    <Icon v-if="icon" :icon="icon" class="mr-2" />
    <slot />
    <Icon
      icon="mdi:chevron-right"
      class="ml-3 transform"
      :class="{
        '-rotate-90': isOpen,
        'rotate-90': !isOpen,
      }"
    />
  </button>
</template>
