<script lang="ts" setup name="BaseRadio" generic="T">
//TODO: fix disabled Booleanish value error

import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

const props = defineProps<{
  modelValue?: T;
  nativeValue: T;
  name: string;
  dataTest?: string;
  disabled?: boolean;
  label?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: T): void;
  (e: 'change'): void;
  (e: 'blur'): void;
}>();

defineSlots<{
  default?: (props: Record<string, unknown>) => any;
}>();

const inputDataTest = computed(() => props.dataTest || 'radio-input');

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <label
    class="group flex items-center gap-2"
    :data-test="inputDataTest"
    :class="{
      'text-charcoal-4': disabled,
      'cursor-pointer': !disabled,
    }"
  >
    <input
      v-model="inputValue"
      type="radio"
      class="min-h-4 min-w-4 outline-none focus-visible:ring-1 focus-visible:ring-ocean-4"
      :class="{ 'cursor-pointer': !disabled }"
      :name="name"
      :value="nativeValue"
      :disabled="disabled"
      :data-testid="`${inputDataTest}-field`"
      @change="emit('change')"
      @blur="emit('blur')"
    />
    <div
      class="text-body-1"
      :class="{
        'text-charcoal-4': disabled,
        'text-charcoal-9 group-hover:text-ocean-6': !disabled,
      }"
    >
      <slot>
        <p>
          {{ label }}
        </p>
      </slot>
    </div>
  </label>
</template>

<style scoped>
input[type='radio'] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid theme('colors.charcoal.4');
  border-radius: 50%;
}

input[type='radio']:not(:disabled):hover {
  border-color: theme('colors.ocean.6');
}

input[type='radio']:not(:disabled):checked:hover {
  background-color: theme('colors.ocean.6');
  border-color: theme('colors.ocean.6');
}

input[type='radio']:not(:disabled):checked {
  border-color: theme('colors.ocean.8');
  background-color: theme('colors.ocean.8');
}

input[type='radio']:disabled:checked {
  border-color: theme('colors.charcoal.4');
  background-color: theme('colors.charcoal.4');
}
</style>
