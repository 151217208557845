<script setup lang="ts" name="OrganisationGeneralInfo">
import { useVModel } from '@vueuse/core';

import Field from '~/components/Field.vue';
import InfoTooltip from '~/components/InfoTooltip.vue';

import type { IFormField, IOrganisationNew } from '~/types';

const props = defineProps<{
  schema: Record<string, IFormField>;
  form: IOrganisationNew;
}>();

const emit = defineEmits<{
  'update:modelValue': [];
}>();

const inputValue = useVModel(props, 'form', emit);
</script>

<template>
  <div class="max-w-form space-y-8">
    <div>
      <p>Enter organisation name:</p>
      <Field
        class="mt-2"
        v-model="inputValue.name"
        :field="schema.organisationName"
      />
    </div>

    <div class="space-y-4">
      <div class="flex items-center gap-x-1">
        <p>Select organisation type:</p>

        <InfoTooltip
          description="This selection determines whether the MyG2 view of cargoes will be from a Charterer or a Broker perspective. The Broker perspective will display all cargoes that have the selected broker assigned to the cargo, irrespective of who the charterer is."
          placement="bottom"
        />
      </div>

      <Field v-model="inputValue.type" :field="schema.organisationType" />
    </div>
  </div>
</template>
