<script setup lang="ts" name="StepperHeaderTitle">
import { computed } from 'vue';

import type { StepperPositionType } from '~/types';

const { position = 'horizontal' } = defineProps<{
  title: string;
  isActive: boolean;
  isCompleted: boolean;
  position?: StepperPositionType;
  disabled?: boolean;
}>();

const positionIs = computed(
  () => (type: StepperPositionType) => type === position,
);
</script>

<template>
  <div
    class="absolute text-button-2 uppercase"
    :class="{
      'text-charcoal-5': disabled,
      'text-primary-8': isActive,
      'text-primary-6': isCompleted && !disabled,
      'text-charcoal-6': !isCompleted && !disabled,
      'left-1/2 top-0 mt-8 w-32 -translate-x-1/2 text-center md:w-64':
        positionIs('horizontal'),
      'left-0 ml-8': positionIs('vertical'),
    }"
  >
    {{ title }}
  </div>
</template>
