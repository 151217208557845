import type { RemovableRef } from '@vueuse/core';
import { useLocalStorage } from '@vueuse/core';
import { isEmpty } from 'lodash-es';
import { defineStore } from 'pinia';

import { LOCAL_STORAGE_KEYS, PINIA_STORE } from '~/constants';

import { checkOrganisationHasTourFeatures } from '~/features/useFeatures';
import { getDefaultOrg } from '~/features/useProfileHelpers';
import { useNullableObjectSerializer } from '~/features/useUtilities';
import type { OrgUpdatedSettings } from '~/features/useWebsocketChannels';

import type { IOrganisation, IOrgFeature } from '~/types';

import { useDevFeaturesStore } from './useDevFeaturesStore';

interface IProfileStore {
  organisations: RemovableRef<IOrganisation[]>;
  organisation: RemovableRef<IOrganisation | null>;
}

export const useOrganisationsStore = defineStore(
  PINIA_STORE.ORGANISATION_STORE,
  {
    state: (): IProfileStore => ({
      organisations: useLocalStorage(LOCAL_STORAGE_KEYS.ORGANISATIONS, []),
      organisation: useLocalStorage(LOCAL_STORAGE_KEYS.ORGANISATION, null, {
        serializer: useNullableObjectSerializer<IOrganisation>(),
      }),
    }),
    getters: {
      featureEnabledForOrganisation: (state) => (feature: IOrgFeature) =>
        state.organisation?.features.includes(feature) ?? false,
      canDeclareCargo(state): boolean {
        return state.organisation?.canDeclareCargo ?? false;
      },
      canNominate(state): boolean {
        return state.organisation?.canNominate ?? false;
      },
      canReceiveShipmentsNotifications(): boolean | null {
        const devFeatures = useDevFeaturesStore();

        if (!this.organisation) {
          return null;
        }

        return devFeatures.SHIPMENTS_NOTIFICATIONS && this.organisation?.notify;
      },
      defaultOrganisationId(state): OrganisationId | null {
        const defaultOrganisation = getDefaultOrg(
          state.organisations,
          state.organisation,
        );

        return defaultOrganisation?.id || null;
      },
      getById(state): (id: OrganisationId | null) => IOrganisation | null {
        return (id: OrganisationId | null) => {
          if (!state.organisations) {
            return null;
          }

          return state.organisations.find((x) => x.id === id) || null;
        };
      },
      tourOrganisation(state): IOrganisation | null {
        if (!state.organisations) {
          return null;
        }

        return (
          state.organisations.find(checkOrganisationHasTourFeatures) ||
          state.organisations[0]
        );
      },
    },
    actions: {
      _updateOrganisation(
        organisation: IOrganisation | null,
      ): IOrganisation | null {
        if (isEmpty(this.organisations) || !organisation) {
          this.organisation = null;
          return null;
        }

        this.organisations = this.organisations.map((x) =>
          x.id === organisation.id ? organisation : x,
        );

        this.selectOrganisation(organisation);
        return organisation;
      },
      selectOrganisation(organisation: IOrganisation) {
        this.organisation = organisation;
      },
      setById(id: OrganisationId) {
        const organisation = this.getById(id);
        if (!organisation) {
          return;
        }

        this.selectOrganisation(organisation);
      },
      updateOrganisations(organisations: IOrganisation[], update = false) {
        const orgId = this.organisation?.id || null;
        const updatedCurrentOrg = organisations.find((x) => x.id === orgId);
        const shouldReload = update && orgId !== null && !updatedCurrentOrg;

        this.organisations = organisations;
        const organisation = getDefaultOrg(organisations, this.organisation);

        if (organisation && orgId !== null) {
          this._updateOrganisation(organisation);
        }

        if (shouldReload) {
          location.reload();
          return;
        }
      },
      updateOrganisationShipmentsNotifications(notify: boolean) {
        if (!this.organisation) {
          return;
        }

        this._updateOrganisation({ ...this.organisation, notify: notify });
      },
      updateOrganisationFeatures(features: IOrgFeature[]) {
        if (!this.organisation) {
          return;
        }
        this._updateOrganisation({ ...this.organisation, features });
      },
      updateOrganisationSettings(data: OrgUpdatedSettings) {
        const { organisationId, ...params } = data;

        const organisations = this.organisations.map((org) => {
          if (org.id !== organisationId) {
            return org;
          }
          for (const p in params) {
            org[p] = params[p];
          }
          return org;
        });

        this.updateOrganisations(organisations);
      },
      updateWidgetCount(countWidgets: number) {
        if (!this.organisation) {
          return;
        }

        this._updateOrganisation({ ...this.organisation, countWidgets });
      },
      unsetCurrentOrganisation() {
        this._updateOrganisation(null);
      },
    },
  },
);
