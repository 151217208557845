import type { Pinia, Store } from 'pinia';
import { getActivePinia } from 'pinia';

import { PINIA_STORE } from '~/constants';

import type { IResettablePiniaStore } from '~/types';

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}

export const useResetStore = (): Record<IResettablePiniaStore, () => void> => {
  const pinia = getActivePinia() as ExtendedPinia;

  if (!pinia) {
    throw new Error('There is no active Pinia instance');
  }

  const resetStores: Record<IResettablePiniaStore, () => void> = {
    [PINIA_STORE.ANALYTICS_STORE]: () => {},
    [PINIA_STORE.DEV_STORE]: () => {},
    [PINIA_STORE.HOME_STORE]: () => {},
    [PINIA_STORE.KPI_STORE]: () => {},
    [PINIA_STORE.MAINTENANCE_STORE]: () => {},
    [PINIA_STORE.ORGANISATION_STORE]: () => {},
    [PINIA_STORE.PROFILE_STORE]: () => {},
    [PINIA_STORE.STATISTICS_STORE]: () => {},
    [PINIA_STORE.UNREAD_NOTIFICATIONS_STORE]: () => {},
  };

  pinia._s.forEach((activeStore, name) => {
    if (name === PINIA_STORE.AUTH_STORE) {
      return;
    }

    if (!resetStores[name]) {
      return;
    }

    resetStores[name] = () => activeStore.$reset();
  });

  return resetStores;
};
