import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import { PINIA_STORE, STATES } from '~/constants';

import { getStatisticsWidgets } from '~/features/homeWidgets';

import Service from '~/services/Service';

import type {
  IHomeResponse,
  IHomeWidget,
  ILatestNews,
  IShipmentCount,
  IShipmentResponse,
  IState,
  IVoyage,
  OrganisationHomeWidgetsResponse,
} from '~/types';

export const useHomeStore = defineStore(PINIA_STORE.HOME_STORE, {
  state: () => {
    const shipmentsState = ref<IState>(STATES.LOADING);
    const voyages = ref<IVoyage[]>([]);

    const setShipmentState = (state: IState) => {
      shipmentsState.value = state;
    };

    const shipmentStateIs = (state: IState) =>
      stateIs(shipmentsState.value, state);

    const fetchShipments = (organisationId: OrganisationId) => {
      Service.organisation(organisationId)
        .shipment()
        .indexV2()
        .data({
          page: 1,
          per_page: 5,
        })
        .onStart(() => setShipmentState(STATES.LOADING))
        .onError(() => setShipmentState(STATES.ERROR))
        .onNoData(() => setShipmentState(STATES.NO_DATA))
        .onSuccess(({ data }: IShipmentResponse) => {
          voyages.value = data;
          setShipmentState(STATES.IDLE);
        })
        .execute();
    };

    const statisticsState = ref<IState>(STATES.LOADING);
    const widgetsData = ref<IHomeWidget[]>([]);

    const setStatisticsState = (state: IState) => {
      statisticsState.value = state;
    };

    const statisticsStateIs = (state: IState) =>
      stateIs(statisticsState.value, state);

    const fetchStatistics = (
      organisationId: OrganisationId,
      onSuccessCb?: () => void,
    ) => {
      Service.organisation(organisationId)
        .home()
        .widgets()
        .onStart(() => setStatisticsState(STATES.LOADING))
        .onError(() => setStatisticsState(STATES.ERROR))
        .onNoData(() => setStatisticsState(STATES.NO_DATA))
        .onSuccess(({ data }: { data: OrganisationHomeWidgetsResponse }) => {
          widgetsData.value = getStatisticsWidgets(data);
          setStatisticsState(STATES.IDLE);

          if (onSuccessCb) {
            onSuccessCb();
          }
        })
        .execute();
    };

    const latestNews = ref<ILatestNews>();
    const shipmentsCount = ref<IShipmentCount>();
    const emissions = ref({});
    const asideState = ref<IState>(STATES.LOADING);

    const setAsideState = (state: IState) => {
      asideState.value = state;
    };

    const asideStateIs = (state: IState) => stateIs(asideState.value, state);

    const fetchAside = (organisationId: OrganisationId) => {
      Service.organisation(organisationId)
        .home()
        .show()
        .onStart(() => setAsideState(STATES.LOADING))
        .onError(() => setAsideState(STATES.ERROR))
        .onSuccess((response: IHomeResponse) => {
          const {
            latestNews: news,
            shipmentsCount: count,
            emissions: carbonWidget,
          } = response.data;

          latestNews.value = news;
          shipmentsCount.value = count;
          emissions.value = carbonWidget;

          setAsideState(STATES.IDLE);
        })
        .execute();
    };

    const isPageError = computed(
      () =>
        shipmentStateIs(STATES.ERROR) &&
        statisticsStateIs(STATES.ERROR) &&
        asideStateIs(STATES.ERROR),
    );

    const refreshPage = (organisationId: OrganisationId) => {
      fetchShipments(organisationId);
      fetchStatistics(organisationId);
      fetchAside(organisationId);
    };

    const isStatisticsAndShipmentsError = computed(
      () => shipmentStateIs(STATES.ERROR) && statisticsStateIs(STATES.ERROR),
    );

    const refreshStatisticsAndShipments = (organisationId: OrganisationId) => {
      fetchShipments(organisationId);
      fetchStatistics(organisationId);
    };

    const stateIs = (stateFor: IState, state: IState) => stateFor === state;

    return {
      isPageError,
      isStatisticsAndShipmentsError,
      refreshPage,
      refreshStatisticsAndShipments,

      shipmentsState,
      voyages,
      fetchShipments,
      shipmentStateIs,

      statisticsState,
      widgetsData,
      fetchStatistics,
      statisticsStateIs,

      latestNews,
      shipmentsCount,
      emissions,
      asideStateIs,
      fetchAside,
    };
  },
});
