<script lang="ts" setup name="OrganisationSettings">
import { computed, reactive, ref, watch } from 'vue';

import { DATA_CHOICE, ORG_FEATURES } from '~/constants';
import { useDevFeaturesStore } from '~/store';

import { hasOneOfPermissions } from '~/features/usePermissions';
import { addIf } from '~/features/useUtilities';

import OrganisationSetting from '~/components/OrganisationSetting.vue';
import OrganisationSettingCollapsible from '~/components/OrganisationSettingCollapsible.vue';

import type { ActiveSetting, Setting, StatisticsSetting } from '~/types';

const { settings } = defineProps<{
  settings: ActiveSetting[];
}>();

const emit = defineEmits<{
  (e: 'updateSettings', value: Setting[]): void;
  (e: 'updateSetting', value: Setting): void;
  (e: 'updateStatistics', value: StatisticsSetting): void;
}>();

const devFeatures = useDevFeaturesStore();

const featureIsEnabled = (feature: ActiveSetting) => settings.includes(feature);

const updateSetting = (setting: Setting) => {
  emit('updateSetting', setting);
};

const globalSettings = reactive<Setting[]>([
  {
    id: ORG_FEATURES.SAILING_SCHEDULE,
    dataTest: 'sailing-schedule-setting',
    name: 'Sailing Schedule',
    enabled: featureIsEnabled(ORG_FEATURES.SAILING_SCHEDULE),
  },
  {
    id: ORG_FEATURES.BOOKINGS,
    dataTest: 'bookings-setting',
    name: 'Bookings',
    enabled: featureIsEnabled(ORG_FEATURES.BOOKINGS),
  },
  {
    id: ORG_FEATURES.KPIS,
    dataTest: 'kpi-setting',
    name: 'KPI',
    enabled: featureIsEnabled(ORG_FEATURES.KPIS),
  },
]);

const afterStatistics = reactive<Setting[]>([
  {
    id: ORG_FEATURES.VESSELS,
    name: 'Vessels',
    dataTest: 'vessels-setting',
    enabled: featureIsEnabled(ORG_FEATURES.VESSELS),
  },
  {
    id: ORG_FEATURES.CARBON_OFFSETTING_WIDGET,
    name: 'Carbon Offsetting',
    dataTest: 'carbon-offsetting-setting',
    enabled: featureIsEnabled(ORG_FEATURES.CARBON_OFFSETTING_WIDGET),
  },
]);

const statisticsSettings = reactive<Setting[]>([
  {
    id: ORG_FEATURES.STATISTICS_LAYCAN,
    name: 'Laycan',
    dataTest: 'laycan-setting',
    enabled: featureIsEnabled(ORG_FEATURES.STATISTICS_LAYCAN),
  },
  {
    id: ORG_FEATURES.STATISTICS_TRANSIT_TIME,
    name: 'Transit Time',
    dataTest: 'transit-time-setting',
    enabled: featureIsEnabled(ORG_FEATURES.STATISTICS_TRANSIT_TIME),
  },
  {
    id: ORG_FEATURES.STATISTICS_SHIPMENT_VOLUMES,
    name: 'Shipment Volumes',
    dataTest: 'shipment-volumes-setting',
    enabled: featureIsEnabled(ORG_FEATURES.STATISTICS_SHIPMENT_VOLUMES),
  },
  {
    id: ORG_FEATURES.STATISTICS_INVOICES,
    name: 'Invoices',
    dataTest: 'invoices-setting',
    enabled: featureIsEnabled(ORG_FEATURES.STATISTICS_INVOICES),
  },
  {
    id: ORG_FEATURES.STATISTICS_LAYTIME,
    name: 'Laytime',
    dataTest: 'laytime-setting',
    enabled: featureIsEnabled(ORG_FEATURES.STATISTICS_LAYTIME),
  },
  {
    id: ORG_FEATURES.STATISTICS_EMISSIONS,
    name: 'Emissions',
    dataTest: 'emissions-setting',
    enabled: featureIsEnabled(ORG_FEATURES.STATISTICS_EMISSIONS),
  },
  ...addIf(devFeatures.STATISTICS_WAITING_FOR_BERTH, {
    id: ORG_FEATURES.STATISTICS_WAITING_FOR_BERTH,
    name: 'Waiting for Berth',
    dataTest: 'waiting-for-berth-setting',
    enabled: featureIsEnabled(ORG_FEATURES.STATISTICS_WAITING_FOR_BERTH),
  }),
  {
    id: ORG_FEATURES.STATISTICS_PRODUCTIVITY,
    name: 'Productivity',
    dataTest: 'productivity-setting',
    enabled: featureIsEnabled(ORG_FEATURES.STATISTICS_PRODUCTIVITY),
  },
  {
    id: ORG_FEATURES.STATISTICS_BUNKERS,
    name: 'Bunkers',
    dataTest: 'bunkers-setting',
    enabled: featureIsEnabled(ORG_FEATURES.STATISTICS_BUNKERS),
  },
  {
    id: ORG_FEATURES.STATISTICS_CLAIMS,
    name: 'Claims',
    dataTest: 'claims-setting',
    enabled: featureIsEnabled(ORG_FEATURES.STATISTICS_CLAIMS),
  },
]);

const statisticsToggle = reactive<Setting>({
  id: ORG_FEATURES.STATISTICS_ALL,
  name: 'Statistics',
  dataTest: 'statistics-setting',
  enabled: statisticsSettings.some((setting) => setting.enabled),
});

const dataChoiceLive = ref<Setting>({
  id: DATA_CHOICE.LIVE,
  name: 'Live VMS Data',
  dataTest: 'live-vms-setting',
  enabled: !featureIsEnabled(ORG_FEATURES.SAILING_SCHEDULE_SYNC),
});

const dataChoiceSchedule = ref<Setting>({
  id: ORG_FEATURES.SAILING_SCHEDULE_SYNC,
  name: 'Adjusted Sailing Schedule',
  dataTest: 'adjusted-ss-setting',
  enabled: featureIsEnabled(ORG_FEATURES.SAILING_SCHEDULE_SYNC),
});

const updateSailingScheduleSync = (enabled: boolean) => {
  updateSetting({
    id: ORG_FEATURES.SAILING_SCHEDULE_SYNC,
    name: dataChoiceLive.value.name,
    enabled,
  });
};

const updateDataChoiceSetting = ({ id, enabled }) => {
  if (id === DATA_CHOICE.LIVE) {
    if (enabled !== dataChoiceSchedule.value.enabled) return;

    updateSailingScheduleSync(!enabled);
    dataChoiceSchedule.value.enabled = !enabled;
    return;
  }

  if (enabled !== dataChoiceLive.value.enabled) return;

  updateSailingScheduleSync(enabled);
  dataChoiceLive.value.enabled = !enabled;
};

const updateStatistics = (enabled: boolean) => {
  const everyStatisticIsEnabled = statisticsSettings.every(
    (statistic) => statistic.enabled,
  );

  if (enabled && everyStatisticIsEnabled) return;

  const everyStatisticIsDisabled = statisticsSettings.every(
    (statistic) => !statistic.enabled,
  );

  if (!enabled && everyStatisticIsDisabled) return;

  statisticsSettings.forEach((setting) => {
    setting.enabled = enabled;
  });

  emit('updateStatistics', { statisticsSettings, enabled });
};

const canEdit = computed(() =>
  hasOneOfPermissions(['organization_settings.update.any']),
);

watch(
  () => statisticsSettings,
  (settings) => {
    if (settings.some((setting) => setting.enabled)) {
      statisticsToggle.enabled = true;
      return;
    }

    if (settings.every((setting) => !setting.enabled)) {
      statisticsToggle.enabled = false;
    }
  },
  { deep: true },
);

watch(
  () => [
    globalSettings,
    statisticsSettings,
    afterStatistics,
    dataChoiceSchedule,
  ],
  () => {
    emit('updateSettings', [
      ...globalSettings,
      ...statisticsSettings,
      ...afterStatistics,
      dataChoiceSchedule.value,
    ]);
  },
  {
    deep: true,
  },
);
</script>

<template>
  <div data-test="organisation-settings">
    <slot>
      <div class="flex justify-between px-6 pt-6">
        <p class="text-subtitle-1 text-charcoal-9">Settings</p>
        <p class="mt-4 text-sm text-secondary-9">Disable / Enable</p>
      </div>
    </slot>

    <div
      class="divide mt-3 divide-y-2 divide-charcoal-2 border-t-2 border-charcoal-2"
    >
      <OrganisationSetting
        v-for="setting in globalSettings"
        :key="setting.id"
        v-model="setting.enabled"
        :name="setting.name"
        :data-test="setting.dataTest"
        :can-edit="canEdit"
        @update:model-value="updateSetting(setting)"
      />

      <OrganisationSettingCollapsible
        v-model="statisticsToggle.enabled"
        :name="statisticsToggle.name"
        :can-edit="canEdit"
        :data-test="statisticsToggle.dataTest"
        @update:model-value="updateStatistics"
      >
        <OrganisationSetting
          v-for="setting in statisticsSettings"
          :key="setting.id"
          v-model="setting.enabled"
          subsetting
          :data-test="setting.dataTest"
          :name="setting.name"
          :can-edit="canEdit"
          @update:model-value="updateSetting(setting)"
        />
      </OrganisationSettingCollapsible>

      <OrganisationSetting
        v-for="setting in afterStatistics"
        :key="setting.id"
        v-model="setting.enabled"
        :data-test="setting.dataTest"
        :name="setting.name"
        :can-edit="canEdit"
        @update:model-value="updateSetting(setting)"
      />
      <div>
        <div class="flex justify-between px-6 pb-3 pt-6">
          <p class="text-subtitle-1 text-charcoal-9">Data Source for ETA</p>
          <p class="mt-4 text-sm text-secondary-9">Disable / Enable</p>
        </div>

        <OrganisationSetting
          v-model="dataChoiceLive.enabled"
          :name="dataChoiceLive.name"
          :data-test="dataChoiceLive.dataTest"
          :can-edit="canEdit"
          @update:model-value="updateDataChoiceSetting(dataChoiceLive)"
        />
        <OrganisationSetting
          v-model="dataChoiceSchedule.enabled"
          :name="dataChoiceSchedule.name"
          :data-test="dataChoiceSchedule.dataTest"
          :can-edit="canEdit"
          @update:model-value="updateDataChoiceSetting(dataChoiceSchedule)"
        />
      </div>
    </div>
  </div>
</template>
