<script setup lang="ts" name="Icon">
import type { IconSize, IconType } from '~/types';

const { icon, size = '2xl' } = defineProps<{
  icon: IconType;
  size?: IconSize;
}>();

const SIZE_TO_CLASS = {
  xs: 'text-base leading-4', // h-4 w-4, 16px
  '2xl': 'text-2xl leading-6', // h-6 w-6, 24px
  '3xl': 'text-3xl leading-none', // h-8 w-8, 32px
  custom: '',
};
</script>

<template>
  <div :class="[icon, SIZE_TO_CLASS[size]]" class="shrink-0"></div>
</template>
