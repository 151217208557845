import { type RemovableRef, useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

import {
  ANALYTIC_SCRIPT_ID,
  COOKIE_LAW_STATE,
  LOCAL_STORAGE_KEYS,
  PINIA_STORE,
} from '~/constants';

import {
  addGoogleScript,
  addSleekPlanScript,
  initGoogle,
  removeGoogleScripts,
  removeScript,
  toggleSleekPlanVisibility,
} from '~/features/useAnalyticsHelpers';

import { useProfileStore } from './useProfileStore';

type CookieLawState = (typeof COOKIE_LAW_STATE)[keyof typeof COOKIE_LAW_STATE];

const NO_ID = -1 as const;

type IMaybe = null | undefined;

type Item<T> = T | IMaybe;
type StoredItem<T> = RemovableRef<Item<T>>;

interface IAnalyticsState {
  googleIsEnabled: StoredItem<boolean>;
  cookieLawState: StoredItem<CookieLawState>;
  googleToken: string;
  sleekPlanIsInitialized: boolean;
}

export const useAnalyticsStore = defineStore(PINIA_STORE.ANALYTICS_STORE, {
  state: (): IAnalyticsState => ({
    googleIsEnabled: useLocalStorage<Item<boolean>>(
      LOCAL_STORAGE_KEYS.GOOGLE_ENABLED,
      initGoogle(),
    ),
    cookieLawState: useLocalStorage<Item<CookieLawState>>(
      LOCAL_STORAGE_KEYS.COOKIE_LAW,
      null,
    ),
    googleToken: import.meta.env.VITE_GA_TOKEN,
    sleekPlanIsInitialized: false,
  }),
  getters: {
    userId: (): number => useProfileStore().profile?.id || NO_ID,
    shouldShowCookieLaw: (state): boolean => state.cookieLawState === null,
  },
  actions: {
    update(userId: number): void {
      if (!this.googleIsEnabled || window.gtag === undefined) {
        return;
      }
      window.gtag('config', this.googleToken, {
        user_id: userId,
      });
    },
    acceptCookieLaw(): void {
      this.cookieLawState = COOKIE_LAW_STATE.ACCEPTED;
      this.googleIsEnabled = true;

      addGoogleScript(this.googleToken, this.userId);
    },
    rejectCookieLaw(): void {
      this.cookieLawState = COOKIE_LAW_STATE.REJECTED;
      this.googleIsEnabled = false;

      removeGoogleScripts();
      removeScript(ANALYTIC_SCRIPT_ID.HOT_JAR);
    },
    showCookieLaw(): void {
      this.cookieLawState = null;
      this.googleIsEnabled = false;

      removeGoogleScripts();
      removeScript(ANALYTIC_SCRIPT_ID.HOT_JAR);
    },
    toggleSleekPlanWidget(show: boolean): void {
      if (!this.sleekPlanIsInitialized && show) {
        this.sleekPlanIsInitialized = true;
        addSleekPlanScript();
        return;
      }

      toggleSleekPlanVisibility(show);
    },
  },
});
