<script lang="ts" setup name="BaseItem">
import { type Component, computed } from 'vue';

import { BASE_ITEM_TYPE } from '~/constants';

import { formatNumber } from '~/features/useNumbers';

import BaseCircle from '~/components/BaseCircle.vue';
import BaseLink from '~/components/BaseLink.vue';
import BaseTag from '~/components/BaseTag.vue';
import CountryWithFlag from '~/components/CountryWithFlag.vue';
import TitleCaption from '~/components/TitleCaption.vue';

import type {
  BaseItemType,
  BaseTagContrastType,
  IBaseIcon,
  IBaseItemCircle,
} from '~/types';

import Icon from './Icon.vue';

const {
  label = '',
  value = null,
  unit = '',
  link = '',
  loading = false,
  type = BASE_ITEM_TYPE.DEFAULT,
  fallback = null,
  bold = false,
  capitalize = false,
  decimal = 0,
  truncate = false,
  useHtml = false,
  tagVariant = 'contrast-charcoal-2',
  textSmall = false,
  inheritColor = false,
  swap = false,
  disabled = false,
  icon,
} = defineProps<{
  icon?: IBaseIcon | null;
  circle?: IBaseItemCircle | null;
  label?: string;
  value?: string | number | null;
  unit?: string;
  link?: string;
  loading?: boolean;
  type?: BaseItemType;
  fallback?: null | string | number;
  bold?: boolean;
  capitalize?: boolean;
  decimal?: number;
  truncate?: boolean;
  useHtml?: boolean;
  tagVariant?: BaseTagContrastType;
  textSmall?: boolean;
  inheritColor?: boolean;
  width?: number | 'md' | 'lg' | 'xl';
  swap?: boolean;
  disabled?: boolean;
  component?: Component | null;
}>();

const ICON_COLOR = {
  primary: 'text-primary-8',
  secondary: 'text-charcoal-6',
  disabled: 'text-charcoal-5',
};

const TEXT_COLOR = {
  [BASE_ITEM_TYPE.DANGER]: 'text-red-6',
  [BASE_ITEM_TYPE.WARNING]: 'text-warning-10',
  [BASE_ITEM_TYPE.INFO]: 'text-charcoal-8',
  [BASE_ITEM_TYPE.CHANGED]: 'text-orange-7',
  [BASE_ITEM_TYPE.NUMBER_CHANGED]: 'text-orange-7',
  [BASE_ITEM_TYPE.DEFAULT]: '',
};

const numberTypes: BaseItemType[] = [
  BASE_ITEM_TYPE.NUMBER,
  BASE_ITEM_TYPE.NUMBER_DANGER,
  BASE_ITEM_TYPE.NUMBER_CHANGED,
];

const itemValue = computed(() => {
  const newValue = numberTypes.includes(type)
    ? formatNumber(value, decimal)
    : value;

  return newValue || fallback;
});

const typeIs = computed(
  () =>
    (...types: BaseItemType[]) =>
      types.includes(type) && !!value,
);

const textColorClass = computed(() => {
  if (disabled) {
    return '!text-charcoal-5';
  }

  return TEXT_COLOR[type];
});

const iconColorClass = computed(() => {
  if (!icon) {
    return '';
  }

  return disabled ? ICON_COLOR.disabled : ICON_COLOR[icon.color];
});
</script>

<template>
  <div>
    <TitleCaption v-if="label && !swap" truncate :disabled="disabled">
      {{ label }}
    </TitleCaption>
    <CountryWithFlag
      v-if="typeIs(BASE_ITEM_TYPE.FLAG)"
      class="mt-1"
      :country="value as string"
    />
    <BaseTag
      v-else-if="typeIs(BASE_ITEM_TYPE.TAG_VARIANT)"
      class="mt-1"
      :name="value as string"
      :variant="tagVariant"
      :disabled="disabled"
    />
    <a
      v-else-if="typeIs(BASE_ITEM_TYPE.LINK) && link"
      target="_blank"
      :href="link"
    >
      <BaseLink size="base" :icon="null">
        {{ value }}
      </BaseLink>
    </a>
    <component v-else-if="component" :is="component" />

    <div v-else class="flex items-center gap-2">
      <Icon v-if="icon" :icon="icon.name" size="xs" :class="iconColorClass" />
      <BaseCircle
        v-if="circle"
        v-bind="circle"
        :size="circle.background === 'pulse' ? 'sm' : 'xs'"
        :disabled="disabled"
      />
      <p
        v-title
        class="space-x-1"
        :class="[
          textColorClass,
          {
            'bg-charcoal-3':
              loading &&
              !typeIs(BASE_ITEM_TYPE.DANGER, BASE_ITEM_TYPE.NUMBER_DANGER),
            'mt-1 max-w-min rounded bg-charcoal-2 px-1 py-0.5 text-sm': typeIs(
              BASE_ITEM_TYPE.INFO,
            ),
            'font-bold': bold,
            'text-sm': textSmall,
            truncate: truncate,
            capitalize: capitalize,
            'text-body-1': !inheritColor && !swap,
            'text-subtitle-2': swap,
          },
        ]"
      >
        <template v-if="!useHtml"> {{ itemValue }} </template>
        <span v-else class="text-left font-body" v-html="itemValue" />
        <span
          v-if="unit && value"
          :class="{
            'text-charcoal-6': !inheritColor && !disabled,
            'ml-1': unit,
          }"
        >
          {{ unit }}
        </span>
      </p>
    </div>
    <TitleCaption v-if="label && swap" :disabled="disabled">
      {{ label }}
    </TitleCaption>
  </div>
</template>
