<script setup lang="ts" name="Stepper">
import { computed, ref, useSlots } from 'vue';
import { useForm } from 'vee-validate';

import StepperHeader from '~/components/StepperHeader.vue';
import StepperHeaderLayout from '~/components/StepperHeaderLayout.vue';

import type {
  IStepperConfig,
  IStepperTitle,
  StepperPositionType,
} from '~/types';

const {
  form,
  titles,
  position = 'horizontal',
} = defineProps<{
  form?: boolean;
  position?: StepperPositionType;
  titles?: IStepperTitle[];
  hideHeader?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', stepperConfig: IStepperConfig): void;
}>();

const slots = useSlots();

const activeStepIdx = ref(0);

const content = computed(() => slots.default?.());

const totalSteps = computed(() => content.value?.length ?? 0);

const isLastStep = computed(() => activeStepIdx.value === totalSteps.value - 1);

const isFirstStep = computed(() => activeStepIdx.value === 0);

const activeStepId = computed(
  () => content.value?.[activeStepIdx.value]?.props?.id || null,
);

const { validate, setErrors } = useForm();

const getNextStep = () => {
  let nextStepIdx = activeStepIdx.value + 1;

  while (nextStepIdx < totalSteps.value) {
    if (!titles?.[nextStepIdx].disabled) {
      break;
    }

    nextStepIdx++;
  }

  return nextStepIdx;
};

const getPreviousStep = () => {
  let previousStepIdx = activeStepIdx.value - 1;

  while (previousStepIdx >= 0) {
    if (!titles?.[previousStepIdx].disabled) {
      break;
    }

    previousStepIdx--;
  }

  return previousStepIdx >= 0 ? previousStepIdx : activeStepIdx.value;
};

const incrementStep = async () => {
  if (!content.value) {
    return;
  }

  const currentStep = content.value[activeStepIdx.value];
  const shouldValidate = currentStep.props?.validateStep === 'true';

  if (form && shouldValidate) {
    const { valid, errors } = await validate();

    if (!valid) {
      setErrors(errors);
      return;
    }
  }

  if (!isLastStep.value) {
    activeStepIdx.value = getNextStep();
  }
};

const decrementStep = () => {
  if (!isFirstStep.value) {
    activeStepIdx.value = getPreviousStep();
  }
};

const hasTitles = computed(() => titles?.some((item) => item));

const stepperConfig = computed<IStepperConfig>(() => ({
  totalSteps: totalSteps.value,
  activeStepIdx: activeStepIdx.value,
  activeStepId: activeStepId.value,
  isLastStep: isLastStep.value,
  onNextStep: incrementStep,
  onPrevStep: decrementStep,
}));

const positionIs = computed(
  () => (type: StepperPositionType) => type === position,
);
</script>

<template>
  <form
    :class="{ flex: positionIs('vertical') }"
    @submit.prevent="emit('onSubmit', stepperConfig)"
  >
    <slot name="preHeader" v-bind="stepperConfig" />

    <slot v-if="!hideHeader" name="header" v-bind="stepperConfig">
      <StepperHeaderLayout
        :position="position"
        :totalSteps="totalSteps"
        :hasTitles="hasTitles"
      >
        <StepperHeader
          :position="position"
          :totalSteps="totalSteps"
          :activeStepIdx="activeStepIdx"
          :isLastStep="isLastStep"
          :titles="titles"
        />
      </StepperHeaderLayout>
    </slot>

    <div
      :class="{
        'flex-1': positionIs('vertical'),
        'flex h-full flex-col': positionIs('horizontal'),
      }"
    >
      <div v-for="(step, index) in content" :key="index">
        <div v-show="index === activeStepIdx" :data-testid="`step-${index}`">
          <component
            v-if="index === activeStepIdx"
            :is="step"
            v-bind="{ stepperConfig }"
          />
        </div>
      </div>

      <slot name="footer" v-bind="stepperConfig" />
    </div>
  </form>
</template>
