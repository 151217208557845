import type {
  IDataTableCellPadding,
  IDataTablePaddingType,
  IDataTablePaddingVariant,
} from '~/types';

export const PADDING_CLASSES = {
  small_first: 'pl-3 pr-4',
  small_last: 'pl-4 pr-3',
  small_actions: 'pl-4 pr-2',
  small_loading: 'px-3 py-4',
  medium_first: 'pl-6 pr-4',
  medium_last: 'pl-4 pr-6',
  medium_actions: 'pl-4 pr-6',
  medium_loading: 'px-6 py-4',
} as const;

export const getCellPadding = (
  type?: IDataTablePaddingType,
  variant: IDataTablePaddingVariant = 'first',
): IDataTableCellPadding => {
  if (!type) {
    return '';
  }
  const key = `${type}_${variant}`;
  return PADDING_CLASSES[key];
};

export const calculateCellPadding = ({
  cellIndex,
  cellsLength,
  hasActions,
  checkboxSelection,
  collapsibleRows,
  paddingType,
}: {
  cellIndex: number;
  cellsLength: number;
  hasActions: boolean;
  checkboxSelection?: boolean;
  collapsibleRows?: boolean;
  paddingType?: IDataTablePaddingType;
}) => {
  const isFirstCell = cellIndex === 0 && !collapsibleRows && !checkboxSelection;
  const isLastCell = cellsLength - 1 === cellIndex && !hasActions;
  const variant = isLastCell ? 'last' : 'first';
  return isFirstCell || isLastCell ? getCellPadding(paddingType, variant) : '';
};
