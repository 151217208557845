import type { Ref } from 'vue';

import {
  isDynamicCell,
  isNestedKeyCell,
} from '~/features/useDataTableCellHelpers';
import type { IStepCellManager } from '~/features/useDataTableStepCells';

import type {
  IDataTableBaseRow,
  IDataTableCell,
  IDataTableCellValue,
  IOption,
} from '~/types';

import type { IAddressManager } from './useTableSelectedCell';

export type HandleInputUpdate = <T extends IDataTableBaseRow>(
  rowToUpdate: T,
  newValue: IDataTableCellValue,
) => void;

export const useDataTableRowUpdates = <T extends IDataTableBaseRow>({
  stepCellManager,
  addressManager,
  data,
  getRowByIndex,
  updateCellValue,
  onRowUpdate,
}: {
  stepCellManager: IStepCellManager;
  addressManager: IAddressManager;
  data: Ref<T[]>;
  updateCellValue;
  getRowByIndex;
  onRowUpdate;
}) => {
  const parseRow = <T extends IDataTableBaseRow>(row: T) =>
    JSON.parse(JSON.stringify(row));

  const updateDynamicCell = (
    updatedRow: T,
    cell: IDataTableCell & { dynamic: string },
    newValue: IDataTableCellValue,
  ) => {
    const [key, index, property] = cell.dynamic.split('.');

    if (cell.type === 'options') {
      const optionsValue = newValue as IOption;

      updatedRow[key][index] = {
        ...updatedRow[key][index],
        id: optionsValue ? optionsValue.id : null,
        name: optionsValue ? optionsValue.name : null,
      };
    } else {
      updatedRow[key][index][property] = newValue;
    }

    updatedRow[key][index].edited = true;
  };

  const updateNestedCell = (
    updatedRow: T,
    cell: IDataTableCell,
    newValue: IDataTableCellValue,
  ) => {
    const [key, nestedKey] = cell.key.split('.');
    if (cell.type === 'options') {
      const optionsValue = newValue as IOption;
      updatedRow[key] = {
        ...updatedRow[key],
        id: optionsValue ? optionsValue.id : null,
        name: optionsValue ? optionsValue.name : null,
      };
    } else {
      updatedRow[key][nestedKey] = newValue;
    }
  };

  const updateCellInRow = ({
    row,
    cell,
    newValue,
  }: {
    row: T;
    cell: IDataTableCell;
    newValue: IDataTableCellValue;
  }): T => {
    const updatedRow = parseRow(row);

    if (isDynamicCell(cell)) {
      updateDynamicCell(updatedRow, cell, newValue);
    } else if (isNestedKeyCell(cell)) {
      updateNestedCell(updatedRow, cell, newValue);
    } else {
      updatedRow[cell.key] = cell.value;
    }

    return updatedRow;
  };

  const updateRowForStepCells = () => {
    const rowIndex = addressManager.getRowAndColNumbersFromAddress().rowNumber;
    const tableRow = getRowByIndex(rowIndex);
    const rowById = data.value.find((row) => row.id === tableRow.id);

    if (!rowById) {
      throw new Error('Row to update was not found');
    }

    let rowToUpdate = parseRow(rowById);

    stepCellManager.stepCells.forEach((stepCell) => {
      rowToUpdate = updateCellInRow({
        row: rowToUpdate,
        cell: stepCell,
        newValue: stepCell.value,
      });
    });

    onRowUpdate(rowToUpdate);
  };

  const updateStepCellValue = (newValue: IDataTableCellValue) => {
    stepCellManager.updateCurrentStepValue(newValue);

    const { rowNumber, colNumber } =
      addressManager.getRowAndColNumbersFromAddress();

    updateCellValue({ rowNumber, colNumber, newValue });
  };

  const updateRowForCellValue = <T extends IDataTableBaseRow>(
    rowToUpdate: T,
    newValue: IDataTableCellValue,
  ) => {
    const { rowNumber, colNumber } =
      addressManager.getRowAndColNumbersFromAddress();

    const updatedCell = updateCellValue({ rowNumber, colNumber, newValue });

    const row = parseRow(rowToUpdate);

    const updatedRow = updateCellInRow({
      row,
      cell: updatedCell,
      newValue,
    });

    onRowUpdate(updatedRow);
  };

  const handleInputUpdate = <T extends IDataTableBaseRow>(
    rowToUpdate: T,
    newValue: IDataTableCellValue,
  ) => {
    if (stepCellManager.hasUncompletedSteps()) {
      updateStepCellValue(newValue);
      return;
    }

    updateRowForCellValue(rowToUpdate, newValue);
  };

  return {
    handleInputUpdate,
    updateRowForStepCells,
  };
};
