<script lang="ts" setup name="ServiceState">
import { computed } from 'vue';

import type { ServiceLoadingType } from '~/types/serviceState';

const { loading, state } = defineProps<{
  state?: string;
  loading?: ServiceLoadingType;
  stateIsLoading?: boolean;
  stateIsError?: boolean;
  stateIsNotAuthorized?: boolean;
  stateIsNoData?: boolean;
  stateIsNoResults?: boolean;
  stateIsNotAvailable?: boolean;
  stateIsIdle?: boolean;
  stateIsValidationError?: boolean;
}>();

const loadingIs = computed(
  () => (state: ServiceLoadingType) => loading === state,
);
</script>

<template>
  <slot v-if="stateIsLoading && loadingIs('spinner')" name="loading" />
  <slot v-else-if="stateIsError" name="error" />
  <slot v-else-if="stateIsNotAuthorized" name="error-auth" />
  <slot v-else-if="stateIsNotAvailable" name="not-available" />
  <slot v-else-if="stateIsNoResults" name="no-results" />
  <slot v-else-if="stateIsNoData" name="no-data" />
  <slot v-else-if="stateIsValidationError" name="validation-error" />
  <slot v-else-if="stateIsIdle || loadingIs('skeleton')" />
  <p v-else class="bg-red-5 text-9xl text-white">
    State `{{ state }}` is not a valid state.
  </p>
</template>
