<script setup lang="ts" name="AdminAcceptUserOrganisationsStep">
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';

import AdminUserOrganisationAssign from '~/components/AdminUserOrganisationAssign.vue';
import AdminUserOrganisationNew from '~/components/AdminUserOrganisationNew.vue';

import type {
  IAdminRequestedUser,
  IAdminUserAcceptForm,
  IOption,
  IStepperConfig,
} from '~/types';

const props = defineProps<{
  form: IAdminUserAcceptForm;
  initialForm: IAdminUserAcceptForm;
  stepperConfig?: IStepperConfig;
  user?: IAdminRequestedUser;
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:form', value: IOption): void;
  (e: 'update:initialForm', value: IOption): void;
  (e: 'assignOrgSubmit', next: () => void): void;
  (e: 'createOrgSubmit', next: () => void): void;
}>();

const isAssignOrgVisible = ref(true);

const form = useVModel(props, 'form', emit);
const initialForm = useVModel(props, 'initialForm', emit);
</script>

<template>
  <AdminUserOrganisationAssign
    v-if="isAssignOrgVisible"
    v-model="form.organisation"
    :loading="loading"
    :user="user"
    :stepperConfig="stepperConfig"
    @showCreateOrg="isAssignOrgVisible = false"
    @assignOrgSubmit="emit('assignOrgSubmit', $event)"
  />
  <AdminUserOrganisationNew
    v-else
    v-model:form="form.newOrganisation"
    v-model:initialForm="initialForm.newOrganisation"
    @showAssignOrg="isAssignOrgVisible = true"
    :user="user"
    @createOrgSubmit="
      emit('createOrgSubmit', props.stepperConfig?.onNextStep ?? (() => {}))
    "
  />
</template>
