<script setup lang="ts" name="AdminAcceptUserOrganisationsNavigation">
import AdminAcceptUserStepperFooter from '~/components/AdminAcceptUserStepperFooter.vue';
import BaseButton from '~/components/BaseButton.vue';

const { organisationExists } = defineProps<{
  organisationExists?: boolean;
}>();

const emit = defineEmits<{
  (e: 'prev'): void;
  (e: 'showCreateOrg'): void;
  (e: 'assignOrganisation'): void;
}>();
</script>

<template>
  <AdminAcceptUserStepperFooter>
    <BaseButton variant="btn-tertiary" @click="emit('prev')"> Back </BaseButton>

    <template v-if="organisationExists">
      <BaseButton variant="btn-secondary" @click="emit('showCreateOrg')">
        Create New Organisation
      </BaseButton>
      <BaseButton @click="emit('assignOrganisation')">
        Assign Organisation
      </BaseButton>
    </template>

    <template v-else>
      <BaseButton variant="btn-secondary" @click="emit('assignOrganisation')">
        Assign Organisation
      </BaseButton>
      <BaseButton @click="emit('showCreateOrg')">
        Create New Organisation
      </BaseButton>
    </template>
  </AdminAcceptUserStepperFooter>
</template>
