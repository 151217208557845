<script setup lang="ts" name="UserNotificationManagementRow">
import { computed } from 'vue';
import { RouterLink } from 'vue-router';
import { useVModel } from '@vueuse/core';

import BaseToggle from '~/components/BaseToggle.vue';

const props = defineProps<{
  id: string;
  name: string;
  email: boolean;
  notification: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:email'): () => void;
  (e: 'update:notification'): () => void;
}>();

const email = useVModel(props, 'email', emit);
const notification = useVModel(props, 'notification', emit);

const viewPath = computed(() => ({
  name: 'OrganisationSettingsIndex',
  params: { organisationId: props.id },
}));
</script>

<template>
  <div
    class="grid grid-cols-3 items-center gap-x-2 py-6 pl-4 hover:bg-charcoal-0 sm:flex sm:pl-4 sm:pr-6"
  >
    <RouterLink
      :to="viewPath"
      class="block min-w-0 flex-1 cursor-pointer text-primary-8"
    >
      <p v-title class="">
        {{ name }}
      </p>
    </RouterLink>
    <div class="grid-span col-span-2 grid grid-cols-2 gap-x-2 sm:flex">
      <div class="flex justify-center sm:w-32">
        <BaseToggle v-model="notification" />
      </div>
      <div class="flex justify-center sm:w-32">
        <BaseToggle v-model="email" />
      </div>
    </div>
  </div>
</template>
