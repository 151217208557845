<script setup lang="ts" name="DataTableRow">
import { ref } from 'vue';
import { useResizeObserver } from '@vueuse/core';

import { getCellPadding } from '~/features/useDataTableCellPadding';

import BaseCollapsible from '~/components/BaseCollapsible.vue';
import Icon from '~/components/Icon.vue';

import type { IDataTablePaddingType } from '~/types';

const {
  editable,
  isCollapsibleDisabled = false,
  isCollapsibleOpen = false,
  selected = false,
} = defineProps<{
  padding?: IDataTablePaddingType;
  selected?: boolean;
  collapsible?: boolean;
  isCollapsibleOpen?: boolean;
  isCollapsibleDisabled?: boolean;
  editable?: boolean;
}>();

const emit = defineEmits<{
  toggle: [];
}>();

const expandableRow = ref(null);
const expandableRowHeight = ref(0);

const onToggle = (toggle: () => void) => {
  toggle();
  emit('toggle');
};

const handleRowClicked = (toggle: () => void) => {
  if (!isCollapsibleDisabled && !editable) {
    onToggle(toggle);
  }
};

useResizeObserver(expandableRow, ([entry]) => {
  if (!expandableRow.value) return;
  const { height } = entry.contentRect;
  expandableRowHeight.value = Math.round(height);
});
</script>

<template>
  <div
    v-if="!collapsible"
    role="row"
    class="col-span-full grid grid-cols-subgrid"
    :class="{
      'group border-b border-charcoal-2 last:border-none hover:bg-charcoal-1':
        !selected,
      'border-2 border-ocean-4 bg-ocean-0': selected,
    }"
  >
    <slot />
  </div>

  <BaseCollapsible
    v-else
    class="col-span-full grid grid-cols-subgrid border-b border-charcoal-2 last:border-none"
    transitionClass="col-span-full grid grid-cols-subgrid"
    :openByDefault="isCollapsibleOpen && !isCollapsibleDisabled"
  >
    <template #trigger="{ toggle, isOpen }">
      <div
        ref="expandableRow"
        data-testid="expandable-row"
        class="col-span-full grid grid-cols-subgrid"
        :class="{
          'border-2 border-l-0 border-r-0 border-ocean-4 bg-ocean-0': selected,
          'group border-b border-charcoal-2 last:border-none hover:bg-charcoal-1':
            !selected,
        }"
        @click="handleRowClicked(toggle)"
      >
        <div
          v-if="selected"
          class="absolute border-l-2 border-ocean-4"
          :style="{ height: `${expandableRowHeight}px` }"
        />
        <button
          class="flex self-center"
          type="button"
          :disabled="isCollapsibleDisabled"
          @click.stop="onToggle(toggle)"
        >
          <div
            class="sticky left-0 top-0 z-10 flex w-min shrink-0 grow-0 items-center gap-x-2 p-4"
            :class="getCellPadding(padding)"
          >
            <Icon
              :icon="isOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'"
              :class="{
                'text-charcoal-4': isCollapsibleDisabled,
                'text-primary-8': !isCollapsibleDisabled,
              }"
            />
          </div>
        </button>

        <div
          role="row"
          class="col-[2_/_-1] grid grid-cols-subgrid"
          :class="{
            'cursor-pointer':
              collapsible && !isCollapsibleDisabled && !editable,
          }"
        >
          <slot :isOpen="isOpen" />
        </div>
      </div>
    </template>

    <slot name="collapsible" />
  </BaseCollapsible>
</template>
