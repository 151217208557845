<script setup lang="ts" name="FieldDateRange">
import { computed, h, shallowRef } from 'vue';
import { useVModel } from '@vueuse/core';
import { ElDatePicker } from 'element-plus';

import FieldBaseErrors from '~/components/FieldBaseErrors.vue';
import Icon from '~/components/Icon.vue';

const props = defineProps<{
  field: {
    name: string;
    label: string;
    rules: string;
    disabled?: boolean;
  };
  errors: string[];
  modelValue: string[];
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string[]): void;
}>();

const customIconPrefix = shallowRef({
  render() {
    return h(Icon, {
      icon: 'mdi:calendar-blank-outline',
      class: 'text-charcoal-5 text-base',
    });
  },
});

const inputValue = useVModel(props, 'modelValue', emit);

const styles = computed(() => {
  if (props.field.disabled) {
    return 'width: 100%;';
  }

  return `
    width: 100%;
    --el-input-hover-border-color: #266764;
    --el-input-focus-border-color: #266764;
    --el-color-primary: #266764;
  `;
});

const onFocus = () => {
  const start = document.getElementById('month-selection-start');
  const end = document.getElementById('month-selection-end');

  if (start && end) {
    start.setAttribute('placeholder', 'DD/MM/YYYY');
    end.setAttribute('placeholder', 'DD/MM/YYYY');
  }
};

const onBlur = () => {
  const start = document.getElementById('month-selection-start');
  const end = document.getElementById('month-selection-end');

  if (start && end) {
    start.setAttribute('placeholder', 'Start date');
    end.setAttribute('placeholder', 'End date');
  }
};

const errorStyles = computed(() =>
  props.errors.length > 0 ? 'border border-red-6' : '',
);
</script>

<template>
  <div>
    <ElDatePicker
      v-model="inputValue"
      size="large"
      type="daterange"
      format="DD/MM/YYYY"
      value-format="D MMM YYYY"
      range-separator="To"
      end-placeholder="End date"
      start-placeholder="Start date"
      unlink-panels
      :class="errorStyles"
      :editable="false"
      :name="field.name"
      :disabled="field.disabled"
      :teleported="true"
      :prefix-icon="customIconPrefix"
      :id="['month-selection-start', 'month-selection-end']"
      :style="styles"
      @focus="onFocus"
      @blur="onBlur"
    />
    <FieldBaseErrors
      v-if="props.errors.length > 0"
      :errors="props.errors"
      class="mt-1"
      :data-test="`error`"
    />
  </div>
</template>
