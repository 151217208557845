<script setup lang="ts" name="FilterTag">
import Icon from '~/components/Icon.vue';

defineProps<{
  name: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  click: [];
}>();
</script>

<template>
  <button
    type="button"
    class="group flex items-center rounded border border-solid border-charcoal-2 bg-charcoal-1 px-2 py-1 text-caption text-charcoal-8 hover:text-ocean-8 disabled:bg-white disabled:text-charcoal-4"
    :disabled="disabled"
    @click="emit('click')"
  >
    <span>
      <slot>
        {{ name }}
      </slot>
    </span>
    <Icon
      icon="ic:round-close"
      class="ml-2 text-charcoal-6 group-hover:text-ocean-8 group-disabled:text-charcoal-4"
      size="xs"
    />
  </button>
</template>
