<script lang="ts" setup name="FormWizardSearchableList" generic="T">
import { useVModel } from '@vueuse/core';

import BaseAlert from '~/components/BaseAlert.vue';
import BaseCardLoading from '~/components/BaseCardLoading.vue';
import BaseCardNoResults from '~/components/BaseCardNoResults.vue';
import FieldTags from '~/components/FieldTags.vue';
import SearchField from '~/components/SearchField.vue';

const props = defineProps<{
  modelValue?: string;
  items?: T[];
  selectedItems?: T[];
  placeholder?: string;
  errors?: string[];
  errorMessage?: string;
  title?: string;
  subtitle?: string;
  loading?: boolean;
  selectedItemTagKey?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue'): void;
  (e: 'onRemoveSelectedItem'): void;
  (e: 'clearSearch'): void;
}>();

const search = useVModel(props, 'modelValue', emit);
</script>

<template>
  <div>
    <BaseAlert v-if="errors && errors.length > 0" type="error" class="mb-4">
      {{ errorMessage }}
    </BaseAlert>
    <p class="text-subtitle-1 text-charcoal-9">{{ title }}</p>

    <p class="mb-4 mt-2 text-body-1">
      {{ subtitle }}
    </p>
    <FieldTags
      v-if="selectedItems?.length"
      class="my-4"
      :items="selectedItems"
      :selected-item-tag-key="selectedItemTagKey"
      @remove="emit('onRemoveSelectedItem', $event)"
    />
    <SearchField v-model="search" :placeholder="placeholder" />
    <div
      class="-mx-6 -mb-6 max-h-80 overflow-y-auto border-b border-charcoal-2"
    >
      <BaseCardLoading v-if="loading" small />
      <BaseCardNoResults
        v-else-if="items?.length === 0"
        message="Please amend the search criteria or clear search field to see all available companies."
        @clear="emit('clearSearch')"
      />
      <div v-else class="pl-7">
        <slot />
      </div>
    </div>
  </div>
</template>
