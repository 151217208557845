<script lang="ts" setup name="UserListPendingActiveUserInfo">
import { computed } from 'vue';

import { getFullName } from '~/features/useUtilities';

import Avatar from '~/components/Avatar.vue';

const { firstName, lastName, email } = defineProps<{
  firstName?: string;
  lastName?: string;
  email?: string;
}>();

const fullName = computed(() => getFullName(firstName, lastName));
</script>

<template>
  <div class="flex">
    <Avatar size="lg" :name="fullName" />
    <div class="space-y-1 pl-4">
      <p class="text-body-1">{{ fullName }}</p>
      <p class="truncate text-body-2 text-charcoal-6">
        {{ email }}
      </p>
    </div>
  </div>
</template>
