<script setup name="FiltersClearButton" lang="ts">
const emit = defineEmits<{
  (e: 'clear'): void;
}>();
</script>

<template>
  <button
    type="button"
    class="rounded px-2 py-1 text-button-1 uppercase text-red-6 hover:bg-charcoal-1 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-5 focus-visible:ring-offset-2"
    @click="emit('clear')"
  >
    <slot>Clear</slot>
  </button>
</template>
