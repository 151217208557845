<script setup lang="ts" name="ModalConfirm">
import BaseButton from '~/components/BaseButton.vue';
import BaseModalStatus from '~/components/BaseModalStatus.vue';

import type {
  IButtonVariant,
  IconType,
  ModalPositionType,
  ModalTextAlignType,
  ModalTextColorType,
  ModalType,
} from '~/types';

const {
  title,
  message,
  cancel = 'Cancel',
  confirm = 'Confirm',
  icon = 'ic:baseline-delete',
  isLoading = false,
  confirmType = 'btn-tertiary',
  modalType = 'danger',
} = defineProps<{
  title: string;
  message?: string;
  cancel?: string;
  confirm?: string;
  icon?: IconType;
  isLoading?: boolean;
  confirmType?: IButtonVariant;
  confirmDanger?: boolean;
  modalType?: ModalType;
  position?: ModalPositionType;
  textAlign?: ModalTextAlignType;
  textColor?: ModalTextColorType;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();
</script>

<template>
  <BaseModalStatus
    :title="title"
    :message="message"
    :icon="icon"
    :modalType="modalType"
    :position="position"
    :textAlign="textAlign"
    :textColor="textColor"
  >
    <slot />
    <template #actions>
      <div class="flex w-full justify-end space-x-2 px-6 pb-6">
        <BaseButton
          v-if="cancel"
          data-test="dialog-cancel"
          variant="btn-tertiary"
          :loading="isLoading"
          @click="emit('cancel')"
        >
          {{ cancel }}
        </BaseButton>

        <BaseButton
          data-test="dialog-confirm"
          :variant="confirmType"
          :danger="confirmDanger"
          :loading="isLoading"
          @click="emit('confirm')"
        >
          {{ confirm }}
        </BaseButton>
      </div>
    </template>
  </BaseModalStatus>
</template>
