import { ANALYTIC_SCRIPT_ID, SLEEK_PLAN_SCRIPT_ID } from '~/constants';

import { storage } from '~/features/useStorage';

type UserNoId = typeof NO_ID;
type UserId = number | null | UserNoId;

type ScriptIds = (typeof ANALYTIC_SCRIPT_ID)[keyof typeof ANALYTIC_SCRIPT_ID];

const NO_ID = 'not_logged_in' as const;

export const removeScript = (id: ScriptIds) => {
  const scripts = document.querySelectorAll(`[data-script-id=${id}]`);
  if (!scripts) return;

  scripts.forEach((el) => el.remove());
};

const addScript = (id: ScriptIds, source: string, src = false) => {
  // just in case we have a duplicate
  removeScript(id);
  const script = document.createElement('script');
  if (src) {
    script.src = source;
    script.async = true;
  } else {
    script.innerHTML = source;
  }
  script.setAttribute('data-script-id', id);
  document.head.appendChild(script);
};

export const addGoogleScript = (token: string, id: UserId = null) => {
  if (!token) {
    return;
  }
  const userId = id || storage.getProfile()?.id || NO_ID;

  const scriptSource = `https://www.googletagmanager.com/gtag/js?id=${token}`;
  addScript(ANALYTIC_SCRIPT_ID.GOOGLE_TAG_MANAGER, scriptSource, true);

  const script = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', '${token}', {
      send_page_view: false,
      custom_map: { dimension1: 'organisationName' },
      user_id: '${userId}',
    });
    `;

  addScript(ANALYTIC_SCRIPT_ID.GOOGLE_SCRIPT, script);
};

export const removeGoogleScripts = () => {
  removeScript(ANALYTIC_SCRIPT_ID.GOOGLE_TAG_MANAGER);
  removeScript(ANALYTIC_SCRIPT_ID.GOOGLE_SCRIPT);
};

export const addSleekPlanScript = () => {
  const sleekId = import.meta.env.VITE_SLEEKPLAN_ID;
  if (!sleekId) {
    return;
  }

  try {
    window.$sleek = [];
    window.SLEEK_PRODUCT_ID = sleekId;

    const script = document.createElement('script');

    script.src = 'https://client.sleekplan.com/sdk/e.js';
    script.async = true;
    script.setAttribute('data-script-id', SLEEK_PLAN_SCRIPT_ID);

    document.getElementsByTagName('head')[0].appendChild(script);
  } catch (e) {
    console.error(e);
  }
};

export const toggleSleekPlanVisibility = (show: boolean) => {
  if (show) {
    window.$sleek?.showButton?.();

    return;
  }
  window.$sleek?.hideButton?.();
};

export const initGoogle = () => {
  const googleIsEnabled = storage?.getGoogleIsEnabled();
  if (googleIsEnabled) {
    const googleToken = import.meta.env.VITE_GA_TOKEN;
    addGoogleScript(googleToken);
  }
  return googleIsEnabled;
};
