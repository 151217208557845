<script setup lang="ts" name="AdminUserOrganisationNew">
import { computed, onBeforeMount, ref } from 'vue';
import { useVModel } from '@vueuse/core';

import { ROLES } from '~/constants';

import { getOrganisationDefaultSchema } from '~/features/useOrganisationForm';
import { useService } from '~/features/useService';

import Service from '~/services/Service.js';

import AdminAcceptUserStepperFooter from '~/components/AdminAcceptUserStepperFooter.vue';
import AdminOrganisationNewCustomerUsers from '~/components/AdminOrganisationNewCustomerUsers.vue';
import AdminOrganisationNewSettings from '~/components/AdminOrganisationNewSettings.vue';
import AdminUserOrganisationNewLayout from '~/components/AdminUserOrganisationNewLayout.vue';
import BaseButton from '~/components/BaseButton.vue';
import Field from '~/components/Field.vue';
import FieldFormSection from '~/components/FieldFormSection.vue';
import Icon from '~/components/Icon.vue';
import OrganisationGeneralInfo from '~/components/OrganisationGeneralInfo.vue';
import OrganisationUsersForm from '~/components/OrganisationUsersForm.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import Stepper from '~/components/Stepper.vue';

import type {
  IAdminRequestedUser,
  IFormField,
  IOrganisationCustomerUser,
  IOrganisationNew,
  IOrganisationRoles,
  IOrganisationUser,
  IOrganisationWizardResponse,
  IStepperConfig,
  Setting,
} from '~/types';

const props = defineProps<{
  form: IOrganisationNew;
  initialForm: IOrganisationNew;
  user?: IAdminRequestedUser;
}>();

const emit = defineEmits<{
  (e: 'update:form', value: string[]): void;
  (e: 'update:initialForm', value: string[]): void;
  (e: 'showAssignOrg'): void;
  (e: 'createOrgSubmit'): void;
}>();

const form = useVModel(props, 'form', emit);
const initialForm = useVModel(props, 'initialForm', emit);

const userList = ref<IOrganisationUser[]>([]);
const customerRoles = ref<IOrganisationRoles[]>([]);

const onPrev = (prev: () => void, activeStepId: string) => {
  if (activeStepId === 'name') {
    emit('showAssignOrg');
  }

  prev();
};

const onNext = (stepper: IStepperConfig) => {
  if (stepper.activeStepId === 'settings') {
    emit('createOrgSubmit');
  }

  stepper.onNextStep();
};

const defaultSchema = getOrganisationDefaultSchema();

const schema = computed<Record<string, IFormField>>(() => ({
  organisationName: defaultSchema.organisationName,
  organisationType: defaultSchema.organisationType,
  companies: {
    ...defaultSchema.companies,
    type: form.value.type,
  },
}));

const updateUsers = (users: IOrganisationCustomerUser[]) => {
  form.value.customerUsers = users;
};

const updateSettings = (settings: Setting[]) => {
  form.value.settings = settings
    .filter((feature) => feature.enabled)
    .map((feature) => feature.id);
};

const preselectInternalAdmins = (users: IOrganisationUser[]) => {
  const internalAdmins = users.filter(
    (user) => user.role.name === ROLES.G2O_ADMIN,
  );

  form.value.users = internalAdmins;
};

const serviceState = useService(
  Service.organisationList().newOrganisationMeta(),
  ({ data }: { data: IOrganisationWizardResponse }) => {
    preselectInternalAdmins(data.users);

    userList.value = data.users.filter((user) => user.email_verified);
    customerRoles.value = data.roles;

    const users = [
      {
        firstName: props.user?.first_name || '',
        lastName: props.user?.last_name || '',
        email: props.user?.email || '',
        role: data.roles[1],
        hideDelete: true,
      },
    ];

    updateUsers(users);
    initialForm.value.customerUsers = users;

    return true;
  },
);

onBeforeMount(() => {
  const organisation = props.user?.organisations[0] || '';

  form.value.name = organisation;
  initialForm.value.name = organisation;
});
</script>

<template>
  <Stepper form @onSubmit="onNext" hide-header>
    <ServiceCard id="name" validateStep="true" v-bind="serviceState" styled>
      <div
        class="bg-white px-6 pb-16 pt-6 shadow"
        id="name"
        validateStep="true"
      >
        <FieldFormSection title="Provide General Information" />

        <OrganisationGeneralInfo :schema="schema" :form="form" />
      </div>
    </ServiceCard>

    <AdminUserOrganisationNewLayout id="companies" validateStep="true">
      <Field v-model="form.companies" :field="schema.companies" />
    </AdminUserOrganisationNewLayout>

    <AdminUserOrganisationNewLayout id="g2o-users" validateStep="true">
      <OrganisationUsersForm v-model="form.users" :users="userList" />
    </AdminUserOrganisationNewLayout>

    <AdminUserOrganisationNewLayout id="customer-users" validateStep="true">
      <AdminOrganisationNewCustomerUsers
        :orgType="form.type"
        :customer-users="form.customerUsers"
        :customer-roles="customerRoles"
        @update="updateUsers"
      />
    </AdminUserOrganisationNewLayout>

    <AdminUserOrganisationNewLayout id="settings" validateStep="true">
      <AdminOrganisationNewSettings
        :settings="form.settings"
        @update-settings="updateSettings"
      />
    </AdminUserOrganisationNewLayout>

    <template #footer="{ onPrevStep, activeStepId, isLastStep }">
      <AdminAcceptUserStepperFooter>
        <BaseButton
          variant="btn-secondary"
          @click="onPrev(onPrevStep, activeStepId)"
        >
          Back
        </BaseButton>

        <BaseButton type="submit">
          {{ isLastStep ? 'Submit and assign' : 'Next' }}

          <template v-if="!isLastStep" #rightIcon>
            <Icon icon="mdi:chevron-right" />
          </template>
        </BaseButton>
      </AdminAcceptUserStepperFooter>
    </template>
  </Stepper>
</template>
