<script setup lang="ts" name="AdminOrganisationNewSettings">
import OrganisationSettings from '~/components/OrganisationSettings.vue';

import type { ActiveSetting, Setting } from '~/types';

const { settings } = defineProps<{
  settings: ActiveSetting[];
}>();

const emit = defineEmits<{
  (e: 'updateSettings', value: Setting[]): void;
}>();
</script>

<template>
  <OrganisationSettings
    :settings="settings"
    @update-settings="emit('updateSettings', $event)"
  >
    <p class="mb-2 text-subtitle-1">Manage Settings</p>
    <p class="mb-4">
      Manage access to the specific pages. Only enabled sections will be
      displayed to Organisation users.
    </p>
  </OrganisationSettings>
</template>
