<script setup lang="ts" name="BaseButton">
import { computed, useSlots } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { useLink } from 'vue-router';

import {
  BUTTON_ICON_BREAKPOINTS,
  BUTTON_ICON_SIZE,
  BUTTON_SIZE,
  BUTTON_TEXT_BUTTON_BREAKPOINTS,
  BUTTON_TEXT_SIZE,
  BUTTON_TEXT_SIZE_MAP,
  BUTTON_TEXT_WIDTH_BREAKPOINTS,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from '~/constants';
const {
  danger,
  href,
  icon,
  label,
  hideLabelAtBreakpoint,
  roundedFull,
  iconSize = '2xl',
  to = '',
  description,
  loading = false,
  disabled = false,
  tooltipDescription = '',
  skeletonLoading = false,
  dataTest = 'g2-button',
  size = BUTTON_SIZE.MEDIUM,
  variant = BUTTON_VARIANT.PRIMARY,
  type = BUTTON_TYPE.BUTTON,
} = defineProps<{
  href?: string;
  target?: HTMLAnchorElement['target'];
  label?: string;
  icon?: IconType;
  loading?: boolean;
  dataTest?: string;
  tooltipDescription?: string;
  danger?: boolean;
  variant?: IButtonVariant;
  size?: IButtonSize;
  disabled?: boolean;
  description?: string;
  to?: RouteLocationRaw;
  skeletonLoading?: boolean;
  type?: IButtonType;
  roundedFull?: boolean;
  iconSize?: IconSize;
  hideLabelAtBreakpoint?: IButtScreenBreakpoints;
}>();
const emit = defineEmits<{
  (e: 'click'): void;
}>();

import Icon from '~/components/Icon.vue';
import Tooltip from '~/components/Tooltip.vue';

import type {
  IButtonSize,
  IButtonType,
  IButtonVariant,
  IButtScreenBreakpoints,
  IconSize,
  IconType,
} from '~/types';

const slots = useSlots();

const { navigate } = useLink({ to });

const isDisabled = computed(() => disabled && !skeletonLoading);
const isLoading = computed(() => skeletonLoading);

const isTextButton = computed<boolean>(
  () => !!label || (slots.default?.()?.length || 0) > 0,
);

const hideButtonIcon = computed(
  () => hideLabelAtBreakpoint && BUTTON_ICON_BREAKPOINTS[hideLabelAtBreakpoint],
);

const hideTextButton = computed(
  () =>
    hideLabelAtBreakpoint &&
    BUTTON_TEXT_BUTTON_BREAKPOINTS[hideLabelAtBreakpoint],
);

const tag = computed(() => {
  if (disabled || loading || to) return 'button';

  if (href) return 'a';

  return 'button';
});

const classesByState = computed(() => {
  if (isDisabled.value) {
    return `${variant} btn-disabled`;
  }

  if (isLoading.value) {
    return 'btn-loading';
  }

  const btnClass = danger
    ? `g2-btn ${variant} btn-danger`
    : `g2-btn ${variant}`;

  return ['btn-warning', 'btn-tertiary', 'btn-white'].includes(variant)
    ? btnClass
    : `${btnClass} shadow-sm hover:shadow-lg`;
});

const buttonSize = computed(() => {
  const buttonIconSize = BUTTON_ICON_SIZE[size];

  if (hideLabelAtBreakpoint && isTextButton.value) {
    const buttonTextWidth =
      BUTTON_TEXT_WIDTH_BREAKPOINTS[hideLabelAtBreakpoint];
    const buttonTextSize =
      BUTTON_TEXT_SIZE_MAP[size]?.[hideLabelAtBreakpoint] || '';

    return `${buttonIconSize} ${buttonTextSize} ${buttonTextWidth}`;
  }

  if (isTextButton.value) {
    const buttonTextSize = BUTTON_TEXT_SIZE[size];

    return `min-w-[62px] ${buttonTextSize}`;
  }

  return buttonIconSize;
});

const showTooltip = (show: () => void) => {
  if (tooltipDescription) {
    show();
  }
};

const hideTooltip = (hide: () => void) => {
  if (tooltipDescription) {
    hide();
  }
};

const onClick = () => {
  if (disabled || loading) return;

  if (to) {
    navigate();
  }

  emit('click');
};
</script>

<template>
  <Tooltip :description="tooltipDescription" placement="bottom">
    <template #trigger="{ show, hide, setRef }">
      <component
        v-bind="$attrs"
        :ref="setRef"
        :is="tag"
        :to="to"
        :target="target"
        :href="href"
        v-tooltip="description"
        :type="type"
        :data-test="dataTest"
        :disabled="disabled"
        @click="onClick"
        @mouseover="showTooltip(show)"
        @mouseleave="hideTooltip(hide)"
        class="flex-center gap-x-2 text-button-1 uppercase tracking-widest focus:outline-none focus-visible:ring-2 focus-visible:ring-ocean-3"
        :class="[
          classesByState,
          buttonSize,
          roundedFull ? 'rounded-full' : 'rounded-lg',
        ]"
      >
        <Icon v-if="loading" icon="mdi:loading" class="animate-spin" />

        <template v-else>
          <Icon
            v-if="icon && isTextButton"
            :class="[hideTextButton]"
            :icon="icon"
            :size="iconSize"
          />

          <Icon
            v-if="(!isTextButton || hideLabelAtBreakpoint) && icon"
            data-testid="button-icon"
            :class="[hideButtonIcon]"
            :icon="icon!"
            :size="iconSize"
          />
        </template>

        <span v-if="isTextButton" :class="[hideTextButton]">
          <slot>{{ label }}</slot>
        </span>

        <div
          v-if="slots.rightIcon"
          class="ml-1 flex justify-center"
          :class="[hideTextButton]"
        >
          <slot name="rightIcon" />
        </div>
      </component>
    </template>
  </Tooltip>
</template>

<style scoped>
.btn-primary {
  --btn-background: theme('colors.primary.8');
  --btn-foreground: theme('colors.white');
  --btn-background-hover: theme('colors.primary.6');
  --btn-foreground-hover: theme('colors.white');

  --danger-background: theme('colors.red.6');
  --danger-foreground: theme('colors.white');
  --danger-foreground-hover: theme('colors.white');
  --danger-background-hover: theme('colors.red.4');

  --disabled-background: theme('colors.charcoal.4');
  --disabled-foreground: theme('colors.white');
}

.btn-secondary {
  --btn-background: theme('colors.white');
  --btn-foreground: theme('colors.primary.8');
  --btn-background-hover: theme('colors.white');
  --btn-foreground-hover: theme('colors.ocean.6');

  --danger-background: theme('colors.white');
  --danger-foreground: theme('colors.red.6');
  --danger-foreground-hover: theme('colors.red.5');
  --danger-background-hover: theme('colors.white');

  --disabled-background: theme('colors.white');
  --disabled-foreground: theme('colors.charcoal.5');
}

.btn-tertiary {
  --btn-background: transparent;
  --btn-foreground: theme('colors.primary.8');
  --btn-background-hover: theme('colors.charcoal.1');
  --btn-foreground-hover: theme('colors.primary.6');

  --danger-background: transparent;
  --danger-foreground: theme('colors.red.6');
  --danger-foreground-hover: theme('colors.red.5');
  --danger-background-hover: theme('colors.red.0');

  --disabled-background: transparent;
  --disabled-foreground: theme('colors.charcoal.5');
}

.btn-warning {
  --btn-background: transparent;
  --btn-foreground: theme('colors.orange.3');
  --btn-foreground-hover: theme('colors.orange.7');
}

.btn-white {
  --btn-background: transparent;
  --btn-foreground: theme('colors.white');
  --btn-foreground-hover: theme('colors.primary.8');
}

.g2-btn {
  background-color: var(--btn-background);
  color: var(--btn-foreground);
}

.g2-btn:hover {
  background-color: var(--btn-background-hover);
  color: var(--btn-foreground-hover);
}

.g2-btn.btn-danger {
  --btn-background: var(--danger-background);
  color: var(--danger-foreground);
}

.g2-btn:hover.btn-danger {
  color: var(--danger-foreground-hover);
  --btn-background-hover: var(--danger-background-hover);
}

.g2-btn:focus.btn-danger {
  background-color: var(--danger-background);
  color: var(--danger-foreground);
}

.btn-disabled {
  cursor: not-allowed;
  background-color: var(--disabled-background);
  color: var(--disabled-foreground);
}

.btn-disabled:hover {
  background-color: var(--disabled-background);
}

.btn-loading {
  animation: pulse 2s linear infinite;
  pointer-events: none;
  background-color: theme('colors.charcoal.2');
  color: theme('colors.charcoal.2');
}
</style>
