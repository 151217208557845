<script setup name="UserForm">
import { computed, reactive } from 'vue';
import { useVModel } from '@vueuse/core';

import Field from '~/components/Field.vue';

const props = defineProps({
  modelValue: {
    type: [Number, String, Object, Array, Date, Boolean],
    default: null,
  },
  meta: {
    type: Object,
    default: () => {},
  },
  isCreating: {
    type: Boolean,
    default: false,
  },
  emailVerified: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const form = useVModel(props, 'modelValue', emit);

const schema = reactive({
  firstName: {
    name: 'first_name',
    label: 'First Name',
    rules: 'required|min:2|max:128',
    component: 'FieldText',
    autocomplete: 'first_name',
    disabled: !props.isCreating,
  },
  lastName: {
    name: 'last_name',
    label: 'Last Name',
    rules: 'required|min:2|max:128',
    component: 'FieldText',
    autocomplete: 'last_name',
    disabled: !props.isCreating,
  },
  email: {
    name: 'email',
    label: 'Email',
    rules: computed(() => {
      const baseRules = 'required|email|g2o_email|max:128';
      return props.isCreating ? `${baseRules}|unique_email` : baseRules;
    }),
    component: 'FieldText',
    autocomplete: 'email',
    disabled: !props.isCreating,
    trim: true,
  },
  role: {
    name: 'role_id',
    label: 'User Role',
    component: 'FieldSingleSelect',
    rules: 'required',
    disabled: computed(() => !props.meta?.roles?.length),
    options: computed(() => props.meta?.roles || []),
  },
});
</script>

<template>
  <div class="space-y-4">
    <template v-if="emailVerified && !isCreating">
      <Field v-model="form.firstName" :field="schema.firstName" />
      <Field v-model="form.lastName" :field="schema.lastName" />
    </template>
    <Field v-model="form.email" :field="schema.email" />
    <Field v-model="form.role" :field="schema.role" />
  </div>
</template>
