import { computed, type Ref, ref } from 'vue';

type NumericValueType = string | number | null;

export const useNumericField = (
  inputValue: Ref<NumericValueType>,
  format: boolean,
) => {
  const addThousandsSeparator = (str: string): string => {
    return str
      .split('')
      .reverse()
      .join('')
      .replace(/(\d{3})(?=\d)/g, '$1,')
      .split('')
      .reverse()
      .join('');
  };

  const visualValue = computed<number | string | null>(() => {
    if (format) {
      return addThousandsSeparator(inputValue.value?.toString() ?? '');
    }
    return inputValue.value;
  });

  const showVisualValue = ref(true);

  const numericValue = computed<NumericValueType>({
    get() {
      return showVisualValue.value ? visualValue.value : inputValue.value;
    },
    set(value: NumericValueType) {
      inputValue.value = value;
    },
  });

  const onInput = (event: KeyboardEvent) => {
    const key = Number(event.key);

    if (key >= 0 || key <= 9 || event.key === '.') {
      const element = event.currentTarget as HTMLInputElement;
      inputValue.value = element.value;

      return;
    }

    event.preventDefault();
  };

  const onBlur = (cb?: () => void) => {
    showVisualValue.value = true;
    if (cb) {
      cb();
    }
  };

  const onFocus = (cb?: () => void) => {
    showVisualValue.value = false;
    if (cb) {
      cb();
    }
  };

  return {
    numericValue,
    onInput,
    onBlur,
    onFocus,
  };
};
