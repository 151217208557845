<script setup lang="ts" name="AdminAcceptUserOrganisationLink">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import { BASE_TAG_CONTRAST } from '~/constants';

import BaseTag from '~/components/BaseTag.vue';
import Field from '~/components/Field.vue';

import type { IAdminRequestedUser, IFormField, IOption } from '~/types';

const props = defineProps<{
  field: IFormField;
  modelValue: IOption | null;
  user?: IAdminRequestedUser;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: IOption): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const title = computed(() =>
  props.user?.organisationExists
    ? 'We found user’s Organisation with the exact name of '
    : 'We did not find user’s Organisation with exact name of ',
);

const tagVariant = computed(() =>
  props.user?.organisationExists
    ? BASE_TAG_CONTRAST.GREEN_1
    : BASE_TAG_CONTRAST.CHARCOAL_1,
);

const organisation = computed(() => props.user?.organisations[0] || '-');
</script>

<template>
  <div class="rounded bg-white px-7 pb-16 pt-7 shadow">
    <div class="mb-2 flex items-center gap-2">
      <div class="text-subtitle-1 text-charcoal-9">
        {{ title }}
      </div>

      <BaseTag :name="organisation" :variant="tagVariant" />
    </div>

    <p class="text-subtitle-1 text-charcoal-9">
      Select or Create New Organisation
    </p>

    <p class="mb-6 mt-4 text-body-1">
      Please select existing Organisation from the dropdown or create a new one.
    </p>

    <div class="max-w-xs">
      <Field v-model="inputValue" :field="field" />
    </div>
  </div>
</template>
