import type { RouteLocationNamedRaw } from 'vue-router';

import type { AdminUserCreateForm, AdminUserEditForm } from '~/types';

export const getAdminEditForm = (): AdminUserEditForm => ({
  firstName: '',
  lastName: '',
  email: '',
  role: null,
  organisations: [],
});

export const getAdminEditFormData = ({
  role,
  organisations,
}: AdminUserEditForm) => ({
  role_id: role?.id,
  organisations: organisations?.map((x) => x.id).sort() || [],
});

export const getAdminEditSuccessPage = (
  name: string,
  path: RouteLocationNamedRaw,
) => ({
  title: 'Thank you!',
  subTitle: 'You Have Successfully Updated User Information',
  buttonText: name,
  path,
});

export const getAdminCreateForm = (): AdminUserCreateForm => ({
  email: '',
  role: null,
  organisations: [],
});

export const getAdminCreateFormData = ({
  email,
  role,
  organisations,
}: AdminUserCreateForm) => ({
  email,
  role_id: role?.id,
  organisations: organisations.map((x) => x.id).sort() || [],
});

export const getAdminCreateSuccessPage = (
  name: string,
  path: RouteLocationNamedRaw,
) => ({
  title: 'Thank you!',
  subTitle: 'You Have Successfully Created New User.',
  buttonText: name,
  path,
});
