<script lang="ts" setup name="FieldAcceptInvitation">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import BaseCheckbox from '~/components/BaseCheckbox.vue';
import FieldBaseErrors from '~/components/FieldBaseErrors.vue';

import type { IFormField } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    field: IFormField;
    errors?: string[];
  }>(),
  {
    modelValue: false,
    errors: () => [],
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'change'): void;
  (e: 'blur'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const TermsOfAgreementErrors = computed(() =>
  props.errors?.length > 0
    ? ['You must accept the Privacy Notice and Terms & Conditions to register.']
    : [],
);
</script>

<template>
  <div>
    <BaseCheckbox
      v-model="inputValue"
      :name="field.name"
      data-test="accept-invitation-checkbox"
      @change="emit('change')"
      @blur="emit('blur')"
    >
      <div data-test="aggreement-terms" class="text-charcoal-6">
        I hereby confirm that I have read and understood the
        <a
          class="text-primary-8"
          href="https://www.g2ocean.com/corporate-responsibility/privacy-notice/"
          target="_blank"
          data-test="privacy-notes"
          rel="noopener noreferrer"
          @click.stop
          @keyup.stop
        >
          Privacy Notice
        </a>
        and accept the
        <a
          class="text-primary-8"
          href="/pdfs/TermsandConditionsMyG2.pdf"
          target="_blank"
          data-test="terms-conditions"
          rel="noopener noreferrer"
          @click.stop
          @keyup.stop
        >
          Terms & Conditions
        </a>
        for the service.
      </div>
    </BaseCheckbox>
    <FieldBaseErrors
      v-if="TermsOfAgreementErrors.length > 0"
      class="mt-1"
      :errors="TermsOfAgreementErrors"
      :data-test="`${field.name}-error`"
    />
  </div>
</template>
