import { type InjectionKey, provide, type Ref, ref } from 'vue';

import { useStrictInject } from './useStrictInject';

export type TimestampType = number | null;

interface TimeSinceLastSync {
  days: number;
  hours: number;
  minutes: number;
}

export const LastSyncKey: InjectionKey<{
  lastUpdated: Ref<TimestampType>;
  setLastUpdated: (timestamp?: TimestampType) => void;
}> = Symbol('LastSync');

export const injectLastSync = () => useStrictInject(LastSyncKey);

const pluralizeTimeUnit = (
  value: number,
  singular: string,
  plural: string,
): string => (value === 1 ? singular : plural);

const formatTimeSinceLastSync = ({
  days,
  hours,
  minutes,
}: TimeSinceLastSync): string => {
  const dayText = pluralizeTimeUnit(days, 'day', 'days');
  const hourText = pluralizeTimeUnit(hours, 'hour', 'hours');
  const minuteText = pluralizeTimeUnit(minutes, 'minute', 'minutes');

  const formattedTimeUnits = [
    days > 0 ? `${days} ${dayText}` : null,
    hours > 0 ? `${hours} ${hourText}` : null,
    minutes > 0 ? `${minutes} ${minuteText}` : null,
  ].filter((unit) => unit);

  return `Last update: ${formattedTimeUnits.join(' ')} ago`;
};

const calculateTotalUnits = (
  currentTimestamp: number,
  lastSyncTimestamp: number,
) => {
  const totalSeconds = currentTimestamp - lastSyncTimestamp;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const totalDays = Math.floor(totalHours / 24);
  return { totalDays, totalHours, totalMinutes };
};

export const calculateTimeSinceLastSync = (
  timestamp: number,
): TimeSinceLastSync => {
  const currentTimestamp = Math.floor(new Date().getTime() / 1000);
  const { totalDays, totalHours, totalMinutes } = calculateTotalUnits(
    currentTimestamp,
    timestamp,
  );

  const days = totalDays;
  const hours = totalHours - days * 24;
  let minutes = totalMinutes - totalHours * 60;

  if (days === 0 && hours === 0 && minutes === 0) {
    minutes = 1;
  }

  return { days, hours, minutes };
};

export const useLastSync = (timestamp?: TimestampType) => {
  const formattedTime = ref<string>('');

  if (!timestamp) {
    return { formattedTime };
  }

  const calculateAndFormatTimeSinceLastSync = () => {
    const timeSinceLastSync = calculateTimeSinceLastSync(timestamp);
    formattedTime.value = formatTimeSinceLastSync(timeSinceLastSync);
  };

  calculateAndFormatTimeSinceLastSync();

  return { formattedTime };
};

export const useLastUpdated = (provideLastSync?: boolean) => {
  const lastUpdated = ref<TimestampType>(null);

  const setLastUpdated = (timestamp?: TimestampType) => {
    if (lastUpdated.value || !timestamp) {
      return;
    }

    lastUpdated.value = timestamp;
  };

  if (provideLastSync) {
    provide(LastSyncKey, { lastUpdated, setLastUpdated });
  }

  return {
    lastUpdated,
    setLastUpdated,
  };
};
