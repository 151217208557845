<script setup lang="ts" name="FormWizardFooter">
import { computed } from 'vue';

import BaseButton from '~/components/BaseButton.vue';
import Icon from '~/components/Icon.vue';
import StepperFooterLayout from '~/components/StepperFooterLayout.vue';

const {
  totalSteps,
  activeStepIdx,
  submit = 'Submit',
} = defineProps<{
  totalSteps: number;
  activeStepIdx: number;
  submit?: string;
  onPreviousStep: () => void;
}>();

const hasPreviousStep = computed(() => activeStepIdx > 0);
const hasNextStep = computed(() => activeStepIdx < totalSteps - 1);
const nextLabel = computed(() => (hasNextStep.value ? 'Next' : submit));
</script>

<template>
  <StepperFooterLayout>
    <BaseButton
      v-if="hasPreviousStep"
      variant="btn-secondary"
      @click="onPreviousStep"
    >
      back
    </BaseButton>

    <BaseButton type="submit">
      <p>{{ nextLabel }}</p>
      <template v-if="hasNextStep" #rightIcon>
        <Icon icon="mdi:chevron-right" />
      </template>
    </BaseButton>
  </StepperFooterLayout>
</template>
