import type { RouteLocationNormalized } from 'vue-router';

import {
  GA_EVENT_ACTIONS,
  GA_STATISTICS_PAGES,
  PAGE_NAME_TO_TITLE,
} from '~/constants';
import { useAnalyticsStore } from '~/store';

import type {
  IGaAction,
  IGaOptions,
  IGaStatisticsPage,
  IOrganisationCustomerUser,
  IOrganisationUser,
  IPeriodName,
} from '~/types';

function sendEvent(action: IGaAction, options: IGaOptions) {
  if (!useAnalyticsStore().googleIsEnabled) {
    return;
  }
  if (window.gtag === undefined) {
    return;
  }
  window.gtag('event', GA_EVENT_ACTIONS[action], options);
}

const sendPageViewEvent = (options: IGaOptions) =>
  sendEvent('PAGE_VIEW', options);

interface IPageViewParams {
  to: RouteLocationNormalized;
  isAuthenticated: boolean;
  isG2OEmployee: boolean;
  isPublicUser: boolean;
  name?: string;
}

export const useGooglePageView = ({
  to,
  isAuthenticated,
  isG2OEmployee,
  isPublicUser,
  name,
}: IPageViewParams): void => {
  const options = {
    page_title:
      PAGE_NAME_TO_TITLE[to.name as keyof typeof PAGE_NAME_TO_TITLE] || to.name,
    page_path: to.path,
    organisationName: 'Not Logged In',
  };

  if (!isAuthenticated) {
    sendPageViewEvent(options);
    return;
  }

  const _getOrganisationName = () => {
    if (isG2OEmployee) {
      return 'G2Ocean';
    }

    if (isPublicUser) {
      return 'Public User';
    }

    if (name) {
      return name;
    }

    return 'G2Ocean';
  };

  sendPageViewEvent({
    ...options,
    organisationName: _getOrganisationName(),
  });
};

export const sendDateFilterEvent = (
  name: IPeriodName,
  page: IGaStatisticsPage,
) => {
  const pageTitle = GA_STATISTICS_PAGES[page].name;
  const label = `${pageTitle}: ${name}`;

  sendEvent('DATE_FILTER', {
    event_category: 'statistics',
    event_label: label,
  });
};

export const sendShipmentsFilter = (
  name: 'Upcoming' | 'Ongoing' | 'Completed' | 'All',
) => {
  const label = `Shipments: ${name}`;

  sendEvent('SHIPMENT_STATUS', {
    event_category: 'shipments',
    event_label: label,
  });
};

export const sendShipmentSharedEvent = () => {
  sendEvent('SHIPMENT_SHARED', {
    event_category: 'shipment',
    event_label: 'Shipment: Shared',
  });
};

export const sendInviteUserEvent = (area: 'Organisation' | 'Home') => {
  sendEvent('INVITE_USER', {
    event_category: 'organisation',
    event_label: `Invite User: ${area}`,
  });
};

export const sendSailingScheduleCustomisedEvent = () => {
  sendEvent('SAILING_SCHEDULE_CUSTOMIZED', {
    event_category: 'Sailing Schedule',
    event_label: 'Sailing Schedule: Customised',
  });
};

export const sendManageOffsetEvent = () => {
  sendEvent('MANAGE_OFFSET', {
    event_category: 'Carbon Offset',
    event_label: 'Carbon Offset: Manage Offset',
  });
};

export const sendCreateOrganisationEvent = ({
  users,
  customerUsers,
}: {
  users: IOrganisationUser[];
  customerUsers: IOrganisationCustomerUser[];
}) => {
  sendEvent('CREATE_ORGANISATION_CUSTOMER_USERS', {
    event_category: 'Organisation',
    event_label: 'Organisation: Create: Customers users added',
    value: customerUsers.length,
  });
  sendEvent('CREATE_ORGANISATION_G2O_USERS', {
    event_category: 'Organisation',
    event_label: 'Organisation: Create: G2O users added',
    value: users.length,
  });
};
