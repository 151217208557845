<script setup lang="ts" name="FieldMultipleEmails">
import { computed, watch } from 'vue';
import { useVModel } from '@vueuse/core';
import { useField, useForm } from 'vee-validate';

import FieldBaseErrors from '~/components/FieldBaseErrors.vue';
import FieldBaseInput from '~/components/FieldBaseInput.vue';
import FilterTag from '~/components/FilterTag.vue';

const props = defineProps<{
  modelValue: string[];
  field: {
    name: string;
    label: string;
    disabled?: boolean;
    emails: string[];
  };
  errors: string[];
}>();

const emit = defineEmits(['update:modelValue']);

const { validateField } = useForm();

const emails = useVModel(props, 'modelValue', emit);

const existingEmails = computed(() => [...emails.value, ...props.field.emails]);

const emailRules = computed(() => {
  return `email|max:128|unique_shared_email:${existingEmails.value}`;
});

const fieldName = 'multiple_emails_email';

const { value, errors, resetField, setErrors } = useField<string>(
  fieldName,
  emailRules,
  {
    label: 'Email',
    validateOnValueUpdate: false,
    syncVModel: false,
  },
);

const removeEmail = (email: string) => {
  emails.value = emails.value.filter((e) => e !== email);
};

const addEmail = async () => {
  const { valid } = await validateField(fieldName);

  if (!valid || !value.value) {
    return;
  }

  emails.value = [...emails.value, value.value];
  value.value = '';
  resetField();
};

const onKeyDown = (event: KeyboardEvent) => {
  if (['Enter', 'Tab', ','].includes(event.key)) {
    event.preventDefault();
    addEmail();
  }
};

watch(
  () => props.errors,
  (val) => {
    if (!errors.value.length) {
      setErrors(val);
    }
  },
  { deep: true, immediate: true },
);
</script>

<template>
  <div>
    <div
      v-if="emails.length"
      class="max-h-32 overflow-y-auto bg-white p-2 leading-normal"
    >
      <div class="flex flex-wrap gap-2">
        <FilterTag
          v-for="email in emails"
          :key="email"
          :name="email"
          :disabled="field.disabled"
          @click="removeEmail(email)"
        />
      </div>
    </div>

    <div :data-test="`${fieldName}-wrapper`">
      <FieldBaseInput
        v-model="value"
        class="w-full"
        trim
        placeholder="Enter email, comma separated"
        :name="field.name"
        :label="field.label"
        :has-errors="errors.length > 0"
        :floating-label="false"
        :disabled="field.disabled"
        @blur="addEmail"
        @keydown="onKeyDown"
      />

      <FieldBaseErrors
        v-if="errors.length > 0"
        class="mt-1"
        :errors="errors"
        :data-test="`${field.name}-error`"
      />
    </div>
  </div>
</template>
