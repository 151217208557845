import type { RemovableRef } from '@vueuse/core';
import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

import { LOCAL_STORAGE_KEYS, PINIA_STORE } from '~/constants';
import { useProfileStore } from '~/store';

import { useResetStore } from '~/features/useResetStore.js';
import { setSailingScheduleDateType } from '~/features/useSailingScheduleFilters';

import Service from '~/services/Service';

interface IAuthStore {
  accessToken: RemovableRef<string | null>;
  showLoggedOutMessage: boolean;
}

const setAxiosToken = (token?: string) => {
  window.axios.defaults.headers.common.Authorization = token;
};

const cleanupLocalStorage = () => {
  setSailingScheduleDateType(null);
};

const resetAllStores = () => {
  const storeResets = useResetStore();
  Object.values(PINIA_STORE).forEach((key) => {
    if (key === PINIA_STORE.AUTH_STORE) {
      return;
    }

    const reset = storeResets[key];
    if (reset) {
      reset();
    }
  });
};

export const useAuthStore = defineStore(PINIA_STORE.AUTH_STORE, {
  state: (): IAuthStore => ({
    accessToken: useLocalStorage<string | null>(
      LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
      null,
    ),
    showLoggedOutMessage: false,
  }),
  actions: {
    setAuth(accessToken: string): void {
      const token = `Bearer ${accessToken}`;
      this.accessToken = token;

      setAxiosToken(token);
    },
    async login(accessToken: string): Promise<void> {
      this.setAuth(accessToken);
      return useProfileStore().fetchProfile();
    },
    async logout(): Promise<void> {
      this.accessToken = null;
      resetAllStores();
      cleanupLocalStorage();
      this.showLoggedOutMessage = true;
      this.$router.push('/login');

      return new Promise<void>((resolve) => {
        Service.auth()
          .logout()
          .onError(() => {
            setAxiosToken();
            resolve();
          })
          .onFinish(() => {
            setAxiosToken();
            resolve();
          })
          .execute();
      });
    },
    async logout401(): Promise<void> {
      this.accessToken = null;
      setAxiosToken();
      resetAllStores();
      this.showLoggedOutMessage = true;
      await this.$router.push('/login');
    },
  },
});
