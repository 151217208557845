import type { Ref } from 'vue';

import type { IAddressManager } from './useDataTableSelectedCell';

export type HandleCellClick = (address: string) => void;
export type HandleCellDoubleClick = (address: string) => void;

export const useDataTableEventHandlers = ({
  getTableId,
  toggleEditMode,
  getTableLength,
  getNumberOfCellsInRow,
  addressManager,
  editable,
  resetStepCells,
  isRowCancelled,
  tableRef,
}: {
  editable: boolean;
  getTableId;
  toggleEditMode;
  getTableLength;
  getNumberOfCellsInRow;
  addressManager: IAddressManager;
  resetStepCells: () => void;
  isRowCancelled: (rowNumber: number) => boolean;
  tableRef?: Ref<HTMLElement | undefined>;
}) => {
  let isScrollbarClicked = false;
  const handleCellClick = (address: string) => {
    if (!editable) {
      return;
    }

    resetStepCells();
    addressManager.selectAddress(address);
  };

  const handleCellDoubleClick = (address: string) => {
    const currentAddress = addressManager.parseAddress(address);

    if (!editable || isRowCancelled(currentAddress.rowNumber)) {
      return;
    }

    resetStepCells();
    toggleEditMode(currentAddress);
  };

  const handleGridFocus = () => {
    if (!editable || isScrollbarClicked) {
      return;
    }
    addressManager.focusCell(`A1-${getTableId()}`);
  };

  const handleGridBlur = () => {
    if (!editable || isScrollbarClicked) {
      return;
    }

    addressManager.selectAddress(`A1-${getTableId()}`);
  };

  const moveUp = (currentRow: number, currentCol: number) => {
    return { newRow: Math.max(0, currentRow - 1), newCol: currentCol };
  };

  const moveDown = (currentRow: number, currentCol: number) => {
    return { newRow: currentRow + 1, newCol: currentCol };
  };

  const moveLeft = (currentRow: number, currentCol: number) => {
    const newCol = Math.max(0, currentCol - 1);

    if (currentCol === 0 && currentRow > 0) {
      const newRow = currentRow - 1;
      const lastColOfPrevRow = getNumberOfCellsInRow(newRow);

      return {
        newRow: newRow,
        newCol: lastColOfPrevRow - 1,
      };
    }

    return { newRow: currentRow, newCol: newCol };
  };

  const moveRightOrTab = ({ rowNumber, colNumber }) => {
    const newCol = colNumber + 1;

    const isLastCellInCurrentRow = newCol === getNumberOfCellsInRow(rowNumber);

    const isLastCellInGrid =
      rowNumber + 1 === getTableLength() && isLastCellInCurrentRow;

    if (isLastCellInGrid) {
      return { newRow: rowNumber, newCol: colNumber };
    }

    if (isLastCellInCurrentRow) {
      return { newRow: rowNumber + 1, newCol: 0 };
    }

    return { newRow: rowNumber, newCol: newCol };
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const { rowNumber, colNumber } =
      addressManager.getRowAndColNumbersFromAddress();

    let { newRow, newCol } = { newRow: rowNumber, newCol: colNumber };
    let shouldScroll = false; // Variable to determine if scrolling is required

    switch (event.key) {
      case 'ArrowUp':
        ({ newRow, newCol } = moveUp(rowNumber, colNumber));
        shouldScroll = true;
        break;
      case 'ArrowDown':
        ({ newRow, newCol } = moveDown(rowNumber, colNumber));
        shouldScroll = true;
        break;
      case 'ArrowLeft':
        ({ newRow, newCol } = moveLeft(rowNumber, colNumber));
        shouldScroll = true;
        break;
      case 'ArrowRight':
      case 'Tab':
        ({ newRow, newCol } = moveRightOrTab({ rowNumber, colNumber }));
        event.preventDefault();
        shouldScroll = true;
        break;
      case 'Enter': {
        if (!editable || isRowCancelled(rowNumber)) return;

        toggleEditMode({ rowNumber, colNumber });
        event.preventDefault();
        break;
      }
    }

    const isValidRow = newRow < getTableLength();
    const isValidColumn = newCol < getNumberOfCellsInRow(newRow);

    if (isValidRow && isValidColumn) {
      const newAddress = addressManager.createAddress(newCol, newRow);
      addressManager.selectAddress(newAddress);

      if (shouldScroll) {
        addressManager.focusAndScrollToCell(newAddress);
      } else {
        addressManager.focusCell(newAddress);
      }
    }
  };

  const handleMousedown = (event: MouseEvent) => {
    if (event.target === tableRef?.value) {
      isScrollbarClicked = true;
      return;
    }
    isScrollbarClicked = false;
  };

  const gridEvents = {
    keydown: handleKeyDown,
    blur: handleGridBlur,
    focus: handleGridFocus,
    mousedown: handleMousedown,
  };

  return {
    gridEvents,
    handleCellClick,
    handleCellDoubleClick,
    moveRightOrTab,
  };
};
