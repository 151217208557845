<script setup lang="ts" name="StepperHeaderBar">
import { computed } from 'vue';

import type { StepperPositionType } from '~/types';
const { position } = defineProps<{
  isCompleted?: boolean;
  position?: StepperPositionType;
  hasTitles?: boolean;
}>();

const positionIs = computed(
  () => (type: StepperPositionType) => type === position,
);
</script>

<template>
  <div
    class="flex-auto transition duration-1000 ease-linear"
    :class="{
      'border-primary-6': isCompleted,
      'border-charcoal-4': !isCompleted,
      'border-t': positionIs('horizontal'),
      'border-l': positionIs('vertical'),
      'ml-3': positionIs('vertical') && hasTitles,
    }"
  />
</template>
