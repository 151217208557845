import { isRef } from 'vue';
import type { MaybeRef } from '@vueuse/shared';

const isItANumber = (str: string) =>
  /^-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(str);

export const formatNumber = (
  refValue: MaybeRef<string | number | null>,
  minimumFractionDigits = 0,
) => {
  const value = isRef(refValue) ? refValue.value : refValue;

  if (typeof value === 'string' && !isItANumber(value)) {
    return value;
  }

  if (value == null) {
    return null;
  }

  const number = Number.parseFloat(String(value));

  if (Number.isNaN(number)) {
    return value;
  }

  return number.toLocaleString('en-US', {
    minimumFractionDigits,
  });
};

export const kNumberFormatter = (
  value: number | string | null,
  minimumFractionDigits = 1,
  maximumFractionDigits = 3,
) => {
  if (value == null) {
    return null;
  }

  const num = Number.parseFloat(String(value));

  if (Number.isNaN(num)) {
    throw new Error(`Invalid input: ${value} is not a number`);
  }

  const total = Math.abs(num);
  const number = (Math.sign(num) * (total / 1000)).toFixed(1);

  return total > 999
    ? `${Number(number).toLocaleString('en-US', {
        minimumFractionDigits,
        maximumFractionDigits,
      })}k`
    : (Math.sign(num) * total).toFixed();
};
