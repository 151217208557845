<script lang="ts" setup name="ServiceCard">
import BaseCardLoading from '~/components/BaseCardLoading.vue';
import BaseCardNoData from '~/components/BaseCardNoData.vue';
import BaseCardNoResults from '~/components/BaseCardNoResults.vue';
import BaseCardNotAuthorized from '~/components/BaseCardNotAuthorized.vue';
import BaseCardNotAvailable from '~/components/BaseCardNotAvailable.vue';
import BaseCardServerError from '~/components/BaseCardServerError.vue';
import ServiceState from '~/components/ServiceState.vue';

import type { IllustrationType } from '~/types';
import type { ServiceLoadingType } from '~/types/serviceState';

const props = withDefaults(
  defineProps<{
    styled?: boolean;
    state?: string;
    loading?: ServiceLoadingType;
    stateIsLoading?: boolean;
    stateIsError?: boolean;
    stateIsNotAuthorized?: boolean;
    stateIsNoData?: boolean;
    stateIsNoResults?: boolean;
    stateIsNotAvailable?: boolean;
    stateIsValidationError?: boolean;
    stateIsIdle?: boolean;
    stateHasErrors?: boolean;
    noResultsMessage?: string;
    noResultsButton?: string;
    noDataTitle?: string;
    noDataMessage?: string;
    noDataIcon?: IllustrationType;
    notAvailableTitle?: string;
    notAvailableMessage?: string;
    authMessage?: string | null;
    authButton?: string | null;
    fetchDataDebounced?: () => void;
    fetchData?: () => void;
    setStateNotAuthorized?: () => void;
    setStateNoData?: () => void;
    setStateNoResults?: () => void;
    setStateLoading?: () => void;
    setStateError?: () => void;
    setStateIdle?: () => void;
  }>(),
  {
    state: '',
    noDataMessage: '',
    noDataIcon: 'no-data',
    noResultsButton: 'Clear all',
    noDataTitle: 'Sorry No Data Was Found',
    noResultsMessage:
      'Please amend the search criteria or clear search field to see all available items.',
  },
);

const emit = defineEmits(['clear']);
</script>

<template>
  <ServiceState
    :state="state"
    :loading="loading"
    :stateIsIdle="stateIsIdle"
    :stateIsError="stateIsError"
    :stateIsNoData="stateIsNoData"
    :stateIsLoading="stateIsLoading"
    :stateIsNoResults="stateIsNoResults"
    :stateIsNotAvailable="stateIsNotAvailable"
    :stateIsNotAuthorized="stateIsNotAuthorized"
    :stateIsValidationError="stateIsValidationError"
  >
    <template #loading>
      <slot name="loading">
        <BaseCardLoading class="col-span-full rounded bg-white drop-shadow" />
      </slot>
    </template>
    <template #validation-error>
      <slot name="validation-error" />
    </template>
    <template #error>
      <slot name="error">
        <BaseCardServerError
          :styled="props.styled"
          class="col-span-full"
          @retry="props.fetchData"
        />
      </slot>
    </template>
    <template #error-auth>
      <slot name="error-auth">
        <BaseCardNotAuthorized
          :styled="props.styled"
          class="col-span-full"
          :message="authMessage"
          :button="authButton"
        />
      </slot>
    </template>

    <template #no-results>
      <slot name="no-results">
        <BaseCardNoResults
          :styled="props.styled"
          class="col-span-full"
          :message="noResultsMessage"
          :button="noResultsButton"
          @clear="emit('clear')"
        />
      </slot>
    </template>

    <template #no-data>
      <slot name="no-data">
        <BaseCardNoData
          :styled="props.styled"
          :title="noDataTitle"
          class="col-span-full"
          :message="noDataMessage"
          :illustration="noDataIcon"
        >
          <div v-if="$slots.noDataAction" class="mt-4">
            <slot name="noDataAction" />
          </div>
        </BaseCardNoData>
      </slot>
    </template>
    <template #not-available>
      <slot name="not-available">
        <BaseCardNotAvailable
          :styled="props.styled"
          class="col-span-full"
          :title="notAvailableTitle"
          :message="notAvailableMessage"
        />
      </slot>
    </template>
    <slot />
  </ServiceState>
</template>
