import { computed, onMounted, type Ref, ref } from 'vue';
import { useResizeObserver } from '@vueuse/core';

import type { IconType } from '~/types';

interface ITabResize {
  label?: string;
  subLabel?: string;
  icon?: IconType;
  enabled?: boolean;
}

export const useTabsResize = <T extends ITabResize>(tabs: Ref<T[]>) => {
  const MIN_TAB_WIDTH = 80;
  const maxVisibleTabs = ref(tabs.value.length);
  const widestTabWidth = ref(MIN_TAB_WIDTH);
  const tabsWrapperRef = ref<HTMLElement>();

  const availableTabs = computed(() =>
    tabs.value.filter((tab) => tab.enabled !== false),
  );

  const visibleTabs = computed(() =>
    availableTabs.value.slice(0, maxVisibleTabs.value),
  );

  const hiddenTabs = computed(() =>
    availableTabs.value.slice(maxVisibleTabs.value),
  );

  const renderDummyNode = (tab: ITabResize): HTMLDivElement => {
    const div = document.createElement('div');
    div.style.setProperty('width', 'max-content');
    div.style.setProperty('padding-left', '4px');
    div.style.setProperty('padding-right', '4px');

    const label = tab.label ?? '';
    const subLabel = tab.subLabel ?? '';

    if (label.length >= subLabel.length) {
      div.innerText = label;
      div.style.setProperty('text-transform', 'uppercase');
    } else {
      div.innerText = subLabel;
      div.style.setProperty('text-transform', 'capitalize');
    }

    document.querySelector('body')?.appendChild(div);
    return div;
  };

  const calculateWidestTab = <T extends ITabResize>(tabs: T[]): number => {
    const nodes: HTMLDivElement[] = [];

    tabs.forEach((tab) => {
      if (!tab.label && !tab.subLabel) return;
      nodes.push(renderDummyNode(tab));
    });

    if (nodes.length === 0) {
      return MIN_TAB_WIDTH;
    }

    const maxWidth = Math.max(...nodes.map((node) => node.clientWidth));

    nodes.forEach((node) => node.remove());
    return maxWidth;
  };

  useResizeObserver(tabsWrapperRef, () => {
    const tabsSectionWidth = tabsWrapperRef.value!.clientWidth;
    let fullyVisible = Math.floor(tabsSectionWidth / widestTabWidth.value);

    const hasMultipleTabsHidden = fullyVisible < availableTabs.value.length;
    const remainingSpace =
      tabsSectionWidth - widestTabWidth.value * fullyVisible;

    if (remainingSpace <= MIN_TAB_WIDTH && hasMultipleTabsHidden) {
      fullyVisible--;
    }

    maxVisibleTabs.value = fullyVisible;
  });

  onMounted(() => {
    widestTabWidth.value = calculateWidestTab(availableTabs.value);
  });

  return {
    MIN_TAB_WIDTH,
    maxVisibleTabs,
    widestTabWidth,
    tabsWrapperRef,
    availableTabs,
    visibleTabs,
    hiddenTabs,
  };
};
