<script setup lang="ts" name="StepperHeader">
import { computed } from 'vue';

import StepperHeaderBar from '~/components/StepperHeaderBar.vue';
import StepperHeaderStep from '~/components/StepperHeaderStep.vue';

import type { IStepperTitle, StepperPositionType } from '~/types';

const {
  activeStepIdx,
  isLastStep,
  position = 'horizontal',
} = defineProps<{
  totalSteps: number;
  activeStepIdx: number;
  isLastStep: boolean;
  position?: StepperPositionType;
  titles?: IStepperTitle[];
}>();

const isCompleted = (step: number) => step - 1 < activeStepIdx || isLastStep;
const isActive = (step: number) => step - 1 === activeStepIdx && !isLastStep;
const positionIs = computed(
  () => (type: StepperPositionType) => type === position,
);
</script>

<template>
  <div
    class="flex"
    :class="{
      'h-full flex-col': positionIs('vertical'),
      'min-w-[144px]': titles,
      'items-center':
        positionIs('horizontal') || (positionIs('vertical') && !titles),
      'my-auto h-full': positionIs('horizontal') && !titles,
    }"
  >
    <template v-for="step in totalSteps" :key="step">
      <StepperHeaderStep
        :step="step"
        :position="position"
        :isCompleted="isCompleted(step)"
        :isActive="isActive(step)"
        v-bind="titles?.[step - 1]"
      />

      <StepperHeaderBar
        v-if="step < totalSteps"
        :isCompleted="isCompleted(step)"
        :position="position"
        :hasTitles="!!titles?.[step - 1]?.title"
      />
    </template>
  </div>
</template>
