import { reactive, ref } from 'vue';
import DOMPurify from 'dompurify';
import { defineStore } from 'pinia';

import { SNACKBAR_TIME } from '~/constants';

import type { ISnackbar, ISnackbarConfig } from '~/types';

export const useSnackbarStore = defineStore('snackbar', () => {
  const state = reactive<ISnackbar>({
    type: null,
    icon: null,
    title: null,
    text: null,
    html: null,
    visible: false,
    withTransition: false,
  });

  const timeout = ref<ReturnType<typeof setTimeout>>();

  const _closeSnackbar = (withTransition: boolean) => {
    state.type = null;
    state.icon = null;
    state.title = null;
    state.text = null;
    state.html = null;
    state.visible = false;
    state.withTransition = withTransition;
    clearTimeout(timeout.value);
  };

  const _removeSnackbar = () => {
    _closeSnackbar(true);
  };

  const _sanitizeHtml = (html: string) => DOMPurify.sanitize(html);

  const _createSnackbar = (config: ISnackbarConfig) => {
    if (config.html) {
      state.html = _sanitizeHtml(config.html);
    }

    state.type = config.type;
    state.title = config.title;
    state.text = config.text;
    state.icon = config.icon;

    state.visible = true;

    timeout.value = setTimeout(() => {
      _removeSnackbar();
    }, SNACKBAR_TIME);
  };

  const add = (config: ISnackbarConfig) => {
    if (state.visible) {
      remove();
    }

    _createSnackbar(config);
  };

  // Closes the snackbar immediately without a
  // transition on manual close or route change
  const remove = () => {
    _closeSnackbar(false);
  };

  return {
    state,

    add,
    remove,
  };
});
