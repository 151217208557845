<script setup lang="ts" name="FieldSingleSelectDate">
import { useVModel } from '@vueuse/core';

import DropdownDateOption from '~/components/DropdownDateOption.vue';
import FieldSingleSelect from '~/components/FieldSingleSelect.vue';

import type { IFieldSelectOption, IFormField } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue?: IFieldSelectOption;
    field: IFormField<IFieldSelectOption>;
    autofocus?: boolean;
    errors?: string[];
  }>(),
  {
    autofocus: false,
    errors: (): string[] => [],
  },
);

const emit = defineEmits<{
  (e: 'blur'): void;
  (e: 'change'): void;
  (e: 'update:modelValue'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <FieldSingleSelect
    v-model="inputValue"
    :field="field"
    :errors="errors"
    :autofocus="autofocus"
    @blur="emit('blur')"
    @change="emit('change')"
  >
    <template #item="{ item }">
      <DropdownDateOption :date="item.name" />
    </template>
  </FieldSingleSelect>
</template>
