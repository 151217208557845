<script lang="ts" setup name="UserDetails">
import { computed } from 'vue';

import { Item, useBaseItems } from '~/features/useBaseItem';
import { addObjectIf } from '~/features/useUtilities';

import BaseButton from '~/components/BaseButton.vue';
import BaseItem from '~/components/BaseItem.vue';

const {
  firstName,
  lastName,
  email,
  role,
  businessType,
  editable = false,
} = defineProps<{
  lastName?: string | null;
  firstName?: string | null;
  email?: string | null;
  role?: string | null;
  businessType?: string | null;
  editable?: boolean;
}>();

const emit = defineEmits<{
  (e: 'edit'): void;
}>();

const details = computed(() => {
  return useBaseItems(
    Item.label('First Name').value(firstName),
    Item.label('Last Name').value(lastName),
    ...addObjectIf(!!role, [Item.label('Role').value(role).capitalize()]),
    Item.label('Email').value(email),
    ...addObjectIf(!!businessType, [
      Item.label("Company's Business").value(businessType),
    ]),
  );
});
</script>

<template>
  <div>
    <div class="flex items-center">
      <p class="text-subtitle-1 text-charcoal-9">User Details</p>

      <BaseButton
        v-if="editable"
        icon="ic:baseline-edit"
        class="ml-auto"
        variant="btn-secondary"
        @click="emit('edit')"
      >
        Edit
      </BaseButton>
    </div>

    <div class="mt-6 grid grid-cols-2 gap-y-7 md:w-1/2">
      <BaseItem v-for="detail in details" v-bind="detail" :key="detail.id" />
    </div>
  </div>
</template>
