<script lang="ts" setup name="PaginationButton">
import Icon from '~/components/Icon.vue';

const { disabled = false, isNext = false } = defineProps<{
  disabled?: boolean;
  isNext?: boolean;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();
</script>

<template>
  <button
    v-bind="$attrs"
    type="button"
    :disabled="disabled"
    class="flex h-8 w-8 items-center justify-center rounded-full border border-solid bg-white"
    :class="{
      'border-charcoal-6 text-charcoal-6': disabled,
      'border-ocean-8 text-ocean-8 focus-visible:border-ocean-4': !disabled,
    }"
    @click.stop="emit('click')"
  >
    <Icon :icon="isNext ? 'mdi:chevron-right' : 'mdi:chevron-left'" />
  </button>
</template>
