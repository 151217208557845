import { getMonth, isLastDayOfMonth, subDays } from 'date-fns';

import { getDateYear } from '~/features/useDates';

import type { IDatePickerDisabledDates, IOption } from '~/types';

export type CargoBookingOccurrence = IOption & { date: Date };

const FEBRUARY = 1;
const OCCURRENCES = 6;

const getOccurrenceAndDisabledDates = (
  baseDate: Date,
  occurrences: Date[] = [],
): { occurrences: Date[] } => {
  if (occurrences.length >= OCCURRENCES) {
    return { occurrences };
  }

  const day = baseDate.getDate();
  if (day % 15 === 0 || (day < 30 && isLastDayOfMonth(baseDate))) {
    occurrences.unshift(baseDate);
  }

  const previousDate = subDays(baseDate, 1);

  return getOccurrenceAndDisabledDates(previousDate, occurrences);
};

const convertDatesToOccurrences = (occurrences: Date[]) => {
  const dates = [...occurrences].reverse();

  return dates.map((date) => ({
    date: date,
    name: getDateYear(date),
    id: getDateYear(date),
  }));
};

export const getCargoNominateOccurences = () => {
  const today = new Date();

  const { occurrences } = getOccurrenceAndDisabledDates(today);

  const lastOccurrence = occurrences[occurrences.length - 1];

  const lastSixOccurrences = convertDatesToOccurrences(occurrences);

  return {
    lastOccurrence,

    lastSixOccurrences,
  };
};

export const getCargoNominateDisabledDates = (): IDatePickerDisabledDates => ({
  start: null,
  end: null,
  repeat: {
    on: [
      (days) => {
        const month = getMonth(days.date);

        return !(
          days.day === 15 ||
          days.day === 30 ||
          (month === FEBRUARY && isLastDayOfMonth(days.date))
        );
      },
    ],
  },
});
