import { HOME_WIDGET_TYPE, TYPE_TO_STATISTICS_PAGE } from '~/constants';

import { formatNumber, kNumberFormatter } from '~/features/useNumbers';

import type {
  IFilledVlsfoPriceBunker,
  IHomeVlsfoWidgetResponse,
  IHomeWidget,
  IHomeWidgetResponse,
  IResponseVlsfoPriceBunker,
  OrganisationHomeWidgetsResponse,
} from '~/types';

const TRENDING_STATUS_TO_ICON = {
  rising: 'g2o:baseline-trending-up',
  stable: 'g2o:baseline-trending-flat',
  falling: 'g2o:baseline-trending-down',
};
const getPercentValue = (value: number) => (value ? `${value}%` : null);

const getVlsfoBunkers = (
  bunkers?: IResponseVlsfoPriceBunker[],
): IFilledVlsfoPriceBunker[] | undefined => {
  if (!bunkers) {
    return;
  }

  return bunkers.map((bunker) => ({
    ...bunker,
    value: bunker.value && formatNumber(bunker.value),
    description: 'USD/MT',
    icon: bunker.status && TRENDING_STATUS_TO_ICON[bunker.status],
  }));
};

const WIDGETS = {
  [HOME_WIDGET_TYPE.SHIPMENT_REVENUE_TONS]: (widget: IHomeWidgetResponse) => ({
    ...widget,
    title: 'Shipment Revenue Tons',
    subTitle: widget.quarter,
    value: kNumberFormatter(widget.value, 0, 0),
  }),
  [HOME_WIDGET_TYPE.INVOICES]: (widget: IHomeWidgetResponse) => ({
    ...widget,
    title: 'Invoices',
    subTitle: 'Total Overdue',
    description: 'USD',
    value: kNumberFormatter(widget.value, 0, 0),
  }),
  [HOME_WIDGET_TYPE.TOTAL_LAYCAN_PERFORMANCE]: (
    widget: IHomeWidgetResponse,
  ) => ({
    ...widget,
    title: 'Laycan Performance',
    subTitle: widget.quarter,
    value: getPercentValue(widget.value),
  }),
  [HOME_WIDGET_TYPE.TRANSIT_TIME]: (widget: IHomeWidgetResponse) => ({
    ...widget,
    title: widget.trade ? `Transit Time / ${widget.trade}` : 'Transit Time',
    subTitle: widget.quarter,
    description: 'days',
    tooltip:
      'The average time between departure from load port and arrival at discharge port. Results are grouped based on the departure date from the load port of the cargo.',
  }),
  [HOME_WIDGET_TYPE.TOTAL_CO2_EMISSIONS]: (widget: IHomeWidgetResponse) => ({
    ...widget,
    title: 'CO2 Emissions',
    subTitle: 'Year to Date',
    description: 'MT',
    tooltip:
      'The estimated Carbon Dioxide (CO2) emitted from burning fuel (VLSFO and LSMGO) from the transportation of cargo in the period selected. Fuel consumption is calculated using the fleet wide average Energy Efficiency Operational Indicator (or EEOI), adjusted for the distance covered by the vessel carrying the cargo and pro-rated to cargoes on the vessel',
    value: formatNumber(widget.value),
  }),
  [HOME_WIDGET_TYPE.OUTSTANDING_CLAIMS]: (widget: IHomeWidgetResponse) => ({
    ...widget,
    title: 'Number of Outstanding Claims',
    description: 'Claims',
    tooltip:
      'The number of outstanding claims are the claims that G2 Ocean have received that have not yet been resolved as of today',
    value: formatNumber(widget.value),
  }),
  [HOME_WIDGET_TYPE.VLSFO_PRICE]: (widget: IHomeVlsfoWidgetResponse) => ({
    ...widget,
    title: 'VLSFO Price',
    value: widget.hasData && widget.bunkers ? widget.bunkers.length : null,
    bunkers: getVlsfoBunkers(widget.bunkers),
  }),
  [HOME_WIDGET_TYPE.PORT_CALLS]: (widget: IHomeWidgetResponse) => ({
    ...widget,
    tooltip:
      'The total number of port calls in the period with either load or discharge operations',
    title: 'Productivity / Port Calls',
    subTitle: 'Last 12 Months',
    value: formatNumber(widget.value),
  }),
};

export const getStatisticsWidgets = (
  widgets: OrganisationHomeWidgetsResponse,
): IHomeWidget[] =>
  widgets.map((widget) => {
    const widgetType = widget.name;

    let fallbackValue: string | null = null;

    if (!widget.hasData) {
      fallbackValue = 'No data available yet.';
    } else if (widget.value === null) {
      fallbackValue = 'No data available for this period.';
    }

    return {
      id: widgetType,
      hash: TYPE_TO_STATISTICS_PAGE[widgetType],
      fallbackValue,
      ...WIDGETS[widgetType](widget),
    };
  });
