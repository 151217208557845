<script lang="ts" setup name="BaseCardNoResults">
import BaseButton from '~/components/BaseButton.vue';
import BaseCardState from '~/components/BaseCardState.vue';

const {
  message = 'Please amend search criteria to see available data.',
  button = 'Clear all',
  title = 'Sorry, No Results Found',
  hideClear,
  styled,
} = defineProps<{
  hideClear?: boolean;
  message?: string;
  button?: string;
  styled?: boolean;
  title?: string;
}>();

const emit = defineEmits<{
  (e: 'clear'): void;
}>();
</script>

<template>
  <BaseCardState
    textSize="card-text-subtitle-1"
    spacing="card-lg"
    :title="title"
    :message="message"
    :styled="styled"
  >
    <template #actions>
      <BaseButton
        v-if="!hideClear"
        variant="btn-tertiary"
        @click="emit('clear')"
      >
        {{ button }}
      </BaseButton>
    </template>
  </BaseCardState>
</template>
