<script setup lang="ts" name="AdminUserView">
import { computed, reactive } from 'vue';
import { RouterView, useRoute } from 'vue-router';

import { USER_TYPES } from '~/constants';

import { provideAdminUserView } from '~/features/adminUser';
import { useRouteBack } from '~/features/useRouteBack';
import { useService } from '~/features/useService';
import { addIf, getFullName } from '~/features/useUtilities';

import Service from '~/services/Service';

import BasePage from '~/components/BasePage.vue';
import ProfileHeader from '~/components/ProfileHeader.vue';
import RouterTabs from '~/components/RouterTabs.vue';

import type {
  IAdminUserShowResponse,
  IAdminViewUser,
  IBasePageProps,
  IRouterTabLink,
} from '~/types';

type User = (typeof USER_TYPES)[keyof typeof USER_TYPES];

const { userId, type } = defineProps<{
  userId: number;
  type: User;
}>();

const adminUsersIndexRoute = useRouteBack({ name: 'AdminUsersIndex' });

const route = useRoute();

const routeKey = computed(
  () => `${String(route.name)}-${Object.values(route.params).join('-')}`,
);

const user = reactive<IAdminViewUser>({
  isInternal: null,
  firstName: null,
  lastName: null,
  email: null,
  role: null,
  businessType: null,
  organisations: null,
});

const serviceState = useService(
  Service.user(userId).show(),
  ({ data }: IAdminUserShowResponse) => {
    user.isInternal = data.internal;
    user.firstName = data.first_name;
    user.lastName = data.last_name;
    user.email = data.email;
    user.role = data.role?.name;
    user.businessType = data.business_type;
    user.organisations = data.organisations;
    return true;
  },
);

const page = computed<IBasePageProps>(() => ({
  backButton: {
    label: 'User List',
    to: adminUsersIndexRoute,
  },
  actions:
    !serviceState.stateHasErrors && user.isInternal
      ? [
          {
            label: 'Edit user',
            icon: 'ic:baseline-edit',
            variant: 'btn-secondary',
            to: { name: 'AdminUserEdit' },
          },
        ]
      : [],
}));

const isG2oType = () => type === USER_TYPES.G2O;
const isCustomerType = () => type === USER_TYPES.CUSTOMER;

const tabs: IRouterTabLink[] = [
  {
    icon: 'mdi:account-box',
    label: 'User details',
    to: { name: 'AdminUserViewDetails' },
  },
  ...addIf(isG2oType() || isCustomerType(), {
    icon: isG2oType() ? 'mdi:briefcase' : 'mdi:bell-outline',
    label: isG2oType() ? 'Organisations' : 'Notifications',
    to: { name: 'AdminUserViewNotifications' },
  } as IRouterTabLink),
];

const fullName = computed(() => getFullName(user.firstName, user.lastName));

provideAdminUserView({
  user: user,
  serviceState: serviceState,
  userId: userId,
});
</script>

<template>
  <BasePage v-bind="page">
    <template #heading>
      <ProfileHeader :fullName="fullName" :profileRole="user.role" />
    </template>

    <div class="col-span-full space-y-2">
      <RouterTabs :tabs="tabs" />
      <RouterView :key="routeKey" />
    </div>
  </BasePage>
</template>
