<script setup lang="ts" name="FormWizardHorizontalTab">
import { computed } from 'vue';

import FormWizardHorizontalTabTitle from '~/components/FormWizardHorizontalTabTitle.vue';
import StepperHeaderCircle from '~/components/StepperHeaderCircle.vue';

const { step, activeStepIdx, disabled, setCurrentTab } = defineProps<{
  step: number;
  activeStepIdx: number;
  title?: string;
  disabled?: boolean;
  setCurrentTab: (index: number) => void;
}>();

const isCompleted = computed(() => step - 1 < activeStepIdx);
const isActive = computed(() => step - 1 === activeStepIdx);

const onClick = () => {
  if (isCompleted.value && !disabled) {
    setCurrentTab(step - 1);
  }
};
</script>

<template>
  <button
    type="button"
    class="flex flex-col items-center gap-y-2 bg-white hover:bg-charcoal-1"
    :class="{
      'pointer-events-none': disabled || !isCompleted,
      'px-6 py-4': !title,
      'w-32 py-4': title,
    }"
    @click="onClick"
  >
    <StepperHeaderCircle
      :isActive="isActive"
      :step="step"
      :isCompleted="isCompleted"
      :disabled="disabled"
    />

    <FormWizardHorizontalTabTitle
      v-if="title"
      :isCompleted="isCompleted"
      :isActive="isActive"
      :disabled="disabled"
      :title="title"
    />
  </button>
</template>
