import {
  CATEGORICAL_DAVIZ_COLORS,
  CATEGORICAL_DAVIZ_COLORS_TO_TAG,
} from '~/constants';

export const getCategoricalColors = (index: number) =>
  CATEGORICAL_DAVIZ_COLORS[index % CATEGORICAL_DAVIZ_COLORS.length];

export const getCategoricalTagColors = (index: number) => {
  const tagsColors = Object.values(CATEGORICAL_DAVIZ_COLORS_TO_TAG);

  return tagsColors[index % tagsColors.length];
};
