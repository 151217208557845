import type { IReasonForCall } from '~/types';

export const INVOICE_LAST_UPDATED = Symbol();

export const LAYCAN_PERFORMANCE_WIDGET_TYPE = {
  CURRENT_YEAR: 'current_year',
  PREVIOUS_YEAR: 'prev_year',
  PREVIOUS_QUARTER: 'prev_quarter',
} as const;

export const LAYCAN_PERFORMANCE_WIDGET_TYPES = [
  LAYCAN_PERFORMANCE_WIDGET_TYPE.CURRENT_YEAR,
  LAYCAN_PERFORMANCE_WIDGET_TYPE.PREVIOUS_YEAR,
  LAYCAN_PERFORMANCE_WIDGET_TYPE.PREVIOUS_QUARTER,
] as const;

export const LAYCAN_PERFORMANCE_WIDGET_TYPE_TO_SUB_TITLE = {
  [LAYCAN_PERFORMANCE_WIDGET_TYPE.CURRENT_YEAR]: 'This Year',
  [LAYCAN_PERFORMANCE_WIDGET_TYPE.PREVIOUS_YEAR]: 'Last Year',
  [LAYCAN_PERFORMANCE_WIDGET_TYPE.PREVIOUS_QUARTER]: 'Last Full Quarter',
} as const;

export const PRODUCTIVITY_WIDGET_TYPE = {
  PORT_CALLS: 'port_calls',
  REVENUE_TONS_PER_DAY: 'revenue_tons_per_day',
  TOTAL_REVENUE_TONS: 'total_revenue_tons',
} as const;

export const PRODUCTIVITY_WIDGET_TYPES = [
  PRODUCTIVITY_WIDGET_TYPE.PORT_CALLS,
  PRODUCTIVITY_WIDGET_TYPE.REVENUE_TONS_PER_DAY,
  PRODUCTIVITY_WIDGET_TYPE.TOTAL_REVENUE_TONS,
] as const;

export const DATE_RANGE = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  QUARTERS: 'quarters',
  YEARS: 'years',
} as const;

export const PERIOD_OPTIONS = {
  LAST_WEEK: '1w',
  LAST_3_MONTHS: '3m',
  LAST_12_MONTHS: '12m',
  LAST_4_QUARTERS: '4q',
  LAST_4_YEARS: '4y',
} as const;

export const CUSTOM_RANGE_OPTIONS = {
  CP: 'cp',
  DCP: 'dcp',
} as const;

export const STATISTICS_PERIODS = {
  LAST_WEEK: {
    id: PERIOD_OPTIONS.LAST_WEEK,
    name: 'Last week',
  },
  LAST_3_MONTHS: {
    id: PERIOD_OPTIONS.LAST_3_MONTHS,
    name: 'Last 3 months',
  },
  LAST_12_MONTHS: {
    id: PERIOD_OPTIONS.LAST_12_MONTHS,
    name: 'Last 12 months',
  },
  LAST_4_QUARTERS: {
    id: PERIOD_OPTIONS.LAST_4_QUARTERS,
    name: 'Last 4 quarters',
  },
  LAST_4_YEARS: {
    id: PERIOD_OPTIONS.LAST_4_YEARS,
    name: 'Last 4 years',
  },
  CUSTOM_RANGE: {
    id: CUSTOM_RANGE_OPTIONS.CP,
    name: 'Custom range',
    cp_start: null,
    cp_end: null,
  },
  CUSTOM_RANGE_DCP: {
    id: CUSTOM_RANGE_OPTIONS.DCP,
    name: 'Custom range',
    cp_start: null,
    cp_end: null,
  },
} as const;

export const STATISTICS_PERIODS_ID_TO_PERIOD = {
  PERIOD_1W: STATISTICS_PERIODS.LAST_WEEK.id,
  PERIOD_3M: STATISTICS_PERIODS.LAST_3_MONTHS.id,
  PERIOD_12M: STATISTICS_PERIODS.LAST_12_MONTHS.id,
  PERIOD_4Q: STATISTICS_PERIODS.LAST_4_QUARTERS.id,
  PERIOD_4Y: STATISTICS_PERIODS.LAST_4_YEARS.id,
  PERIOD_CP: STATISTICS_PERIODS.CUSTOM_RANGE.id,
  PERIOD_DCP: STATISTICS_PERIODS.CUSTOM_RANGE_DCP.id,
} as const;

export const STATISTICS_PERIODS_TO_TEXT = {
  [STATISTICS_PERIODS.LAST_12_MONTHS.id]: 'Last 12 Months',
  [STATISTICS_PERIODS.LAST_4_QUARTERS.id]: 'Last 4 Quarters',
  [STATISTICS_PERIODS.CUSTOM_RANGE.id]: 'Custom Range',
  [STATISTICS_PERIODS.LAST_4_YEARS.id]: 'Last 4 Years',
} as const;

export const STATISTICS_DEFAULT_PERIODS = [
  STATISTICS_PERIODS.LAST_12_MONTHS,
  STATISTICS_PERIODS.LAST_4_QUARTERS,
  STATISTICS_PERIODS.LAST_4_YEARS,
];

export const STATISTICS_QUERY_DEBOUNCE_TIME = 500;

export const LAYTIME_STATUSES = {
  UNDER_QUERY: 'under_query',
  SETTLED: 'settled',
  CUSTOMER_REVIEW: 'for_review',
  CALCULATION_IN_PROGRESS: 'in_progress',
} as const;

export const STATISTICS_RETRY_VARIANTS = {
  DEFAULT: 'default',
  MAIN_CONTENT: 'mainContent',
} as const;

export const LAYCAN_CHART_ZERO_VALUE = 2.5678;

export const WAITING_FOR_BERTH_CHART_ZERO_VALUE = 0.05;

export const REASON_FOR_CALL_TYPES = {
  DEFAULT: '',
  LOAD: 'L',
  DISCHARGE: 'D',
} as const;

export const REASON_FOR_CALL_TYPES_TO_TEXT = {
  [REASON_FOR_CALL_TYPES.DEFAULT]: 'All Port Calls',
  [REASON_FOR_CALL_TYPES.LOAD]: 'Load',
  [REASON_FOR_CALL_TYPES.DISCHARGE]: 'Discharge',
} as const;

export const REASON_FOR_CALL = <IReasonForCall[]>[
  {
    id: REASON_FOR_CALL_TYPES.DEFAULT,
    name: REASON_FOR_CALL_TYPES_TO_TEXT[REASON_FOR_CALL_TYPES.DEFAULT],
  },
  {
    id: REASON_FOR_CALL_TYPES.LOAD,
    name: REASON_FOR_CALL_TYPES_TO_TEXT[REASON_FOR_CALL_TYPES.LOAD],
  },
  {
    id: REASON_FOR_CALL_TYPES.DISCHARGE,
    name: REASON_FOR_CALL_TYPES_TO_TEXT[REASON_FOR_CALL_TYPES.DISCHARGE],
  },
];
