import { computed, ref } from 'vue';
import { isEqual } from 'lodash';

import { sortOptionsAZ } from '~/features/useUtilities';

import type { IFilterMenu, IOption } from '~/types/filters';

export const useFilterMenu = <T extends IOption = IOption>(
  props: IFilterMenu<T>,
) => {
  const search = ref('');

  const bySearch = (search: string) => (option: T) =>
    option.name.toLowerCase().includes(search.toLowerCase());

  const filteredOptions = computed(() =>
    search.value ? props.options.filter(bySearch(search.value)) : props.options,
  );

  const menuOptions = computed<T[]>(() => {
    if (props.sorted) {
      return sortOptionsAZ<T>(filteredOptions.value, props.displayKey);
    }

    return filteredOptions.value;
  });

  const onUpdate = (close: () => void) => {
    search.value = '';
    close();
  };

  const onClickOutside = (close: () => void) => {
    search.value = '';
    close();
  };

  return {
    search,
    menuOptions,
    onUpdate,
    onClickOutside,
  };
};

export const useMultipleSelect = () => {
  return {
    getLabel: (
      label: string,
      options: IOption[],
      selectedOptions: IOption[],
    ) => {
      if (selectedOptions.length) {
        return options.length === selectedOptions.length
          ? `${label}: All`
          : `${label} (${selectedOptions.length})`;
      }

      return label;
    },
    isOptionSelected: (options: IOption[], selectedOptions: IOption[]) =>
      selectedOptions.length > 0 && selectedOptions.length !== options.length,
    isActive: (option: IOption, inputValue: IOption[]) =>
      !!inputValue.find((value) => isEqual(value, option)),
  };
};

export const useSingleSelect = () => {
  return {
    getLabel: (
      label: string,
      selectedOptionName: string,
      defaultValue?: string,
    ) => {
      if (defaultValue) {
        return selectedOptionName || defaultValue;
      }

      return label;
    },
    isOptionSelected: (selectedOption: IOption, defaultValue?: IOption) => {
      if (defaultValue) {
        return !!selectedOption?.id && selectedOption.id !== defaultValue.id;
      } else {
        return !!selectedOption.id;
      }
    },
    isActive: (option: IOption, selectedOption: IOption) =>
      option.id === selectedOption.id,
  };
};
