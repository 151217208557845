<script lang="ts" setup name="BaseCheckboxToggle">
import { computed } from 'vue';

import BaseCheckbox from '~/components/BaseCheckbox.vue';

const {
  items,
  selectedItems,
  hideLabel,
  disabled = false,
} = defineProps<{
  items: unknown[];
  selectedItems: unknown[];
  disabled?: boolean;
  hideLabel?: boolean;
}>();

const emit = defineEmits<{
  (e: 'toggle', event: Event): void;
}>();

const allSelected = computed(() => selectedItems.length === items.length);

const indeterminate = computed(
  () => selectedItems.length > 0 && selectedItems.length < items.length,
);

const label = computed(() => {
  if (hideLabel) return '';

  const count = selectedItems.length;

  if (count === 0) {
    return 'Select all';
  }

  if (count === items.length) {
    return 'All selected';
  }

  return count > 1 ? `${count} items selected` : `${count} item selected`;
});
</script>

<template>
  <BaseCheckbox
    :modelValue="allSelected"
    :indeterminate="indeterminate"
    :disabled="disabled"
    @change="($event) => emit('toggle', $event)"
  >
    <template v-if="label" #default>
      <slot :label="label">
        <p
          class="px-2 text-xs font-bold uppercase tracking-widest text-charcoal-9 hover:text-primary-6 focus-visible:text-primary-8"
        >
          {{ label }}
        </p>
      </slot>
    </template>
  </BaseCheckbox>
</template>
