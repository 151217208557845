<script setup lang="ts" name="AdminUserViewNotifications">
import { injectAdminUserView } from '~/features/adminUser';

import SimpleCard from '~/components/Cards/SimpleCard.vue';
import UserNotificationsManagementTab from '~/components/UserNotificationsManagementTab.vue';

const userViewState = injectAdminUserView();
</script>

<template>
  <SimpleCard>
    <UserNotificationsManagementTab
      :is-internal="userViewState.user.isInternal"
      :user-id="userViewState.userId"
    />
  </SimpleCard>
</template>
