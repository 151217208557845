<script setup lang="ts" name="LastSyncTime">
import { type TimestampType, useLastSync } from '~/features/useLastSync';

const { lastUpdated } = defineProps<{
  lastUpdated?: TimestampType;
}>();

const { formattedTime } = useLastSync(lastUpdated);
</script>

<template>
  <div class="text-body-1 text-charcoal-6">
    {{ formattedTime }}
  </div>
</template>
