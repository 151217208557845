<script lang="ts" setup name="RouterTabs">
import { computed } from 'vue';

import { useTabsResize } from '~/features/useTabsResize';

import RouterTabsDropdown from '~/components/RouterTabsDropdown.vue';
import RouterTabsLink from '~/components/RouterTabsLink.vue';

import type { IRouterTabLink } from '~/types';

const { tabs } = defineProps<{
  tabs: IRouterTabLink[];
}>();

const routeTabs = computed(() => tabs);

const {
  MIN_TAB_WIDTH,
  maxVisibleTabs,
  widestTabWidth,
  tabsWrapperRef,
  visibleTabs,
  hiddenTabs,
} = useTabsResize(routeTabs);
</script>

<template>
  <div data-testid="router-tabs">
    <div
      class="flex rounded-t border-b border-charcoal-2 bg-white px-1 shadow"
      ref="tabsWrapperRef"
    >
      <div v-for="(tab, i) in visibleTabs" :key="i">
        <RouterTabsLink
          v-bind="tab"
          :width="widestTabWidth"
          :minWidth="MIN_TAB_WIDTH"
        />
      </div>

      <RouterTabsDropdown
        v-if="maxVisibleTabs < tabs.length"
        :tabs="hiddenTabs"
        :minWidth="MIN_TAB_WIDTH"
      />
    </div>
  </div>
</template>
