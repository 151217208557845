<script setup lang="ts" name="AdminAcceptUserSuccess">
import { computed } from 'vue';

import BaseButton from '~/components/BaseButton.vue';
import SuccessCard from '~/components/SuccessCard.vue';

import type { IAdminRequestedUser } from '~/types';

const { user, selectedOrganisation, newOrganisation } = defineProps<{
  user?: IAdminRequestedUser;
  selectedOrganisation?: string;
  newOrganisation?: string;
}>();

const fullName = computed(() => `${user?.first_name} ${user?.last_name}`);
const organisationName = computed(
  () => newOrganisation || selectedOrganisation,
);
const toText = computed(() => (newOrganisation ? 'to new created' : 'to'));
</script>

<template>
  <SuccessCard title="User Confirmed!" variant="secondary">
    <template #message>
      <p class="mx-2 my-4 text-center text-primary-8">
        You have successfully assigned
        <span class="font-bold">{{ fullName }}</span> {{ toText }}
        <span class="font-bold"> {{ organisationName }} </span>. An email
        notification has been sent to the user informing of the confirmation.
      </p>
    </template>

    <BaseButton
      :to="{
        name: 'AdminUsersIndex',
      }"
      variant="btn-secondary"
    >
      go to user list
    </BaseButton>
  </SuccessCard>
</template>
