<script setup lang="ts" name="PaginationDropdown">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import Icon from '~/components/Icon.vue';
import XDropdown from '~/components/XDropdown.vue';

import FilterRadioInput from './FilterRadioInput.vue';

const props = defineProps<{
  modelValue: number;
  options: number[];
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', perPage: number): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const isActive = computed(
  () => (option: number) =>
    JSON.stringify(inputValue.value) === JSON.stringify(option),
);
</script>

<template>
  <XDropdown data-testid="select-per-page-option">
    <template #trigger="{ toggle, isOpen }">
      <button
        type="button"
        class="flex items-center border-b transition-colors"
        :class="{
          'hover:border-ocean-8 hover:text-ocean-8': !disabled && !isOpen,
          'border-charcoal-6 text-charcoal-6': disabled || !isOpen,
          'border-ocean-8 text-ocean-8': !disabled && isOpen,
        }"
        :disabled="disabled"
        @click.stop="toggle"
      >
        <p>{{ inputValue }} per page</p>
        <Icon
          icon="mdi:chevron-right"
          class="pointer-events-none transform transition-transform ease-in"
          :class="{
            '-rotate-90': isOpen,
            'rotate-90': !isOpen,
          }"
        />
      </button>
    </template>
    <template #default="{ close }">
      <div class="max-h-64 w-max min-w-full overflow-y-auto bg-white py-2">
        <ul class="list-none" data-testid="filter-options">
          <li v-for="option in options" :key="option" class="w-full">
            <FilterRadioInput
              :id="option"
              v-model="inputValue"
              name="per-page"
              :native-value="option"
              :active="isActive(option)"
              @select="close"
            >
              {{ option }} per page
            </FilterRadioInput>
          </li>
        </ul>
      </div>
    </template>
  </XDropdown>
</template>
