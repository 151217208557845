<script setup lang="ts" name="FilterMenuSelectMultiple">
import { watch } from 'vue';
import { useVirtualList, useVModel } from '@vueuse/core';

import { useFilterMenu, useMultipleSelect } from '~/features/useFilterMenu';

import FilterCheckboxInput from '~/components/FilterCheckboxInput.vue';
import FilterMenu from '~/components/FilterMenu.vue';
import FilterMenuOptions from '~/components/FilterMenuOptions.vue';
import SearchField from '~/components/SearchField.vue';

import type { IconType } from '~/types';
import type { IOption } from '~/types/filters';

const props = withDefaults(
  defineProps<{
    modelValue: IOption[];
    label: string;
    icon?: IconType;
    options: IOption[];
    searchable?: boolean;
    placeholder?: string;
    sorted?: boolean;
    displayKey?: string;
  }>(),
  {
    displayKey: 'name',
    placeholder: 'Search',
  },
);
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'selected'): void;
  (e: 'update:modelValue'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const { search, menuOptions, onUpdate, onClickOutside } = useFilterMenu(props);

const { isActive, isOptionSelected, getLabel } = useMultipleSelect();

const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(
  menuOptions,
  {
    itemHeight: 40,
  },
);

watch(
  () => menuOptions.value,
  () => {
    scrollTo(0);
  },
);
</script>

<template>
  <FilterMenu
    :icon="icon"
    :label="getLabel(props.label, props.options, inputValue)"
    :highlight="isOptionSelected(props.options, inputValue)"
    @click-outside="onClickOutside(() => emit('close'))"
  >
    <template #default="{ close }">
      <SearchField
        v-if="searchable"
        v-model="search"
        focus
        size="small"
        :placeholder="placeholder"
      />

      <FilterMenuOptions
        :hasOptions="menuOptions.length > 0"
        :virtualListContainer="containerProps"
      >
        <div v-bind="wrapperProps">
          <FilterCheckboxInput
            v-for="{ data: option, index } in list"
            v-model="inputValue"
            style="height: 40px"
            :key="index"
            :title="option.name"
            :native-value="option"
            :active="isActive(option, inputValue)"
            @select="() => onUpdate(close)"
          >
            <slot :option="option">
              {{ option.name }}
            </slot>
          </FilterCheckboxInput>
        </div>
      </FilterMenuOptions>
    </template>
  </FilterMenu>
</template>
