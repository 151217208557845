import type { IStepCellManager } from '~/features/useDataTableStepCells';

import type { IAddressManager } from './useDataTableSelectedCell';

export type HandleInputTab = () => void;
export type HandleInputEnter = () => void;

export const useDataTableInputKeywordNavigation = ({
  stepCellManager,
  addressManager,
  toggleEditMode,
  moveRightOrTab,
  resetEditMode,
  updateRowForStepCells,
}: {
  addressManager: IAddressManager;
  stepCellManager: IStepCellManager;
  toggleEditMode;
  moveRightOrTab;
  resetEditMode;
  updateRowForStepCells;
}) => {
  const focusNextStepCell = () => {
    const nextCell = stepCellManager.getCurrentStepCell();

    if (!nextCell) {
      return;
    }

    resetEditMode(addressManager.getRowAndColNumbersFromAddress());

    addressManager.selectAddress(nextCell.address);
    addressManager.focusAndScrollToCell(nextCell.address);

    const parsedAddress = addressManager.parseAddress(nextCell.address);
    toggleEditMode(parsedAddress);
  };

  const handleInputTab = () => {
    if (stepCellManager.hasUncompletedSteps()) {
      stepCellManager.completeCurrentStep();

      if (stepCellManager.hasUncompletedSteps()) {
        focusNextStepCell();
        return;
      } else {
        const valuesWereSubmitted = stepCellManager.stepCellValuesSubmitted();

        if (valuesWereSubmitted) {
          updateRowForStepCells();
        }
      }
    }

    const { newRow, newCol } = moveRightOrTab(
      addressManager.getRowAndColNumbersFromAddress(),
    );

    resetEditMode(addressManager.getRowAndColNumbersFromAddress());
    addressManager.selectAddress(addressManager.createAddress(newCol, newRow));
    addressManager.focusCell(addressManager.selectedCellAddress.value);
  };

  const handleInputEnter = () => {
    if (!stepCellManager.hasUncompletedSteps()) {
      handleInputReset();
      return;
    }

    stepCellManager.completeCurrentStep();

    if (stepCellManager.hasUncompletedSteps()) {
      focusNextStepCell();
      return;
    }

    const valuesWereSubmitted = stepCellManager.stepCellValuesSubmitted();

    if (valuesWereSubmitted) {
      updateRowForStepCells();
    }
  };

  const handleInputReset = () => {
    resetEditMode(addressManager.getRowAndColNumbersFromAddress());
    addressManager.focusCell(addressManager.selectedCellAddress.value);
  };

  return { handleInputTab, handleInputEnter };
};
