<script setup lang="ts" name="AdminUserViewDetails">
import { injectAdminUserView } from '~/features/adminUser';

import Card from '~/components/Card.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import UserDetails from '~/components/UserDetails.vue';

const userViewState = injectAdminUserView();
</script>

<template>
  <Card>
    <ServiceCard v-bind="userViewState?.serviceState">
      <UserDetails
        :first-name="userViewState?.user.firstName"
        :last-name="userViewState?.user.lastName"
        :email="userViewState?.user.email"
        :role="userViewState?.user.role"
        :business-type="userViewState?.user.businessType"
      />
    </ServiceCard>
  </Card>
</template>
