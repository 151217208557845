import type { ComputedRef, Ref } from 'vue';
import type { NavigationGuard } from 'vue-router';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';

import { useShowModal } from '~/features/useModalsProvider';

import ModalConfirmDanger from '~/components/ModalConfirmDanger.vue';

export const useLeavePageWarning = (
  shouldShowLeaveWarning: ComputedRef<boolean>,
  finished: ComputedRef<boolean> | Ref<boolean>,
  beforeUpdate?: NavigationGuard,
  onDialogPopUp?: () => void,
) => {
  const showModal = useShowModal();

  const onRouteGuard = async () => {
    if (shouldShowLeaveWarning.value || finished.value) {
      return true;
    }

    if (onDialogPopUp) {
      onDialogPopUp();
    }

    const result = await showModal(ModalConfirmDanger, {
      title: 'Leave Page?',
      message:
        'Unsaved changes may be lost. Are you sure you want to leave this page?',
      confirm: 'Leave',
      icon: 'mdi:information',
    });

    return result.action === 'CONFIRM';
  };

  onBeforeRouteLeave(onRouteGuard);

  beforeUpdate
    ? onBeforeRouteUpdate(beforeUpdate)
    : onBeforeRouteUpdate(onRouteGuard);
};
