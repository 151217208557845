<script setup lang="ts" name="DataTableDatePicker">
import { nextTick, onMounted, onUnmounted, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

import { getDateYear } from '~/features/useDates';

const { value } = defineProps<{
  value: string;
}>();

const emit = defineEmits<{
  update: [value: string | null];
  moveRightOrTab: [];
  resetEditMode: [];
  onEnter: [];
}>();

const inputRef = ref<HTMLInputElement>();
const datePickerRef = ref();
const targetElement = document.getElementById('date-picker-popover');

const popoverElement = ref<HTMLInputElement>();

const inputValue = ref(value);

const masks = {
  input: 'D MMM YYYY',
};

const selectNewDate = () => {
  emit('update', getDateYear(inputValue.value));
};

const onKeyDown = (event: KeyboardEvent) => {
  event.stopPropagation();

  if (event.key === 'Tab') {
    if (inputValue.value !== value) {
      selectNewDate();
    }

    emit('moveRightOrTab');

    event.preventDefault();
  } else if (event.key === 'Escape') {
    emit('resetEditMode');
  }
};

const onSelectDate = () => {
  inputRef.value?.blur();

  selectNewDate();
  emit('resetEditMode');
};

onClickOutside(
  inputRef,
  () => {
    emit('resetEditMode');
  },
  {
    ignore: [popoverElement],
  },
);

onMounted(() => {
  inputRef.value?.focus();

  nextTick(() => {
    popoverElement.value = datePickerRef.value?.popoverRef.popoverRef;

    if (
      popoverElement.value &&
      !targetElement?.contains(popoverElement.value)
    ) {
      targetElement?.appendChild(popoverElement.value);
    }
  });
});

onUnmounted(() => {
  if (popoverElement.value && targetElement?.contains(popoverElement.value)) {
    targetElement?.removeChild(popoverElement.value);
  }
});
</script>

<template>
  <v-date-picker
    ref="datePickerRef"
    color="orange"
    v-model="inputValue"
    :masks="masks"
    :popover="{ visibility: 'focus', placement: 'bottom-start' }"
    @keydown="onKeyDown"
    @dayclick="onSelectDate"
    @dblclick.stop
  >
    <template #default="{ inputValue: dateValue, inputEvents }">
      <input
        class="w-full max-w-full focus:outline-none"
        ref="inputRef"
        data-test="date-picker-field-input"
        type="text"
        :value="dateValue"
        name="table_date_picker_field"
        v-on="inputEvents"
      />
    </template>
  </v-date-picker>
</template>
