import { useOrganisationsStore, useProfileStore } from '~/store';

import type { IOrganisation } from '~/types';

export const hasOneOfPermissions = (
  permissions: string[],
  organisation?: IOrganisation | null,
) => {
  const profile = useProfileStore();
  // TODO: remove this with time
  const organisationsStore = useOrganisationsStore();

  const allPermissions = [
    ...new Set([
      ...(profile.internalPermissions || []),
      ...(organisation?.permissions || []),
      ...(organisationsStore.organisation?.permissions || []),
    ]),
  ];

  return allPermissions.some((x) => permissions.includes(x));
};
