<script setup lang="ts" name="FilterMenu">
import { ref } from 'vue';

import FilterMenuTrigger from '~/components/FilterMenuTrigger.vue';
import XDropdown from '~/components/XDropdown.vue';

import type { IconType } from '~/types';

defineProps<{
  label: string;
  icon?: IconType;
  highlight?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'clickOutside'): void;
}>();

const triggerRef = ref<typeof FilterMenuTrigger>();

const onClose = (close: () => void) => {
  triggerRef.value?.$el.focus();
  close();
};

const onCloseOutside = () => {
  emit('clickOutside');
};
</script>

<template>
  <XDropdown @click-outside="onCloseOutside">
    <template #trigger="{ toggle, isOpen, close }">
      <slot name="trigger" :toggle="toggle" :isOpen="isOpen" :close="close">
        <FilterMenuTrigger
          ref="triggerRef"
          :data-testid="`filter-select-${label}`"
          :icon="icon"
          :is-open="isOpen"
          :has-selected="highlight"
          :disabled="disabled"
          @click="toggle"
        >
          {{ label }}
        </FilterMenuTrigger>
      </slot>
    </template>

    <template #default="{ close, isOpen }">
      <slot :close="() => onClose(close)" :isOpen="isOpen" />
    </template>
  </XDropdown>
</template>
