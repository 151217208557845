<script lang="ts" setup name="BaseCardNoData">
import { useSlots } from 'vue';

import BaseCardState from '~/components/BaseCardState.vue';

import type {
  BaseCardStateSpacingType,
  BaseCardStateTextSizeType,
  IllustrationType,
} from '~/types';

const {
  title = 'We Have Looked Carefully...',
  illustration = 'woman_on_ledge',
  spacing = 'card-lg',
  textSize = 'card-text-headline',
  message,
  styled,
} = defineProps<{
  title?: string;
  message?: string | null;
  illustration?: IllustrationType;
  styled?: boolean;
  spacing?: BaseCardStateSpacingType;
  textSize?: BaseCardStateTextSizeType;
}>();

const slots = useSlots();
</script>

<template>
  <BaseCardState
    text-color="card-text-charcoal-6"
    :text-size="textSize"
    :spacing="spacing"
    :title="title"
    :message="message"
    :illustration="illustration"
    :styled="styled"
  >
    <template v-if="slots.message" #message>
      <slot name="message" />
    </template>
    <template v-if="slots.default" #actions>
      <slot />
    </template>
  </BaseCardState>
</template>
