import type { InjectionKey, Ref } from 'vue';
import { provide, ref, watch } from 'vue';

import { useStrictInject } from '~/features/useStrictInject';

import type { PaginationLinks, PaginationMeta } from '~/types';

type PaginationChange = () => void;

interface PaginationProps {
  options?: number[];
  page?: number | string;
  perPage?: number | string;
}

interface PaginationState {
  page: number;
  perPage: number;
  total: number;
  from: number;
  to: number;
  hasPrevious: boolean;
  hasNext: boolean;
  options: number[];
}

interface Pagination {
  [key: string]: unknown;
  perPage: number;
  page: number;
}

type NextPage = () => void;
type PrevPage = () => void;

const getIK = <T>(key: string): InjectionKey<T> => Symbol(key);

const PaginationKey = getIK<Ref<PaginationState>>('PaginationKey');
const NextPageKey = getIK<NextPage>('NextPageKey');
const PrevPageKey = getIK<PrevPage>('PrevPageKey');

function providePagination(item: Ref<PaginationState>) {
  provide(PaginationKey, item);
}
function provideNextPage(item: NextPage) {
  provide(NextPageKey, item);
}
function providePrevPage(item: PrevPage) {
  provide(PrevPageKey, item);
}

export const injectPagination = () => useStrictInject(PaginationKey);
export const injectNextPage = () => useStrictInject(NextPageKey);
export const injectPrevPage = () => useStrictInject(PrevPageKey);

export const useAutoPagination = (
  onPaginationChange: PaginationChange,
  { options, page, perPage }: PaginationProps = {},
) => {
  const pagination = ref<PaginationState>({
    page: page ? parseInt(page, 10) : 1,
    perPage: perPage ? parseInt(perPage, 10) : 10,
    total: 0,
    from: 0,
    to: 0,
    hasPrevious: false,
    hasNext: false,
    options: options || [10, 15, 30, 60],
  });

  const resetPage = () => {
    pagination.value.page = 1;
  };

  const onNextPage = () => {
    pagination.value.page += 1;
  };

  const onPreviousPage = () => {
    pagination.value.page -= 1;
  };

  const updatePagination = ({
    links,
    meta,
  }: {
    links: PaginationLinks;
    meta: PaginationMeta;
  }) => {
    pagination.value.page = meta.current_page;
    pagination.value.perPage = meta.per_page;
    pagination.value.total = meta.total;
    pagination.value.from = meta.from;
    pagination.value.to = meta.to;
    pagination.value.hasPrevious = !!links.prev;
    pagination.value.hasNext = !!links.next;
  };

  const withPagination = (data: Record<string, unknown> = {}): Pagination => ({
    ...data,
    page: pagination.value.page,
    perPage: pagination.value.perPage,
  });

  watch(
    () => pagination.value.perPage,
    (val, oldValue) => {
      if (val === oldValue) {
        return;
      }

      if (pagination.value.page !== 1) {
        pagination.value.page = 1;
        return;
      }

      onPaginationChange();
    },
  );

  watch(
    () => pagination.value.page,
    () => {
      onPaginationChange();
    },
  );

  providePagination(pagination);
  provideNextPage(onNextPage);
  providePrevPage(onPreviousPage);

  return {
    withPagination,
    resetPage,
    updatePagination,
  };
};
