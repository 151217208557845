<script lang="ts" setup name="InvitedUserCard">
import BaseButton from '~/components/BaseButton.vue';
import UserListPendingActiveUserInfo from '~/components/UserListPendingActiveUserInfo.vue';
import UserListPendingUserInfo from '~/components/UserListPendingUserInfo.vue';
import UserListUserRole from '~/components/UserListUserRole.vue';

const { firstName, lastName, email, role, hideDelete } = defineProps<{
  firstName?: string;
  lastName?: string;
  email: string;
  role: string;
  hideDelete?: boolean;
}>();

const emit = defineEmits<{
  (e: 'remove'): void;
}>();

const onRemoveUser = () => {
  emit('remove');
};
</script>

<template>
  <div class="flex w-full items-center bg-white p-4">
    <div class="w-1/5 flex-shrink-0">
      <UserListPendingActiveUserInfo
        v-if="firstName"
        :email="email"
        :first-name="firstName"
        :last-name="lastName"
      />
      <UserListPendingUserInfo v-else :email="email" />
    </div>
    <div class="ml-2 w-1/4">
      <UserListUserRole :role="role" />
    </div>
    <BaseButton
      v-if="!hideDelete"
      class="ml-auto"
      variant="btn-tertiary"
      icon="ic:baseline-delete"
      @click="onRemoveUser"
    />
  </div>
</template>
