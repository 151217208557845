<script setup lang="ts" name="BaseTag">
import { computed } from 'vue';

import { BASE_TAG_LOW_CONTRAST } from '~/constants';

import type { BaseTagContrastType, BaseTagLowContrastType } from '~/types';

const { variant, name, disabled } = defineProps<{
  name: string;
  variant: BaseTagContrastType;
  disabled?: boolean;
}>();

const LOOKUP_BORDER_COLOR = {
  [BASE_TAG_LOW_CONTRAST.GREEN_1]: 'border-green-2',
  [BASE_TAG_LOW_CONTRAST.AMBER_1]: 'border-amber-2',
  [BASE_TAG_LOW_CONTRAST.ORANGE_1]: 'border-orange-2',
  [BASE_TAG_LOW_CONTRAST.CHARCOAL_1]: 'border-charcoal-2',
  [BASE_TAG_LOW_CONTRAST.PURPLE_1]: 'border-purple-2',
  [BASE_TAG_LOW_CONTRAST.NAVY_BLUE_1]: 'border-navy-blue-2 ',
  [BASE_TAG_LOW_CONTRAST.INDIGO_1]: 'border-indigo-2 ',
  [BASE_TAG_LOW_CONTRAST.MAGENTA_1]: 'border-magenta-2 ',
};

const isLowContrast = computed(() =>
  Object.values(BASE_TAG_LOW_CONTRAST).includes(
    variant as BaseTagLowContrastType,
  ),
);

const borderColor = computed(() =>
  isLowContrast.value ? LOOKUP_BORDER_COLOR[variant] : '',
);

const tagContrast = computed(() => {
  if (disabled) {
    return 'tag-disabled';
  }

  return variant;
});
</script>

<template>
  <div
    class="flex max-w-max items-center gap-1 truncate rounded px-1 text-left text-button-1 uppercase tracking-wider"
    :class="[tagContrast, borderColor, { border: isLowContrast && !disabled }]"
  >
    <slot name="prepend"> </slot>
    <slot>
      <p>
        {{ name }}
      </p>
    </slot>
    <slot name="append"> </slot>
  </div>
</template>

<style scoped>
.tag-disabled {
  color: theme('colors.white');
  background-color: theme('colors.charcoal.4');
}
</style>
