export const PAGE_NAME_TO_TITLE = {
  // Admin
  AdminUsersIndex: 'Admin User List',
  AdminUserAcceptRequest: 'Admin Accept User Request',
  AdminUserNew: 'Admin User List New User',
  AdminUserView: 'Admin User List View User',
  AdminUserEdit: 'Admin User List Edit User',
  AdminUserRejectRequest: 'Admin User List Reject User',
  AdminOrganisationsIndex: 'Admin Organisations List',
  AdminOrganisationNew: 'Admin New Organisation',

  // Auth
  AzureLogin: 'Azure Login',
  AuthNoAccount: 'Auth No Account',
  AuthLogin: 'MyG2 Login',
  AuthForgotPassword: 'MyG2 Forgot Password',
  AuthResetPassword: 'MyG2 Reset Password',
  AuthAcceptInvitation: 'MyG2 Accept Invitation',
  AuthProfile: 'User My Details',
  AuthProfileEdit: 'User My Details Edit',
  AuthRegistration: 'User Registration',

  // Fallback Routes
  Default: 'Default',
  Expired: 'Expired Invitation',
  403: 'Not Authorized',
  404: 'Not Found',
  500: 'Server Error',

  // Notification Routes
  ArchiveNotificationsIndex: 'Notifications Archive',

  // Organisation Routes
  OrganisationHomeIndex: 'Home',
  // Organisation Statistic Routes
  OrganisationStatisticsV2: 'Statistics',
  OrganisationStatisticsV2ShipmentVolumes: 'Statistics: Shipment Volumes',
  OrganisationStatisticsV2LaycanPerformance: 'Statistics: Laycan',
  OrganisationStatisticsV2TransitTime: 'Statistics: Transit Time',
  OrganisationStatisticsV2Invoices: 'Statistics: Invoices',
  OrganisationStatisticsV2InvoicesOpen: 'Statistics: Open Invoices',
  OrganisationStatisticsV2InvoicesPaid: 'Statistics: Paid Invoices',
  OrganisationStatisticsLaytime: 'Statistics: Laytime',
  OrganisationStatisticsLaytimeView: 'Statistics: Laytime View',
  OrganisationStatisticsWaitingForBerth: 'Statistics: Waiting for Berth',
  OrganisationStatisticsV2Emissions: 'Statistics: Emissions',
  OrganisationStatisticsV2Productivity: 'Statistics: Productivity',
  OrganisationStatisticsV2Bunkers: 'Statistics: Bunkers',
  OrganisationStatisticsV2Claims: 'Statistics: Claims',
  OrganisationSettingsIndex: 'Organisation',
  OrganisationSettingsCompaniesIndex: 'Organisation: Companies',
  OrganisationSettingsUserListIndex: 'Organisation: UserList',
  OrganisationSettingsLiveIndex: 'Organisation: Settings Live',

  // Organisation Cargo Bookings
  OrganisationBookingsIndex: 'Organisation: Cargo Nomination',
  OrganisationBookingsOverview: 'Organisation: Bookings Overview',
  OrganisationBookingsDeclaration: 'Organisation: Bookings Declaration',
  OrganisationBookingsNomination: 'Organisation: Bookings Nomination',
  OrganisationBookingsDeclarationNew: 'Organisation: New Bookings Declaration',
  OrganisationBookingsNominationNew: 'Organisation: New Bookings Nomination',
  OrganisationBookingsHistory: 'Organisation: Bookings History',
  OrganisationBookingsDeclarationVeson:
    'Organisation: Bookings Declaration Transfer To Veson',

  // Organisation Kpis Old Routes
  OrganisationSettingsKpisIndex: 'Organisation: KPIs List',
  OrganisationKpiCreate: 'Organisation: KPI Create',
  OrganisationKpiEdit: 'Organisation: KPI Edit',
  OrganisationKpiShow: 'Organisation: KPI Show',
  // KPI Statistic Views
  OrganisationKpisIndex: 'Organisation: KPI List',
  OrganisationKpisShippedVolume: 'Organisation: KPI Shipped Volume',
  OrganisationKpisPerShipment: 'Organisation: KPI Per Shipment',
  OrganisationKpisTransitTimes: 'Organisation: KPI Transit Times',
  OrganisationKpisInvoices: 'Organisation: KPI Invoices',
  // Shipment Volumes KPIs
  OrganisationSettingsKpisShippedVolumesIndex:
    'Organisation KPIs: View Shipment Volumes KPIs List',
  OrganisationSettingsKpisShippedVolumesNew:
    'Organisation KPIs: New Shipment Volumes KPI',
  OrganisationSettingsKpisShippedVolumesEdit:
    'Organisation KPIs: Edit Shipment Volumes KPI',
  OrganisationSettingsKpisShippedVolumesView:
    'Organisation KPIs: View Shipment Volumes KPI',
  // Transit Times KPIs
  OrganisationSettingsKpisTransitTimesIndex:
    'Organisation KPIs: View Transit Times KPIs List',
  OrganisationSettingsKpisTransitTimesView:
    'Organisation KPIs: View Transit Times KPI',
  OrganisationSettingsKpisTransitTimesEdit:
    'Organisation KPIs: Edit Transit Times KPI',
  OrganisationSettingsKpisTransitTimesNew:
    'Organisation KPIs: New Transit Times KPI',
  // Invoice KPIs
  OrganisationSettingsKpisInvoicesNew: 'Organisation KPIs: New Invoices KPI',
  OrganisationSettingsKpisInvoicesIndex:
    'Organisation KPIs: View Invoices KPIs List',
  OrganisationSettingsKpisInvoicesEdit: 'Organisation KPIs: Edit Invoices KPI',

  // User Routes
  OrganisationSettingsUserEdit: 'Organisation User Edit',
  OrganisationSettingsEdit: 'Organisation Edit',

  // Organisation Shipments Routes
  OffsetOrganisationShipmentsEmissions: 'Shipments: Emissions',
  OffsetOrganisationShipmentsEmissionsIndex: 'Shipments: Emissions Offsets',
  OffsetOrganisationShipmentsEmissionsOrdersIndex:
    'Shipments: Emissions Orders',
  OrganisationShipmentsIndex: 'Shipments',
  OrganisationShipmentsNotifiedIndex: 'My Shipments',
  OrganisationShipmentsShow: 'Shipment',
  OrganisationShipmentsShowDetails: 'Shipment: Details',
  OrganisationShipmentsShowMilestones: 'Shipment: Milestones',
  OrganisationShipmentsShowDocuments: 'Shipment: Documents',
  OrganisationShipmentsShowPortContacts: 'Shipment: Port Contacts',
  OrganisationShipmentsSharedShipment: 'Shipment: Shared Page',

  // Organisation Vessels Routes
  OrganisationVesselsIndex: 'Vessels',
  OrganisationVesselsShow: 'Vessel Details',

  // Public Routes
  PublicCookiePolicy: 'Public Cookie Policy',
  PublicSharedShipment: 'Public Shared Shipment',

  // Sailing Schedule Routes
  SailingScheduleIndex: 'Sailing Schedule',

  // Support Routes
  SupportIndex: 'Support',
  CookiePolicy: 'Cookie Policy',

  // Unknown Routes
  Home: 'Home',

  // Public User Routes
  PublicSailingScheduleIndex: 'External Sailing Schedule',
  PublicVesselsIndex: 'External Vessels List',
  PublicVesselsShow: 'External Vessels Detail',
  PublicUserCargoTracker: 'External Cargo Tracker',
} as const;
