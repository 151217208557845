<script
  setup
  lang="ts"
  name="DataTableRows"
  generic="T extends IDataTableBaseRow"
>
import { useVModel } from '@vueuse/core';

import { getCellPadding } from '~/features/useDataTableCellPadding';
import type {
  HandleCellClick,
  HandleCellDoubleClick,
} from '~/features/useDataTableEventHandlers';
import type {
  HandleInputEnter,
  HandleInputTab,
} from '~/features/useDataTableInputKeywordNavigation';
import type { HandleInputUpdate } from '~/features/useDataTableRowUpdates';
import type { IStepCellManager } from '~/features/useDataTableStepCells';
import {
  getStickyColumnOffset,
  hasStickyColumns,
  isFirstStickyColumn,
} from '~/features/useDataTableStickyColumn';

import BaseCheckbox from '~/components/BaseCheckbox.vue';
import DataTableCellField from '~/components/DataTableCellField.vue';
import DataTableRow from '~/components/DataTableRow.vue';
import DataTableRowCell from '~/components/DataTableRowCell.vue';

import type {
  IDataTableBaseRow,
  IDataTableCellValue,
  IDataTablePaddingType,
  IDataTableRow,
  IDataTableSettings,
} from '~/types';

const props = defineProps<{
  tableData: T[];
  tableRows: IDataTableRow[];
  settings: IDataTableSettings<T>;
  areaOverflows: boolean;
  selectedCellAddress: string;
  selectedTableRows: T[];
  overflowAreaWidth: number | null;
  padding?: IDataTablePaddingType;
  initializeSteps: IStepCellManager['initializeSteps'];
  handleCellClick: HandleCellClick;
  handleCellDoubleClick: HandleCellDoubleClick;
  handleInputUpdate: HandleInputUpdate;
  handleInputTab: HandleInputTab;
  handleInputEnter: HandleInputEnter;
}>();

const emit = defineEmits<{
  'update:selectedTableRows': [value: T[]];
}>();

const selectedRows = useVModel(props, 'selectedTableRows', emit);

const findDataObjectById = (id: string) =>
  props.tableData.find((item) => item.id === id);
</script>

<template>
  <DataTableRow
    v-for="(row, index) in tableRows"
    :key="row.id"
    :collapsible="settings.collapsibleRows"
    :editable="settings.editable"
    :padding="settings.padding"
    :selected="settings.isRowSelected?.(row.id)"
    :isCollapsibleOpen="
      settings.shouldOpenCollapsible?.(findDataObjectById(row.id)!)
    "
    :isCollapsibleDisabled="
      settings.shouldDisableCollapsible?.(findDataObjectById(row.id)!)
    "
    @toggle="settings.onCollapsibleToggle?.(findDataObjectById(row.id)!)"
  >
    <template #collapsible>
      <slot
        name="collapsible"
        :item="findDataObjectById(row.id)!"
        :rowIndex="index"
        :areaOverflows="areaOverflows"
        :overflowAreaWidth="overflowAreaWidth"
      />
    </template>

    <template #default="{ isOpen }">
      <div
        v-if="settings.checkboxSelection"
        class="flex-center p-4 px-6"
        :class="getCellPadding(padding)"
      >
        <BaseCheckbox
          v-model="selectedRows"
          :nativeValue="findDataObjectById(row.id)!"
        />
      </div>

      <DataTableRowCell
        v-for="cell in row.cells"
        :key="cell.address"
        :cell="cell"
        :rowExpanded="isOpen"
        :cancelled="row.cancelled"
        :highlight="row.highlight"
        :selected="settings.isRowSelected?.(row.id)"
        :isFirstSticky="isFirstStickyColumn(settings.columns, cell.key)"
        :rightOffset="
          getStickyColumnOffset(cell.key, settings.columns, settings.actions)
        "
        :areaOverflows="areaOverflows"
        :selectedCellAddress="selectedCellAddress"
        :editable="settings.editable"
        v-slot="{ fieldRef }"
        @onClick="handleCellClick(cell.address)"
        @onDoubleClick="handleCellDoubleClick(cell.address)"
      >
        <component
          v-if="cell.render?.(findDataObjectById(row.id))"
          :is="cell.render(findDataObjectById(row.id))"
          :value="cell.value"
        />
        <DataTableCellField
          v-else
          :cell="cell"
          :fieldRef="fieldRef"
          :options="settings.options"
          @update="
            (val: IDataTableCellValue) =>
              handleInputUpdate(tableData[index], val)
          "
          @on-enter="handleInputEnter"
          @reset-edit-mode="handleInputEnter"
          @move-right-or-tab="handleInputTab"
        />
      </DataTableRowCell>

      <div
        v-if="settings.actions"
        class="cargo-booking-sticky-actions group-hover:bg-charcoal-1"
        :class="[
          getCellPadding(padding, 'last'),
          {
            'bg-ocean-0': settings.isRowSelected?.(row.id),
            'bg-white': !settings.isRowSelected?.(row.id),
            'border-l border-l-charcoal-2': !hasStickyColumns(settings.columns),
            'cargo-booking-sticky-shadow':
              areaOverflows && !hasStickyColumns(settings.columns),
            'border-r-2 border-ocean-4': settings.isRowSelected?.(row.id),
          },
        ]"
      >
        <slot
          name="actions"
          :item="findDataObjectById(row.id)!"
          :initializeSteps="initializeSteps"
          :cancelled="row.cancelled"
        />
      </div>
    </template>
  </DataTableRow>

  <DataTableRow v-if="!!settings.appendRow">
    <component :is="settings.appendRow(tableData)" />
  </DataTableRow>
</template>
