<script setup name="FilterRadioInput">
import { watch } from 'vue';
import { useVModel } from '@vueuse/core';

import Icon from '~/components/Icon.vue';

const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean, Function, Object, Array],
    default: null,
  },
  id: {
    type: [Number, String],
    default: null,
  },
  nativeValue: {
    type: [String, Number, Boolean, Function, Object, Array],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    default: '',
  },
  active: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  capitalize: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue', 'select', 'selected']);

const inputValue = useVModel(props, 'modelValue', emit);

watch(
  () => inputValue.value,
  (value) => {
    if (JSON.stringify(value) === JSON.stringify(props.nativeValue)) {
      emit('select', value);
    }
  },
);
</script>

<template>
  <label
    v-tooltip="tooltip"
    class="grid w-full cursor-pointer grid-cols-[1fr_24px] gap-2 px-4 py-2 hover:bg-charcoal-1"
    :class="{
      'cursor-default': disabled,
      'group cursor-pointer': !disabled,
    }"
    @click="emit('selected')"
  >
    <input
      :id="id"
      v-model="inputValue"
      class="invisible absolute"
      type="radio"
      data-test="input"
      :name="name"
      :value="nativeValue"
      :disabled="disabled"
    />
    <p
      v-title
      class="pointer-events-none truncate group-hover:text-primary-8"
      :class="{
        capitalize: capitalize,
        'text-primary-8': active,
        'text-charcoal-4': selected,
      }"
    >
      <slot />
    </p>
    <Icon
      v-if="active || selected"
      icon="ic:baseline-check"
      class="pointer-events-none place-self-center group-hover:text-primary-8"
      :class="{
        'text-primary-8': active,
        'text-charcoal-3': !active,
        'text-charcoal-5': selected,
      }"
    />
  </label>
</template>
