<script setup lang="ts" name="Maintenance">
import { computed } from 'vue';

import { ALERT_TYPE } from '~/constants';
import { useMaintenanceStore } from '~/store';

import BaseAlert from '~/components/BaseAlert.vue';

import type { IMaintenanceArea, IMaintenanceNotification } from '~/types';

const { areaId } = defineProps<{
  areaId?: IMaintenanceArea;
}>();
const maintenanceStore = useMaintenanceStore();

const areaMessages = computed<IMaintenanceNotification[]>(
  () => maintenanceStore.messages?.filter((m) => m.area === areaId) || [],
);
</script>

<template>
  <div v-if="areaMessages.length !== 0">
    <BaseAlert
      v-for="item in areaMessages"
      :key="item.message"
      :type="item.type || ALERT_TYPE.WARNING"
    >
      <span v-html="item.message" class="whitespace-pre-wrap" />
    </BaseAlert>
  </div>
</template>
