import { computed, reactive, type Ref, ref } from 'vue';

import { useActiveFilters } from '~/features/useActiveFilters';
import { storage } from '~/features/useStorage';
import { getItemsFromSearchOrDefault } from '~/features/useUrlSearch';
import { isEmpty } from '~/features/useUtilities';

import Service from '~/services/Service';

import type {
  IFilter,
  ISailingScheduleFilters,
  ISailingScheduleOption,
  ISailingScheduleQuery,
  IStatisticsTradeLane,
} from '~/types';

export type SailingScheduleDateType = (typeof dateTypes)[number];
export const dateTypes = ['eta', 'etd'] as const;

const dateType = ref(storage.getSailingScheduleDateType() ?? dateTypes[0]);

export const getSailingScheduleDateType = (): SailingScheduleDateType =>
  dateType.value;

export const setSailingScheduleDateType = (
  type: SailingScheduleDateType | null,
) => {
  storage.setSailingScheduleDateType(type);
  dateType.value = type ?? dateTypes[0];
};

export const getSailingScheduleFilters = (
  organisationId: OrganisationId,
): Promise<boolean> =>
  new Promise((resolve) => {
    Service.organisation(organisationId)
      .sailingScheduleV2()
      .filters()
      .onSuccess(({ data }) => {
        storage.setSailingScheduleQuery(data);
        resolve(true);
      })
      .execute();
  });

export const getPublicSailingScheduleFilters = (): Promise<boolean> =>
  new Promise((resolve) => {
    Service.publicSailingSchedule()
      .filters()
      .onSuccess(({ data }) => {
        storage.setPublicSailingScheduleQuery(data);
        resolve(true);
      })
      .execute();
  });

export const useSailingScheduleFilters = (
  filters: Ref<ISailingScheduleFilters>,
) => {
  const byTradeLane = (value: ISailingScheduleOption) =>
    query.tradelanes.some(
      (trade) => trade?.name && value.inTradelanes.includes(trade.name),
    );

  const selectedFilters = computed(() => filters.value.selected);

  const getTradelanes = () =>
    getItemsFromSearchOrDefault(
      'trade',
      filters.value.tradelanes,
      selectedFilters.value?.tradelanes,
    );

  const query = reactive<ISailingScheduleQuery>({
    tradelanes: getTradelanes(),
    dischargePorts: getItemsFromSearchOrDefault(
      'discharge_port',
      filters.value.dischargePorts,
      selectedFilters.value?.dischargePorts || [],
    ),
    loadingPorts: getItemsFromSearchOrDefault(
      'loading_port',
      filters.value.loadingPorts,
      selectedFilters.value?.loadingPorts || [],
    ),
    vessels: getItemsFromSearchOrDefault(
      'vessel',
      filters.value.vessels,
      selectedFilters.value?.vessels || [],
    ),
  });

  const tradelanesOptions = computed<IStatisticsTradeLane[]>(() => {
    if (
      isEmpty(query.loadingPorts) &&
      isEmpty(query.dischargePorts) &&
      isEmpty(query.vessels)
    ) {
      return filters.value.tradelanes;
    }

    const lanes = [
      ...new Set([
        ...query.tradelanes.map((trade) => trade.name),
        ...query.loadingPorts.flatMap((port) => port.inTradelanes),
        ...query.dischargePorts.flatMap((port) => port.inTradelanes),
        ...query.vessels.flatMap((vessel) => vessel.inTradelanes),
      ]),
    ];

    const filteredLanes = lanes
      .map((lane) => filters.value.tradelanes.find(({ name }) => name === lane))
      .filter(Boolean) as IStatisticsTradeLane[];

    return filteredLanes;
  });

  const filterByOptions = (options: ISailingScheduleOption[]) => {
    if (isEmpty(query.tradelanes)) {
      return options;
    }

    return options.filter(byTradeLane);
  };

  const loadingPortsOptions = computed(() =>
    filterByOptions(filters.value.loadingPorts),
  );

  const dischargePortsOptions = computed(() =>
    filterByOptions(filters.value.dischargePorts),
  );

  const vesselsOptions = computed(() => filterByOptions(filters.value.vessels));

  const { activeFilters, clearFilter, clearQuery } = useActiveFilters(query);

  const updateQuery = (newQuery: ISailingScheduleQuery) => {
    query.tradelanes = newQuery.tradelanes;
    query.dischargePorts = newQuery.dischargePorts;
    query.loadingPorts = newQuery.loadingPorts;
    query.vessels = newQuery.vessels;
  };

  const clearActiveFilter = (filter: IFilter) => {
    const newQuery = clearFilter(filter, query);

    updateQuery(newQuery);
  };

  const clearAllFilters = () => {
    const newQuery = clearQuery(query);

    updateQuery(newQuery);
  };

  return {
    query,
    activeFilters,

    tradelanesOptions,
    loadingPortsOptions,
    dischargePortsOptions,
    vesselsOptions,

    clearActiveFilter,
    clearAllFilters,
    getTradelanes,
  };
};
