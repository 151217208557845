export const HOME_WIDGET_TYPE = {
  INVOICES: 'invoices',
  SHIPMENT_REVENUE_TONS: 'shipment_revenue_tons',
  TOTAL_LAYCAN_PERFORMANCE: 'total_laycan_performance',
  TRANSIT_TIME: 'transit_time',
  TOTAL_CO2_EMISSIONS: 'total_co2_emissions',
  OUTSTANDING_CLAIMS: 'outstanding_claims',
  VLSFO_PRICE: 'vlsfo_price',
  PORT_CALLS: 'port_calls',
} as const;

export const TYPE_TO_STATISTICS_PAGE = {
  [HOME_WIDGET_TYPE.SHIPMENT_REVENUE_TONS]:
    'OrganisationStatisticsV2ShipmentVolumes',
  [HOME_WIDGET_TYPE.TOTAL_LAYCAN_PERFORMANCE]:
    'OrganisationStatisticsV2LaycanPerformance',
  [HOME_WIDGET_TYPE.TRANSIT_TIME]: 'OrganisationStatisticsV2TransitTime',
  [HOME_WIDGET_TYPE.TOTAL_CO2_EMISSIONS]: 'OrganisationStatisticsV2Emissions',
  [HOME_WIDGET_TYPE.OUTSTANDING_CLAIMS]: 'OrganisationStatisticsV2Claims',
  [HOME_WIDGET_TYPE.VLSFO_PRICE]: 'OrganisationStatisticsV2Bunkers',
  [HOME_WIDGET_TYPE.PORT_CALLS]: 'OrganisationStatisticsV2Productivity',
  [HOME_WIDGET_TYPE.INVOICES]: 'OrganisationStatisticsV2Invoices',
} as const;

export const HOME_CUSTOMIZATION_WIDGET_TYPE = {
  SHIPMENT_VOLUME: 'shipment_volumes',
  LAYCAN_PERFORMANCE: 'laycan',
  TRANSIT_TIMES: 'transit_times',
  EMISSIONS: 'emissions',
  CLAIMS: 'claims',
  BUNKERS: 'bunkers',
  PRODUCTIVITY: 'productivity',
  INVOICES: 'invoices',
} as const;

export const HOME_CUSTOMIZATION_WIDGET_TYPE_TO_LABEL_TEXT = {
  [HOME_CUSTOMIZATION_WIDGET_TYPE.SHIPMENT_VOLUME]: 'Shipment Volume',
  [HOME_CUSTOMIZATION_WIDGET_TYPE.LAYCAN_PERFORMANCE]: 'Laycan',
  [HOME_CUSTOMIZATION_WIDGET_TYPE.TRANSIT_TIMES]: 'Transit Time',
  [HOME_CUSTOMIZATION_WIDGET_TYPE.EMISSIONS]: 'Emissions',
  [HOME_CUSTOMIZATION_WIDGET_TYPE.CLAIMS]: 'Claims',
  [HOME_CUSTOMIZATION_WIDGET_TYPE.BUNKERS]: 'Bunkers',
  [HOME_CUSTOMIZATION_WIDGET_TYPE.PRODUCTIVITY]: 'Productivity',
  [HOME_CUSTOMIZATION_WIDGET_TYPE.INVOICES]: 'Invoices',
};

export const HOME_VLSFO_PRICE_STATUS = {
  RISING: 'rising',
  FALLING: 'falling',
  STABLE: 'stable',
};
