<script setup lang="ts" name="UserNotificationsManagementTab">
import { reactive, ref } from 'vue';

import { USER_NOTIFICATION_TYPE_TO_TEXT } from '~/constants';
import { useOrganisationsStore, useSnackbarStore } from '~/store';

import { useService } from '~/features/useService.js';
import { getKeyFromSearch } from '~/features/useUrlSearch';

import Service from '~/services/Service.js';

import BaseAutoPagination from '~/components/BaseAutoPagination.vue';
import BaseDivideWrapper from '~/components/BaseDivideWrapper.vue';
import SearchField from '~/components/SearchField.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import UserNotificationManagementRow from '~/components/UserNotificationManagementRow.vue';

import type {
  IUserNotificationOrganisation,
  UserNotificationType,
} from '~/types';

const { userId, isInternal } = defineProps<{
  userId: number | null;
  isInternal: boolean | null;
}>();

const query = reactive({
  search: getKeyFromSearch('search', ''),
});

const snackbar = useSnackbarStore();

const organisations = ref<IUserNotificationOrganisation[]>([]);

const serviceState = useService(
  Service.user(userId).organisationsV2().show(),
  ({ data }: { data: IUserNotificationOrganisation[] }) => {
    if (!data.length) return false;

    organisations.value = data;
    return true;
  },
  {
    paginated: true,
    query,
    getQuery: () => ({
      search: query.search,
    }),
  },
);

const clearFilters = () => {
  query.search = '';
};

const updateNotificationSetting = (
  organisation: IUserNotificationOrganisation,
  name: UserNotificationType,
) => {
  Service.user(userId)
    .organisationsV2()
    .update()
    .data({
      ...organisation,
    })
    .onSuccess(() => {
      const notificationText = USER_NOTIFICATION_TYPE_TO_TEXT[name];
      const postFix = organisation.notify[name] ? 'enabled.' : 'disabled.';

      snackbar.add({
        type: 'success',
        icon: 'ic:baseline-check-circle',
        text: `${notificationText} ${postFix}`,
      });

      if (!organisation.notify.email && !organisation.notify.notification) {
        useOrganisationsStore().updateOrganisationShipmentsNotifications(false);
        return;
      }

      useOrganisationsStore().updateOrganisationShipmentsNotifications(true);
    })
    .execute();
};
</script>

<template>
  <div>
    <div class="border-b border-charcoal-2 px-6 py-5">
      <p class="text-subtitle-1 text-charcoal-9">Assigned Organisations</p>
      <p class="pt-2">
        Manage shipment notifications of assigned organisations
      </p>
    </div>

    <SearchField
      v-if="isInternal"
      v-model="query.search"
      placeholder="Search for Organisations"
    />

    <ServiceCard v-bind="serviceState" @clear="clearFilters">
      <div
        class="grid grid-cols-3 gap-x-2 bg-charcoal-2 px-4 py-3 sm:flex sm:pl-4 sm:pr-6"
      >
        <div class="sm:grow">
          <p class="inline-block text-center text-subtitle-2">
            Organisation Name
          </p>
        </div>
        <p class="text-center text-subtitle-2 sm:w-32">Notify on MyG2</p>
        <p class="text-center text-subtitle-2 sm:w-32">Notify by Email</p>
      </div>

      <BaseDivideWrapper>
        <UserNotificationManagementRow
          v-for="organisation in organisations"
          :key="organisation.id"
          v-model:notification="organisation.notify.notification"
          v-model:email="organisation.notify.email"
          v-bind="organisation"
          @update:notification="
            updateNotificationSetting(organisation, 'notification')
          "
          @update:email="updateNotificationSetting(organisation, 'email')"
        />
      </BaseDivideWrapper>

      <BaseAutoPagination styled />
    </ServiceCard>
  </div>
</template>
