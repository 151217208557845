<script
  setup
  lang="ts"
  name="DataTableHeader"
  generic="T extends IDataTableBaseRow"
>
import { useVModel } from '@vueuse/core';

import { getCellPadding } from '~/features/useDataTableCellPadding';
import { getStickyColumnOffset } from '~/features/useDataTableStickyColumn';
import { hasStickyColumns } from '~/features/useDataTableStickyColumn';

import BaseCheckboxToggle from '~/components/BaseCheckboxToggle.vue';
import DataTableHeaderCell from '~/components/DataTableHeaderCell.vue';

import type {
  IDataTableBaseCell,
  IDataTableBaseRow,
  IDataTablePaddingType,
  IDataTableSettingsActions,
  SortDirection,
} from '~/types';

const props = defineProps<{
  columns: IDataTableBaseCell[];
  areaOverflows: boolean;
  tableData: T[];
  selectedTableRows: T[];
  padding?: IDataTablePaddingType;
  checkboxSelection?: boolean;
  collapsibleRows?: boolean;
  gridWidths?: number[];
  actions?: IDataTableSettingsActions;
}>();

const emit = defineEmits<{
  'update:selectedTableRows': [value: T[]];
  onSort: [column: IDataTableBaseCell, direction: SortDirection];
}>();

const selectedRows = useVModel(props, 'selectedTableRows', emit);

const toggleAll = () => {
  if (props.tableData.length === selectedRows.value.length) {
    selectedRows.value = [];
    return;
  }

  selectedRows.value = [...props.tableData];
};
const callOnsort = (column: IDataTableBaseCell, direction: SortDirection) =>
  emit('onSort', column, direction);
</script>

<template>
  <div
    class="col-span-full grid grid-cols-subgrid bg-charcoal-2 text-subtitle-2"
    role="row"
  >
    <div
      v-if="checkboxSelection"
      class="flex-center px-6 py-4"
      :class="getCellPadding(padding)"
    >
      <BaseCheckboxToggle
        hideLabel
        :items="tableData"
        :selectedItems="selectedRows"
        @toggle="toggleAll"
      />
    </div>
    <div v-else-if="collapsibleRows"></div>

    <template v-for="column in columns" :key="column.key">
      <DataTableHeaderCell
        :column="column"
        :columns="columns"
        :areaOverflows="areaOverflows"
        :rightOffset="getStickyColumnOffset(column.key, columns, actions)"
        @onSort="callOnsort"
      />
    </template>

    <div
      v-if="actions"
      role="columnheader"
      class="cargo-booking-sticky-actions bg-charcoal-2 py-4 text-center font-bold"
      :class="[
        getCellPadding(padding, 'actions'),
        {
          'border-l border-l-charcoal-2': !hasStickyColumns(columns),
          'cargo-booking-sticky-shadow':
            areaOverflows && !hasStickyColumns(columns),
        },
      ]"
    >
      Actions
    </div>
  </div>
</template>
