<script setup lang="ts" name="AdminOrganisationNewCustomerUsers">
import InviteUsersForm from '~/components/InviteUsersForm.vue';

import type {
  IOrganisationCustomerUser,
  IOrganisationRoles,
  IOrganisationType,
} from '~/types';

defineProps<{
  customerUsers: IOrganisationCustomerUser[];
  customerRoles: IOrganisationRoles[];
  orgType: IOrganisationType;
}>();

const emit = defineEmits<{
  (e: 'update', value: IOrganisationCustomerUser[]): void;
}>();
</script>

<template>
  <div>
    <p class="mb-2 text-subtitle-1 text-charcoal-9">
      Add {{ orgType === 'charterer' ? 'Customer' : 'Broker' }} Users
    </p>
    <p class="mb-4">
      Add new {{ orgType === 'charterer' ? 'customer' : 'broker' }} users by
      entering their email and assigning role. Registration details will be sent
      directly to the provided email(s).
    </p>
    <InviteUsersForm
      :current-users="customerUsers"
      :roles="customerRoles"
      @update="emit('update', $event)"
    />
  </div>
</template>
