<script lang="ts" setup name="OrganisationSettingCollapsible">
import { useVModel } from '@vueuse/core';

import BaseCollapsible from '~/components/BaseCollapsible.vue';
import BaseToggle from '~/components/BaseToggle.vue';
import Icon from '~/components/Icon.vue';

const props = defineProps<{
  modelValue: boolean;
  name: string;
  canEdit: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const tooltip = !props.canEdit
  ? 'You are not authorised to enable settings. Please contact your admin for further information.'
  : undefined;

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <BaseCollapsible>
    <template #trigger="{ toggle, isOpen }">
      <button
        type="button"
        class="relative flex w-full justify-between py-6 pl-8 pr-09"
        :class="{
          'hover:bg-charcoal-1': canEdit,
        }"
        @click="toggle"
      >
        <div class="flex space-x-3">
          <Icon
            icon="mdi:chevron-right"
            class="text-primary-6"
            :class="{
              '-rotate-90': isOpen,
              'rotate-90': !isOpen,
            }"
          />
          <p>
            {{ name }}
          </p>
        </div>

        <BaseToggle
          v-model="inputValue"
          :tooltip="tooltip"
          :disabled="!canEdit"
        />
      </button>
    </template>

    <slot />
  </BaseCollapsible>
</template>
