<script setup name="InviteUsersForm">
import { computed, reactive, ref } from 'vue';
import { useForm } from 'vee-validate';

import Service from '~/services/Service.js';

import BaseButton from '~/components/BaseButton.vue';
import Field from '~/components/Field.vue';
import InvitedUserCard from '~/components/InvitedUserCard.vue';

const props = defineProps({
  currentUsers: {
    type: Array,
    required: true,
  },
  roles: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update']);

const { handleSubmit, resetForm } = useForm();

const users = ref(props.currentUsers);

const form = reactive({
  email: null,
  role: props.roles[0],
});

const schema = computed(() => ({
  email: {
    name: 'email',
    label: 'Email',
    rules: 'email|not_g2o_email|max:128',
    component: 'FieldText',
    mode: 'eager',
    autocomplete: 'email',
    trim: true,
  },
  role: {
    name: 'role',
    label: 'Role',
    rules: 'required',
    component: 'FieldSingleSelect',
    autocomplete: 'role',
    disabled: !props.roles.length,
    options: props.roles,
  },
}));

const addUserToTheTopOfTheList = ({ email, role }) => {
  const uniqueEmails = users.value.filter((user) => user.email !== email);
  Service.users()
    .getByEmail(email)
    .onNoData(() => {
      uniqueEmails.unshift({
        email,
        role,
      });
    })
    .onSuccess(({ data }) => {
      uniqueEmails.unshift({
        firstName: data.first_name,
        lastName: data.last_name,
        email,
        role,
      });
    })
    .onFinish(() => {
      users.value = uniqueEmails;
      emit('update', users.value);
    })
    .execute();
};

const onAddUser = handleSubmit(() => {
  if (!form.email) return;

  addUserToTheTopOfTheList(form);

  resetForm();
});

const removeUser = ({ email }) => {
  users.value = users.value.filter((user) => user.email !== email);
  emit('update', users.value);
};
</script>

<template>
  <div class="space-y-4 bg-charcoal-1 p-2">
    <form
      class="flex items-start space-x-2 bg-white p-2 shadow"
      @submit.prevent="onAddUser"
    >
      <Field v-model="form.email" class="flex-1" :field="schema.email" />
      <div class="flex-shrink-0">
        <Field v-model="form.role" :field="schema.role" />
      </div>
      <div class="mt-2 flex-shrink-0">
        <BaseButton
          icon="mdi:plus"
          variant="btn-secondary"
          type="submit"
          :disabled="!form.email"
        >
          Add
        </BaseButton>
      </div>
    </form>
    <p class="text-center text-sm text-charcoal-6">
      ADDED USERS ({{ users.length }})
    </p>
    <div class="max-h-80 divide-y divide-charcoal-2 overflow-y-auto">
      <InvitedUserCard
        v-for="user in users"
        :key="user.email"
        :first-name="user.firstName"
        :last-name="user.lastName"
        :email="user.email"
        :role="user.role.name"
        :hideDelete="user.hideDelete"
        @remove="removeUser(user)"
      />
    </div>
  </div>
</template>
