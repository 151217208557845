<script setup lang="ts" name="BaseCardState">
import { computed, useSlots } from 'vue';

import type {
  BaseCardStateIllustrationSizeType,
  BaseCardStateSpacingType,
  BaseCardStateTextColorType,
  BaseCardStateTextSizeType,
  IllustrationType,
} from '~/types';

const {
  textSize = 'card-text-headline',
  textColor = 'card-text-charcoal-9',
  spacing = 'none',
  illustrationSize = 'card-img-md',
  title,
  message,
  styled,
  illustration,
} = defineProps<{
  title?: string;
  textSize?: BaseCardStateTextSizeType;
  textColor?: BaseCardStateTextColorType;
  message?: string | null;
  styled?: boolean;
  illustration?: IllustrationType;
  illustrationSize?: BaseCardStateIllustrationSizeType;
  spacing?: BaseCardStateSpacingType;
}>();

const slots = useSlots();

const textColorIs = computed(
  () => (color: BaseCardStateTextColorType) => color === textColor,
);

const textSizeIs = computed(
  () => (size: BaseCardStateTextSizeType) => size === textSize,
);

const spacingIs = computed(
  () => (spacingType: BaseCardStateSpacingType) => spacingType === spacing,
);

const illustrationSizeIs = computed(
  () => (size: BaseCardStateIllustrationSizeType) => size === illustrationSize,
);

const cardTextcolor = computed(() => {
  if (textColorIs.value('card-text-charcoal-9')) {
    return 'text-charcoal-9';
  }

  if (textColorIs.value('card-text-charcoal-6')) {
    return 'text-charcoal-6';
  }

  return '';
});

const cardTextSize = computed(() => {
  if (textSizeIs.value('card-text-headline')) {
    return 'text-headline';
  }

  if (textSizeIs.value('card-text-subtitle-1')) {
    return 'text-subtitle-1';
  }

  return '';
});

const cardSpacing = computed(() => {
  if (spacingIs.value('card-lg')) {
    return 'pt-24 pb-28';
  }

  if (spacingIs.value('card-md')) {
    return 'pt-32';
  }

  if (spacingIs.value('card-sm')) {
    return 'p-6';
  }

  return '';
});

const cardIllustrationSize = computed(() => {
  if (illustrationSizeIs.value('card-img-md')) {
    return 'max-h-[120px] max-w-[120px]';
  }

  if (illustrationSizeIs.value('card-img-sm')) {
    return 'max-h-09 max-w-09';
  }

  return '';
});

const illustrationPath = computed(() =>
  illustration ? `/images/illustrations/${illustration}.svg` : '',
);
</script>

<template>
  <div
    class="h-30 flex flex-col items-center justify-center text-center"
    :class="[cardSpacing, { 'rounded bg-white drop-shadow': styled }]"
  >
    <div v-if="illustrationPath" class="mb-4">
      <img :class="[cardIllustrationSize]" :src="illustrationPath" alt="img" />
    </div>
    <slot name="title">
      <p v-if="title" :class="[cardTextcolor, cardTextSize]">
        {{ title }}
      </p>
    </slot>
    <slot v-if="message !== null" name="message">
      <p class="mt-2 text-body-1" :class="[cardTextcolor]">
        {{ message }}
      </p>
    </slot>

    <div v-if="slots.actions" class="mt-4">
      <slot name="actions"> </slot>
    </div>
  </div>
</template>
