<script setup lang="ts" name="DataTableLoading">
import { computed } from 'vue';

import { getCellPadding } from '~/features/useDataTableCellPadding';

import type { IDataTablePaddingType } from '~/types';

const {
  columns = 7,
  rows = 5,
  padding = 'medium',
} = defineProps<{
  columns?: number;
  rows?: number;
  padding?: IDataTablePaddingType;
}>();

const gridTemplateColumns = computed(
  () => `repeat(${columns}, minmax(0, 1fr))`,
);
</script>

<template>
  <div class="divide-y divide-charcoal-2">
    <div class="h-[52px] w-full animate-pulse bg-charcoal-2"></div>

    <div
      v-for="row in rows"
      :key="row"
      class="grid grid-cols-7"
      :class="getCellPadding(padding, 'loading')"
      :style="{
        gridTemplateColumns,
      }"
    >
      <div
        v-for="column in columns"
        :key="column"
        class="text-loading h-3 w-24"
      />
    </div>
  </div>
</template>
