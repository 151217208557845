<script lang="ts" setup name="AdminUserNew">
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue';

import { ROLES } from '~/constants';

import {
  getAdminCreateForm,
  getAdminCreateFormData,
  getAdminCreateSuccessPage,
} from '~/features/useAdminForms';
import { useLeavePageWarning } from '~/features/useLeavePageWarning';
import { useRouteBack } from '~/features/useRouteBack';
import { useService } from '~/features/useService.js';
import { checkDirty } from '~/features/useUtilities';

import Service from '~/services/Service.js';

import BasePage from '~/components/BasePage.vue';
import Field from '~/components/Field.vue';
import FieldFormSection from '~/components/FieldFormSection.vue';
import FormWizard from '~/components/FormWizard.vue';
import FormWizardStep from '~/components/FormWizardStep.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import SuccessCard from '~/components/SuccessCard.vue';
import UserForm from '~/components/UserForm.vue';

import type {
  AdminUserFormMeta,
  AdminUserFormMetaResponse,
  IOptionItem,
} from '~/types';

const userListName = 'User List';
const userListPath = useRouteBack({ name: 'AdminUsersIndex' });
const success = getAdminCreateSuccessPage(userListName, userListPath);

const meta = ref<AdminUserFormMeta>();
const organisations = ref<IOptionItem[]>([]);

const finished = ref(false);
const submitting = ref(false);
const submitError = ref(false);
const errors = ref<Record<string, string>>();

const initialForm = reactive(getAdminCreateForm());
const form = reactive(getAdminCreateForm());

const page = computed(() => {
  if (finished.value) return {};

  return {
    title: 'Create New G2O User',
    backButton: {
      label: userListName,
      to: userListPath,
    },
  };
});

const titles = [
  {
    title: 'User details',
  },
  {
    title: 'Organisations',
  },
];

const getDefaultRole = (data: AdminUserFormMeta) =>
  data.roles.find((role) => role.id === data.defaultRole);

const serviceState = useService(
  Service.users().newUserMeta(),
  ({ data }: AdminUserFormMetaResponse) => {
    meta.value = data;

    const defaultRole = getDefaultRole(data);
    form.role = defaultRole;
    initialForm.role = defaultRole;

    organisations.value = data.organisations;

    return true;
  },
);

const schema = reactive({
  organisations: {
    name: 'organisations',
    label: 'Organisations',
    component: 'FieldAdminOrganisations',
    rules: 'required',
    organisations: computed(() => organisations.value || []),
    role: computed(() => form.role),
  },
});

const setValidationErrors = (data) => {
  errors.value = data.response.data?.errors;
  submitting.value = false;
};

const onSubmit = () => {
  Service.users()
    .store(getAdminCreateFormData(form))
    .onStart(() => {
      submitting.value = true;
    })
    .onError(() => {
      submitError.value = true;
      submitting.value = false;
    })
    .onSuccess(() => {
      finished.value = true;

      form.email = '';
      form.role = null;
      form.organisations = [];

      submitting.value = false;
    })
    .onErrorValidation(setValidationErrors)
    .execute();
};

const setOrganisationsForAdmin = () => {
  [initialForm, form].forEach((data) => {
    data.organisations = [...organisations.value];
  });
};

const resetOrganisations = () => {
  [initialForm, form].forEach((data) => {
    data.organisations = [];
  });
};

watch(
  () => form.role,
  (newRole, previousRole) => {
    if (newRole?.name === ROLES.G2O_ADMIN) {
      setOrganisationsForAdmin();
      return;
    }

    if (previousRole?.name === ROLES.G2O_ADMIN) resetOrganisations();
  },
);

onBeforeUnmount(() => {
  submitError.value = false;
});

useLeavePageWarning(
  computed(() => checkDirty(initialForm, form, getAdminCreateFormData)),
  finished,
);
</script>

<template>
  <BasePage v-bind="page">
    <div class="col-span-full">
      <SuccessCard v-if="finished" v-bind="success" />

      <ServiceCard v-else v-bind="serviceState" styled>
        <FormWizard
          :loading="submitting"
          :submit-error="submitError"
          :errors="errors"
          :titles="titles"
          @submit="onSubmit"
        >
          <FormWizardStep>
            <FieldFormSection title="Enter User Details">
              <UserForm v-model="form" is-creating :meta="meta" />
            </FieldFormSection>
          </FormWizardStep>

          <FormWizardStep>
            <Field v-model="form.organisations" :field="schema.organisations" />
          </FormWizardStep>
        </FormWizard>
      </ServiceCard>
    </div>
  </BasePage>
</template>
